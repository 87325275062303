.paytypecls{padding:12px}
.paytypecls .order-title img{max-height: 60px;object-fit: contain;}
.paytypecls .order-title span{margin-top: 8px;font-weight: 500;
    color: #000;
    font-size: 14px; 
    display: block;}
.page-rtl .cart_inner .order-main.paytypecls{border-right: none;}

.paymentsize{flex-flow: column;}
.paymentsize .tire_qty{margin-top: 0 !important;}
.paymentsize .tire_qty li{display: inline-flex;align-items: center;}
    .paymentsize .tire_qty li span{margin-top: 0 !important;}
    .paymentsize .tire_qty li .order-title{padding-bottom: 0 !important;}
    .cart_inner .cartmaindiv .order-good1 .anorder{margin-top: 0px;width:100%;}
    .cart_inner .cartmaindiv .order-good1 .order-add{width:100%}
    .cart_inner .order-title{ word-wrap: break-word;}
    .cart_inner .order-title span{line-height: 1.6;}
    .payouterboxwrap .paytypecls.mobileshow{display: none;}
@media (max-width:767px) {
    .cart_inner .order-good1 .order-add{margin-top: 0;}
    .cart_inner .order-good1 .text-left{margin-left: 0 !important;}
    .cart_inner .order-main.paytypecls{padding-bottom: 0;}

    .rear-size.order_complete.paymentsize{display: flex;flex-flow: row;}
    .cart_inner .paymentsize .order-main.u-top-mid-col{width: 50%;padding-left: 0;}
    .cart_inner .paymentsize ul.tire_qty{flex-flow: column;}
    .cart_inner .paymentsize ul.tire_qty li{padding-right: 0;
        text-align: left;
        width: 100%;}
        .cart_inner .paymentsize ul.tire_qty li + li{margin-top: 10px;}

        .payouterboxwrap{display: flex;}
        .payouterboxwrap .paytypecls.mobileshow{display: inline-flex;}
        .payouterboxwrap .paytypecls{width:44%;flex-shrink: 0;}
}