/*location modal*/

.countrymodal.openit {
  top: 0;
}
.countrymodal {
  position: fixed;
  top: -680px;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 99999999;
  padding: 64px 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.countrymodal .btn.close {
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0;
  width: 99px;
  height: 52px;
  padding: 0px;
  background: #f3f3f3;
  font-size: 18px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.66);
  font-weight: normal;
  outline: none;
}
.countrymodal .btn.close span {
  font-size: 22px;
  vertical-align: middle;
}
.countrymodal .popup-content .content-body {
  padding: 0px;
  margin: 0px;
}
.countrymodal .popup-content .content-body .outertitle {
  padding: 0 0 0px;
  margin: 0 0 35px;
  text-align: center;
  position: relative;
}
.countrymodal .popup-content .content-body .outertitle .innertitle {
  width: auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  margin: auto;
  padding: 35px 0 0px;
  position: relative;
}
.countrymodal .popup-content .content-body .outertitle .innertitle h3 {
  font-size:30px;
  font-weight: 500;
  color: #050505;
  line-height: 1.5;
}

.countrymodal .popup-content .content-body .outertitle .innertitle h3:after {
  height: 14px;
  content: "";
  width: 72px;
  background: rgb(252 194 35 / 11%);
  position: absolute;
  left: 24%;
  z-index: -1;
  top: 40%;
}
.countrymodal .popup-content .content-body .outertitle .innertitle h3:after {
  background: rgb(252 194 35 / 34%);
}
.countrymodal .popup-content .content-body .outertitle .innertitle h3 span {
  font-size: 18px;
  color: #000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: normal;
  display: block;
  width: 75%;
  margin: auto;
  margin-top: 5px;
}
.countrymodal .popup-content .content-body .outertitle .innertitle h3 span strong{
font-weight: 600;
}
.countrymodal .trandinglist {
  padding: 0px;
  margin: 0px;
  text-align: center;
}
.countrymodal .trandinglist h3 {
  font-size: 20px;
  color: #050505;
  font-weight: 500;
  margin: 0 0 30px;
}
.countrymodal .trandinglist ul {
  padding: 0px;
  margin: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.countrymodal .trandinglist ul li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0 6px;
  list-style: none;
}

.countrymodal .trandinglist ul li a {
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 20px;
  outline: none;
  text-decoration: none;
  border: solid 1px #dedede;
  border-radius: 5px;
  background-clip: padding-box;
  font-size: 12px;
  color: #616161;
  letter-spacing: 0.1px;
  background: #fff;
}
.animate {
  -webkit-animation: ripple .65s linear;
  animation: ripple 0.65s linear;
}
.ink {
  display: block;
  position: absolute;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 100%;
  background-clip: padding-box;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.countrymodal .trandinglist ul li.active a {
  border-color: #474747;
  background: #474747;
  color: #fff;
}
.form-group.locationgroup {
  display: flex;
  position: relative;
  justify-content: center;
}
.form-group.locationgroup a.nav-link.pl-0 {
  vertical-align: middle;
  position: absolute;
  left: 34%;
  font-size: 15px;
  font-weight: 500;
  color: #000;
  top: 8px;
}
.form-group.locationgroup a.nav-link.pl-0 img {
  padding-left: 5px;
  vertical-align: middle;
  padding-right: 10px;
}
.form-group.locationgroup input::placeholder {
  color: #aaaaaa;
  font-size: 15px;
  font-weight: 500;
  
}
.form-group.locationgroup p {
  width: 30%;
  /* margin: auto; */
  font-size: 15px;
  font-weight: 500;
  vertical-align: middle;
  color: #aaaaaa;
  padding: 25px 20px;
  text-transform: uppercase;
  border: 1px solid;
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.06);
  border-radius: 5px 0 0 5px;
}

.form-group.locationgroup input.form-control {
 position: relative;
  height: 70px;
  width: unset;
  border-radius: 0;
  width: 44%;
  border-color: #aaaaaa;
  padding-left: 30px;
  box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.06);
  border-radius: 0px 5px 5px 0px;
}
.content-header {
  text-align: center;
}
.timing_mob{
  display: none;
}
@media (max-width: 1499px)
{
.countrymodal .trandinglist ul li a {
  min-height: 30px;
  padding: 5px 15px;
}
}





@media (min-width:768px) and (max-width:1280px){

section.tire_infowrap {
  padding: 0 15px;
  width: 100%;
}
.order_summrywrap{
display: block;
}
section.order_summraypopup {
  width: 75%;
  margin: auto;
}
section.tire_infowrap .listing-block .listing-info li{
font-size: 8px
}
section.tire_infowrap .listing-block .content-box{
padding: 0 10px 0 15px;
}
section.tire_infowrap .listing-block .listing-info{
padding: 18px 0
}
header.location_page_header .inner-box{
align-items: unset;
}
.location_page_wrap{
padding-top: 160px;
}
header.location_page_header .inner-box .content-box{
display: flex;
}
header.location_page_header .inner-box .listing-bottom-box .listing-rates .left-area {
  display: unset;
  text-align: center;
  margin: auto;
  padding: 0 0;
  width: 100%;
  margin-top: 0px;
  line-height: 30px;
}
header.location_page_header .inner-box .content-box .upper-box a.runflat{
right: 0
}
section.location_leftmenu{
width: 30%;
}
section.loction_mapwrap{
width: 70%;
}
.form-group.locationgroup p{
width: 42%;
}
.form-group.locationgroup a.nav-link.pl-0{
left: 35%;
top: 10px
}
}
.navbar-left-arrow.mobile_arrow{display: none;}
ul.listing-info.loction_listing{display: none;}
.listing-bottom-box.mob_locbtn{display: none;}
@media (max-width: 767.98px){
 .page-rtl ul.menu.left-to-right{
  direction: ltr;
 }
  .order_mobile .working_timeing {
   
    display: none;
}
.listing-block h3 a span.pull-right {
    position: absolute;
    right: -10px;
    top: -40px;
    font-size: 12px;
    color: #fcc223;
}
ul.listing-info.loction_listing
{
      display: block !important;
    background: #fff;
    padding: 0 10px;
    align-items: center;

}
ul.listing-info.loction_listing li {
    display: inline-block;
    width: 50%;
    padding: 10px 0;
}
ul.listing-info.loction_listing li {
    font-weight: bold;
    font-size: 11px;
    color: #000;
}
.page-rtl ul.listing-info.loction_listing li{font-size: 10px}
.listing-bottom-box.mob_locbtn {
    background: #fff;
    padding: 15px;
}
.listing-bottom-box.mob_locbtn .listing-rates{
  position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background: #fcc223;
    margin-top: 0px;
    border-radius: 6px;
}
.listing-bottom-box .listing-rates .left-area span{font-size: 20px; font-weight: bold;}
header.location_page_header .inner-box .content-box{padding: 0 10px 0 10px}
header.location_page_header .inner-box .upper-box ul.listing-info{padding: 0}
.listing-bottom-box.mob_locbtn .listing-rates .left-area{
  margin: 0
}
ul.listing-info.loction_listing li span {
    color: #8e8e8e;
}
.order_mobile .image-box {
    width: 40%;
    display: inline-block;
}
.order_mobile .content-box {
    padding-left: 0;
    padding-right: 0;
    width: 60%;
    display: inline-block;
}
.navbar-left-arrow.mobile_arrow{
  display: block;
  background: #fff;
  padding:10px;
}
.inner-box.location_header_mobile {
    box-shadow: none;
    flex-direction: unset;
}
header.location_page_header .inner-box .image-box{
  padding: 0
}
.inner-box.location_header_mobile .listing-bottom-box {
    display: none;
}
ul.listing-info.location_warrantylist{display: block !important; }
.inner-box.location_header_mobile .navbar-left-arrow {
    display: none;
}
.listing-bottom-box.mob_locbtn{display: block;}
.order_mobile .content-box .upper-box.tireinfo_istingwrap .listing-heading {
    padding: 6px 0px 0 10px;
}

.order_mobile .content-box .upper-box.tireinfo_istingwrap {
    padding: 0;
    margin: 0;
}
.listing-block.order_detailmob .inner-box.order_mobile {
    flex-direction: row;
    padding: 15px 15px 0;
    border-radius: unset;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: none;
}
.order_detailmob .working_timeing {
    background: #fff;
    padding: 15px;
        border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.timing_mob{
  display: block;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .listing-heading.tireinfo_isting-heading h3 {
  font-size: 16px;
}
section.order_summraypopup{
padding: 0
} 
.order_summray_modalheading h2 {
  font-size: 18px;
  text-align: center;
}
.order_summray_modalheading h2 span {
  font-size: 12px;
}
.price_summarylisting ul li {
  font-size: 11px;
}
.price_summarylisting ul li span.free {
  border-radius: unset;
  font-size: 11px;
  padding: 5px;
}
.prce_summarylisting_btn button {
  font-size: 15px;
  height: 40px;
}
.free_delivry_link {
  text-align: center;
}
.free_delivry_link a {
  font-size: 8px;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .listing-heading.tireinfo_isting-heading p.address {
  font-size: 11px;
  width: 100%;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing {
  border: none;
  padding: 0;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing p {
  font-size: 11px !important;
  width: 100%;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing span.full_time {
  font-size: 12px;
  width: 80%;
}
header.order_detail_page_header {
  width: 100%;
  overflow-x: hidden;
}
header.order_detail_page_header .listing-block {
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
}
header.order_detail_page_header .listing-block .inner-box {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;

  flex-direction: unset;
}
header.order_detail_page_header .listing-block .inner-box span {
    font-size: 14px;
    padding: 0;
    margin-top: 20px;
    letter-spacing: 2px;
    line-height: initial;
}
.row.order_deliver .col-md-6.border-left.text-right {
    border-left: none !important;
    text-align: left !important;
  }
  .row.order_deliver .col-md-6.border-left.text-right .installcenterinfo.b_bottom {
    padding: 0 0 15px;
}

.order_tire_listing ul li {
  padding: 0 10px;
  font-size: 11px;
  line-height: 15px;
}
.order_tire_listing ul li span{
font-size: 10px;
line-height: 20px;
}
.order_tire_listing ul{
padding: 20px 0px;
}
.location_page_wrap{
display: block;
padding: 0;
}
header.location_page_header .inner-box .content-box{
display: unset;
}
header.location_page_header .inner-box .content-box .upper-box{
padding: 0
}
header.location_page_header .inner-box ul.listing-info li{
margin: 0
}
header.location_page_header .inner-box .upper-box ul.listing-info li{
margin: 0;
width: 100%;
}
header.location_page_header .inner-box .content-box .upper-box a.runflat{
text-align: right;
right: 0 !important
}
header.location_page_header .inner-box .image-box figure.image{
width: 100% !important
}
header.location_page_header{
position: unset;
}
.toolbar-enabled {
    padding-bottom: 0;
}
header.location_page_header .listing-block{
  height: unset
}
header.location_page_header .inner-box{
align-items: unset;
}
header.location_page_header .navbar-left {
  margin-bottom: 15px;
}
section.loction_mapwrap{
display: block;
overflow-x: hidden;
margin-top: 20px;
}
.countrymodal.openit {
  height: 100%;
  overflow-y: auto;
}
.form-group.locationgroup{
display: block;
}
.form-group.locationgroup p {
  width: 100%;
  margin: 10px 0;
}
.form-group.locationgroup a.nav-link.pl-0{
left: unset;
right: 0
}
.form-group.locationgroup input.form-control {
  width: 100%;
}
.countrymodal .trandinglist ul{
display: block;
}
.countrymodal .trandinglist ul li{
padding: 6px
}
body .listing-block h3 a span.pull-right{right: 17px;left: auto;}
body .page-rtl .listing-block h3 a span.pull-right{left:0px;right: auto;}
}