@charset "UTF-8";
@font-face {
	font-family: 'Celias';
	src: url("../assets/Fonts/Celias-Medium.woff2") format("woff2"), url("../assets/Fonts/Celias-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Celias';
	src: url("../assets/Fonts/Celias-Regular.woff2") format("woff2"), url("../assets/Fonts/Celias-Regular.woff") format("woff");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Celias';
	src: url("../assets/Fonts/Celias-Light.woff2") format("woff2"), url("../assets/Fonts/Celias-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Circular Std Black";
	src: url("../assets/Fonts/Circular-Std-Black.eot"), /* IE9*/
	url("../assets/Fonts/Circular-Std-Black.woff") format("woff2"), /* chrome、firefox */
	url("../assets/Fonts/Circular-Std-Black.woff2") format("woff"), /* chrome、firefox */
	url("../assets/Fonts/Circular-Std-Black.ttf") format("truetype");
	/* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
}


/*body css*/

html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
em,
img,
small,
strong,
sub,
sup,
b,
i,
center,
ul,
li,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
aside,
embed,
footer,
header,
menu,
nav,
section,
audio,
video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article,
aside,
details,
footer,
header,
menu,
nav,
section {
	display: block;
}

html,
body {
	height: 100%;
	font-family: "Celias", sans-serif;
	padding: 0px;
	margin: 0px;
}

body {
	line-height: 1;
	font-family: "Celias", sans-serif;
	overflow-x: hidden;
	background: #eaf0fc;
	font-size: 13px;
	/*padding-top: 86px;*/
}

ol,
ul {
	list-style: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

*,
*:after,
*:before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

a {
	outline: none !important;
}

a:hover {
	color: #000000;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	padding: 0px;
	margin: 0px;
	font-family: "Celias", sans-serif;
	font-weight: normal;
	font-style: normal;
}

h1 {
	font-size: 24px;
	font-weight: normal;
	font-style: normal;
}

.navbar-light .nav-item:hover .nav-link:not(.disabled),
.navbar-light .nav-item:hover .nav-link:not(.disabled)>i {
	color: #000;
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
	color: #000;
}

.navbar-floating {
	padding: 8px 0;
}

.navbar-brand {
	margin-right: 12px!important
}


/*Custom css*/

.preloading {
	width: 100%;
	text-align: center;
	height: 100%;
	position: fixed;
	background: #ffffff;
	z-index: 999999;
	top: 0px;
	display: table;
	left: 0;
}

.preloading .wrap-preload {
	background: #fcc223;
	display: table-cell;
	text-align: center;
	vertical-align: middle;
}

.preloading .cssload-loader {
	width: 49px;
	height: 49px;
	border-radius: 50%;
	margin: 3em;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	background: #ffffff;
}

.preloading .cssload-loader,
.preloading .cssload-loader:before,
.preloading .cssload-loader:after {
	animation: 1.15s infinite ease-in-out;
	-o-animation: 1.15s infinite ease-in-out;
	-ms-animation: 1.15s infinite ease-in-out;
	-webkit-animation: 1.15s infinite ease-in-out;
	-moz-animation: 1.15s infinite ease-in-out;
}

.preloading .cssload-loader {
	animation-name: cssload-loader;
	-o-animation-name: cssload-loader;
	-ms-animation-name: cssload-loader;
	-webkit-animation-name: cssload-loader;
	-moz-animation-name: cssload-loader;
}

@keyframes cssload-loader {
	from {
		transform: scale(0);
		opacity: 1;
	}
	to {
		transform: scale(1);
		opacity: 0;
	}
}

@-o-keyframes cssload-loader {
	from {
		-o-transform: scale(0);
		opacity: 1;
	}
	to {
		-o-transform: scale(1);
		opacity: 0;
	}
}

@-ms-keyframes cssload-loader {
	from {
		-ms-transform: scale(0);
		opacity: 1;
	}
	to {
		-ms-transform: scale(1);
		opacity: 0;
	}
}

@-webkit-keyframes cssload-loader {
	from {
		-webkit-transform: scale(0);
		opacity: 1;
	}
	to {
		-webkit-transform: scale(1);
		opacity: 0;
	}
}

@-moz-keyframes cssload-loader {
	from {
		-moz-transform: scale(0);
		opacity: 1;
	}
	to {
		-moz-transform: scale(1);
		opacity: 0;
	}
}

@media (min-width: 1280px) {
	.container,
	.container-sm,
	.container-md,
	.container-lg,
	.container-xl {
		max-width: 1170px;
	}
	.container-fluid,
	.container-xl {
		padding-right: 40px;
		padding-left: 40px;
	}
}

@media (min-width: 992px) {
	.navbar-sticky.navbar-stuck.navbar-floating.navbar-light {
		background-color: #fcc223;
	}
}

@media (min-width: 1280px) {
	.banner-section .container-fluid,
	.banner-section .container-xl {
		padding-right: 15px;
		padding-left: 15px;
	}
	
}

.admin_main_header {
	padding-left: 32px;
}

.admin_side_bar:before {
	content: "";
	background: url(../assets/banner-tire-mark.png) no-repeat;
	position: absolute;
	top: 128px;
	height: 100%;
	opacity: 0.46;
	width: 192px;
	right: 0;
	z-index: 0;
}

.admin_side_bar {
	position: fixed;
	left: 0;
	top: 0;
	width: 192px;
	height: 100%;
	background: #fcc223;
}

.admin_side_bar:after {
	content: "";
	background: url(../assets/inner-left-city.png) no-repeat;
	position: absolute;
	height: 130px;
	width: 100%;
	bottom: 0;
	left: 0;
}

.admin_side_bar .logo {
	text-align: center;
	padding: 35px 0;
	height: 245px;
	background: #fcc223;
}

span.user_name {
	font-size: 15px;
	padding-right: 60px;
	color: #000;
	display: flex;
}

.barline {
	width: 29px;
	height: 100%;
	position: absolute;
	right: 70px;
	top: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.barline span:nth-child(1) {
	background: #fff9e9;
}

.barline span:nth-child(1) {
	width: 14.76%;
	background: #fff9e9;
}

.barline span:nth-child(2) {
	background: #feedbd;
}

.barline span:nth-child(2) {
	width: 17.64px;
	background: #feedbd;
}

.barline span:nth-child(3) {
	width: 25.45px;
	background: #fde091;
}

.barline span:nth-child(4) {
	background: #fde091;
}

.barline span:nth-child(4) {
	width: 28.15px;
	background: #fcc93b;
}

.admin_menu {
	background: #fcc223;
	/* opacity: .66*/
}

.admin_menu li.active span {
	color: #000;
}

.admin_menu li {
	text-align: left;
	padding: 20px 25px 30px 43px;
	opacity: .48;
	margin-bottom: 45px;
}

.admin_menu li span {
	display: block;
	margin-top: 5px;
	color: #ad8310;
	font-size: 13px;
}

.header_container {
	margin: auto;
	padding-left: 192px;
	height: 100%;
}

header.admin_header {
	background: #fff;
	height: 86px;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 99;
}

.admin_main_header,
.main_admin_header,
.main_admin_header ul {
	height: 100%;
}

.main_admin_header ul li.user_icon,
.main_admin_header ul .navbar-tool {
	height: 100%;
	position: relative;
}

.main_admin_header ul li a.nav-link.pl-0 img {
	vertical-align: middle;
	margin: 0px 5px;
}

.main_admin_header ul li a.nav-link.pl-0 {
	vertical-align: middle;
	font-size: 14px;
	text-transform: uppercase;
	color: #000;
}

.main_admin_header ul {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

li.user_icon .navbar-tool-icon-box img {
	vertical-align: middle;
}

li.user_icon .navbar-tool-icon-box {
	background: #fcc223;
	border-radius: unset;
	height: 100%;
	padding: 10px 0;
	width: 70px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.admin_menu li.active {
	position: relative;
	opacity: 1
}

.admin_menu li.active img.active_icon {
	display: block;
}

.admin_menu li.active:after {
	content: "";
	background: url(../assets/shapeactive.png) no-repeat;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 99999999;
	padding: 50px 9px;
}


/*gajendra-table*/

.admin-container {
	max-width: 1356px;
	margin: 0 auto;
}

.pull-left {
	float: left;
}

.pull-right {
	float: right;
}

.main-content {
	padding-left: 192px;
}

.top-container {
	max-width: 90%;
	margin: 0 auto;
}

.u-filter-right {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: flex-end;
}

.u-filter-col {
	padding: 0 8px;
}

.top-filter-box .form-control {
	height: 42px;
	padding: 0 15px;
	font-size: 14px;
	color: #7b7b7b;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.05);
}

.top-filter-box select.form-control {
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	padding: 0 40px 0 15px;
}

.top-filter-box .u-select {
	position: relative;
}

.top-filter-box .u-select:after {
	position: absolute;
	content: '';
	background: url(../assets/arrow-drop.png);
	right: 12px;
	width: 10px;
	height: 7px;
	top: 19px;
}

.top-filter-box .u-search-box {
	position: relative;
	min-width: 324px;
	background: #fff;
	border-radius: 3px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.012);
}

.top-filter-box .form-control.u-search {
	max-width: 80%;
	background: transparent;
	border: none;
	box-shadow: none;
}

.top-filter-box input[type="date"] {
	max-width: 150px;
	border-radius: 3px;
	padding: 4px;
	color: #4b566b
}

.u-filter-col .btn.btn-dark.exportid.form-control {
	display: flex;
	align-items: center;
	padding: 8px 20px;
}

.u-filter-col .btn.btn-dark.exportid.form-control .icon-import {
	margin: 0px 0 0 10px;
}

.u-select.u-calendar-box .u-calendar {
	position: absolute;
	top: 14px;
	left: 15px;
}

.u-select.u-calendar-box input[type="text"] {
	padding-left: 40px;
	max-width: 150px;
	min-width: 136px;
}

.u-inner-wrapper thead {
	color: #fff!important;
	border-radius: 2px 0 0 13px;
}

button.btn.btn-dark.bulk,
button.btn.btn-dark.bulk:focus {
	background: #fcc326;
	border: none;
	color: #000!important;
}

.u-inner-wrapper table {
	width: 100%;
}

.u-inner-wrapper .table-head th:first-child {
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
}

.u-inner-wrapper .table-head th:last-child {
	border-top-right-radius: 8px;
	border-bottom-right-radius: 8px;
}

.u-inner-wrapper {
	background: #fff;
	padding: 29px 24px;
	margin: 22px 0 0;
	border-radius: 8px;
}

.top-filter-box .col-sm-4 {
	padding: 0;
}

.u-inner-wrapper .table th,
.u-inner-wrapper .table td {
	padding: 18px 4px!important;
	border: none;
	color: #000;
	white-space: normal;
	word-break: break-word;
	max-width: 150px;
}

.u-inner-wrapper .table td a {
	margin: 0 5px;
}

.u-inner-wrapper .table-head {
	background: #353535;
	border-radius: 7px;
}

.u-inner-wrapper .table-head th {
	color: #fff!important;
	text-align: center;
	font-size: 11px;
	text-transform: uppercase;
}

.u-inner-wrapper tbody tr:nth-child(even) {
	background-color: #f7f9fd;
}

.admin-pagination {
	margin: 0;
	display: flex;
	justify-content: center;
	width: 100%;
	margin: 40px 0px 40px;
	padding-bottom: 40px;
}

.admin-pagination .page-item {
	border-right: 1px solid #ccc;
	margin: 0;
}

.admin-pagination .page-item.b-none {
	border: none;
}

.admin-pagination .page-link:hover {}

.admin-pagination .pagination-sm .page-link {
	border-radius: 14px;
	width: 27px;
	margin: 0 5px;
	text-align: center;
}

.admin-pagination .page-link:hover {
	z-index: 2;
	color: #000000;
	text-decoration: none;
	background-color: #fcc234;
}

.admin-pagination .page-item.b-none .page-link:hover {
	background: none;
}

.admin-pagination .page-item.active .page-link {
	color: #000000;
	text-decoration: none;
	background-color: #fcc234;
}
.main-content.report-page .u-search-box {
    min-width: 212px;
}


.top-filter-box {
	border-bottom: 1px solid #dfe2e7;
	padding-bottom: 20px;
	margin: 21px auto;
	position: relative;
}

.top-filter-box:after {
	position: absolute;
	bottom: -2px;
	content: '';
	width: 100%;
	height: 1px;
	background: #f6f8fe;
}

.inventry-bottom .col-md-1 {
	padding: 0px 3px;
}

.top-filter-box .btn.btn-dark {
	padding: 8px 11px;
	font-size: 13px;
	border: none;
}

.top-filter-box h1 {
	margin-bottom: 8px;
	font-size: 20px;
	color: #000;
	font-weight: 500;
}

.top-filter-box .col-sm-2 {
	padding-left: 0px;
	padding-right: 8px;
}

.top-filter-box .col-sm-10 {
	padding-left: 0px;
	padding-right: 0px;
}

.bookservicemain .contactdetail {
	background: #fff;
	padding: 35px 38px;
	box-shadow: 0 0 65px rgba(25, 25, 25, 0.09);
	-webkit-box-shadow: 0 0 65px rgba(25, 25, 25, 0.09);
}

.bookservicemain h2 {
	color: #131313;
	font-size: 28px;
	width: auto !important;
	display: inline-block;
	position: relative;
	z-index: 9;
	font-weight: 500;
}

.bookservicemain h2:before {
	content: '';
	width: 75px;
	height: 10px;
	background: #ffebbf;
	position: absolute;
	left: 43%;
	bottom: 6px;
	z-index: -1;
}

.bookservicemain .contactdetail .selectsec {
	margin-top: 40px;
}

.bookservicemain .txt_star {
	color: #ffbe2c;
    font-size: 13px;
    background: none;
    border: none;
	text-transform: uppercase;
}

.ud-flex-space-between {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.bookservicemain .contactdetail .selectsec .row {
    margin-top: 78px;
    position: relative;
    z-index: 1;
}

.bookservicemain .custom-select {
	background: #fff url(../assets/select-arrow.png) no-repeat right 1rem center/7px 6px;
}


/*.bookservicemain .row{display: block;}*/

.fields input {
	padding: 12px;
}

.fields {
	vertical-align: top;
	margin-bottom: 30px;
	float: left;
	font-family: "Celias", sans-serif;
	position: relative;
}

.bookservicemain .fields {
	margin-bottom: 45px;
}
.main-content .form-control:focus { 
    border-color: rgb(255 190 44);
}
.admin-bg img {
	display: block;
	margin: 16px auto;
}

.book .close {
	background: #f3f3f3;
	padding: 13px 16px;
	font-size: 13px;
	font-weight: 500;
	color: #727272;
	text-transform: capitalize;
	opacity: 1;
	display: flex;
	align-items: center;
	position: absolute;
	right: 0;
	z-index: 9;
}

.book .close span {
	font-size: 27px;
	font-weight: 400;
	margin-left: 8px;
}

.fields label {
	position: absolute;
	background: #fff;
	left: 26px;
	top: -8px;
    padding: 1px 0px;
	color: #7C7C7C;
	font-size: 14px;
	z-index: 9;
}

.bookservicemain .fields label {
	color: #000 !important;
	font-weight: 500
}

.bookservicemain .custom-select,
.bookservicemain .form-control,
.bookservicemain .custom-select:focus,
.bookservicemain .form-control:focus, 
.bookservicemain .custom-select:active,
.bookservicemain .form-control:active,
.bookservicemain .custom-select:visited,
.bookservicemain .form-control:visited {
	height: 64px;
	background-color: #fdfdfd;
}
.bookservicemain  input.form-control:-webkit-autofill {
    background-color: #fdfdfd !important;
}
.bookservicemain .form-control {
	padding-left: 15px;
}

.bookservicemain button.button2 {
	height: 70px;
}

.fields label sup {
	color: red;
}

span.search {
	position: absolute;
	top: 9px;
	right: 14px;
}

button.button2 {
	background: #ffbe2c;
	padding: 14px;
	font-size: 19px;
	border: none;
	color: #fff;
	width: 100%;
	border-radius: 6px;
	cursor: pointer;
}

.modal-open .book {
	overflow-x: hidden;
	overflow-y: auto;
}

.book {
	position: fixed;
	top: 0;
	right: -600px;
	z-index: 9999;
	width: 600px;
	height: 100%;
	outline: 0;
	transition: all 0.5s ease-in-out 0s;
	padding-left: 0px !important;
	background: #fff;
	overflow: hidden;
	box-shadow: 0 0 65px rgba(25, 25, 25, 0.09);
	-webkit-box-shadow: 0 0 65px rgba(25, 25, 25, 0.09);
}

.bookservicemain,
.bookservicemain .contactdetail {
	height: 100%; 
	position: relative;
}
 

.bookservicemain:after {
	background-image: url(../assets/admin_background1.png);
	background-position: bottom center;
	width: 100%;
	height: 222px;
	background-size: contain;
	position: absolute;
	content: '';
	bottom: 0; 
	background-repeat: no-repeat;
}

.book.show {
	right: 0;
	overflow-y: auto;
	overflow-x: hidden;
}

.modelbackHide {
	opacity: 0 !important;
	width: 0;
	height: 0;
}

.u-inner-wrapper img {
	cursor: pointer;
}

.orders-top {
	background: #f4f7fd;
	width: 100%;
	display: inline-flex;
	border-radius: 6px;
	-webkit-border-radius: 6px;
	margin-bottom: 30px;
	height: 42px;
	justify-content: space-around;
}

.orders-top .u-list-top-col {
	position: relative;
	padding: 15px 17px;
	font-size: 12px;
	text-transform: uppercase;
	color: #000;
	font-weight: 500;
}

.orders-top .u-list-top-col:after {
	position: absolute;
	content: '';
	width: 1px;
	height: 100%;
	background-color: #fff;
	right: -10%;
	top: 0;
}

.orders-top .u-list-top-col select {
	border: none;
	background: transparent;
	color: #59a508;
	outline: none;
	text-transform: uppercase;
}

.u-brand-col-left {
	width: 45%;
	margin-top: 22px;
	float: left;
	padding-left: 5px;
}

.u-brand-col-left img {
	width: 122px;
	height: 86px;
	display: block;
	box-shadow: 0 0 29px rgba(0, 0, 0, 0.06);
}

.u-brand-col-right {
	width: 55%;
	display: inline-grid;
	padding: 11px 6px 11px 20px;
	line-height: 14px;
}

.u-brand-col-right b {
	font-weight: bold;
	color: #000;
}

.u-brand-col-right span {
	color: #8e8e8e;
	font-size: 13px;
	margin-top: 7px;
}

.u-brand-col-right span.u-list-year {
	font-size: 14px;
	color: #333;
	font-weight: 500;
}

.u-brand-col-right span.u-list-brand {
	font-size: 20px;
	margin-bottom: 10px;
}

.u-brand-col-right span.u-list-made>span {
	display: block;
	margin-bottom: 3px;
	font-size: 13px;
	font-weight: 400;
	color: #8e8e8e;
}

.u-brand-col-right span.u-list-made {
	font-size: 14px;
	font-weight: 500;
	color: #000;
	margin-bottom: 5px;
}

.u-brand-col-right .bin {
	color: #fa7102;
	margin-top: 3px;
	display: block;
}

.rear-size.col-md-7 {
	padding: 0;
}

.order-title span {
	color: #8e8e8e;
	font-size: 12px;
	margin-top: 7px;
	text-transform: uppercase;
}

.order-title b {
	color: #000;
	font-weight: 500;
	font-size: 18px;
}

/*.order-title {
	padding: 0 0 5px;
}*/

.order-add {
	display: inline-block;
	padding-right: 7px;
}

.order-good .order-add {
	margin-top: 5px;
}

.order-add span {
	color: #8e8e8e;
	font-size: 13px;
	margin-top: 0px;
}

.order-add b {
	color: #000;
	font-weight: 500;
}

.order-main {
	width: unset;
	float: left;
	padding: 24px 36px;
	border-left: 1px solid #d7e1f3;
	border-right: 1px solid #d7e1f3;
}

.order-main.u-top-mid-col {
	padding: 12px 36px 24px;
}

.order-main.order-border.u-bottom-mid-col {
	padding: 24px 36px 12px;
}

.u-border-com-box {
	border-right: 1px solid #d7e1f3;
	border-bottom: 1px solid #d7e1f3;
}

.u-h50 {
	height: 50%;
}

.u-border-com-right {
	border-right: 1px solid #d7e1f3;
}

.order-good {
	    display: flex;
    justify-content: space-between;
    padding: 12px 20px 18px;
    border: 1px solid #d7e1f3;
    border-top: none;
}

.order-add.size {
	float: left;
	text-align: right;
	padding: 12px 20px 18px;
	width: unset;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.order-add.size .order-add {
	padding-left:15px
}

.order-add.size .order-title b {
	font-size: 13px;
}

.order-main.order-border {
	border-bottom: none;
}

.u-inner-wrapper1 {
	margin: 17px 0 0;
	border-radius: 8px;
}

.order-good1 .order-add {
	padding: 0;
	line-height: 29px;
	margin-top: 0px;
}

.card-order.u-inner-wrapper1 {
	margin-bottom: 14px;
}

.card-order.u-inner-wrapper1 ul li {
	margin-bottom: 31px;
}

.card-order.u-inner-wrapper1 ul li .u-list-box {
	background: #fff;
	padding: 28px 20px;
	border-radius: 8px;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.012);
}

.order-good1 {
	text-align: right;
}

.order-good1 .order-add.u-dis b {
	color: #fa7102;
}

.order-add.u-dis {
	margin-bottom: 10px;
	display: block;
}

.order-good1 .order-add .u-list-tot {
	font-weight: 600;
	color: #000;
	font-size: 14px;
	margin-top: 5px;
	display: block;
}

.inventry-bottom .col-md-4 {
	padding: 0px 3px;
}

.inventry-bottom {
	display: flex;
}


/*.book.active {
    right: 0;
    visibility: visible;
    opacity: 1;
}
.book {
   
    right: -455px;
    top: 0px;
    opacity: 1;
    visibility: hidden;
    transition: all 0.5s ease-in-out 0s;
}
*/

.u-inner-wrapper th,
.u-inner-wrapper td {
	text-align: center;
}

.top-filter-box {
	margin: 0px auto;
	margin-top: 48px;
}

.main-content.report-page .inventry-top,
.main-content.report-page .inventry-bottom {
	padding: 0;
}

.u-inner-wrapper {
	width: 100%;
	margin: 22px auto;
}

.u-list-wrapper {
	margin: 0px auto;
	margin-top: 42px;
}

img.web-logo {
	display: inline-block;
}

img.mob-logo,
.mobile-nav {
	display: none;
}

.admin-container.u-table-container {
	max-width: 1455px;
}

/*shop-item css*/

.pulse-icon{position: relative; width: 63px; height: 63px; background: #fff; border-radius: 50%; color: #867b7b; font-size: 20px; text-align: center;line-height: 100px; animation: animate 2s linear infinite;    left: 50%;bottom: -119px; transform: translate(-50%, -46%);}
@keyframes animate{  0%{box-shadow: 0 0 0 0 rgba(255,255,,255,7),0 0 0 0 rgba(255,255,255,7); } 40%{box-shadow: 0 0 0 20px rgba(255,255,255,0),0 0 0 0 rgba(255,255,255,7);}80%{box-shadow: 0 0 0 20px rgba(255,255,255,0),0 0 0 30px rgba(255,255,255,0);} 100%{box-shadow: 0 0 0 0 rgba(255,255,255,0),0 0 0 30px rgba(255,255,255,0);}}
.pulse-icon img {position: absolute;top: 23px;left: 21px; width: 20px;}
.shop-item h3 {color: #000; font-size: 20px; font-weight: 500;}
.shop-create {     max-width: 322px; width: 100%; height: 309px; background: #fcc234;    border-radius: 10px;    margin: 0 0px 30px 28px;   box-shadow: 0px 5px 0px 0px #edba2f;}
.shop-item span {color: #fff;font-size: 10px;letter-spacing: 3px;line-height: 32px;text-transform: uppercase;}
.shop-item {left: 50%;transform: translate(-50%, 0%); position: relative; text-align: center;top: 150px;}
.shop-create.create { background: #ffff;position: relative;     box-shadow: 0px 5px 0px 0px #d9dfeb; }
.shop-info { text-align: center;position: relative;  top: 7%;}
.shop-create.create .dote { background: #f8f9f9;width: 34px;height: 34px; position: absolute;border-radius: 17px; right: 13px; top: 11px;}
.shop-create.create .dote i {background: #7f8593; width: 4px; height: 4px;  border-radius: 6px;position: relative;top: 9px;left: 9px;display: inline-block;margin-right: 3px;}
.shop-info h3 { font-size: 19px; margin-top: 20px;font-weight: 500;color: #2d3243;}
.shop-info a {font-size: 13px; color:rgba(0,0,0,0.7);    line-height: 29px;}
.status {     margin: 17px 0 0 22px;position: relative;bottom: -30px;}
.status-left, .status-right span { color: #b7bacb; font-size: 11px; text-transform: uppercase;display: block;}
.status-left {width: 30%;}
.status-right {width: 65%;margin-left: 13px;}
.status-left .deactive {
	color: #fff;
    border: none;
    background: #f79909;
    padding: 6px 8px;
    font-size: 11px;
    margin-top: 10px;
    text-transform: uppercase; 
    font-weight: 500;
}
.status .status-left span {
    display: block;
}
.status-left .deactive.active {background: #57b23e;}
.status-right p {font-size: 14px;color: #000;line-height: 22px;    margin-top: 8px;}
.shop-main {
	padding-left: 55px;
    margin: 50px 0px 0;
}
.bd-cn-dropdown {
    display: none;
	position: absolute;
	z-index: 1;
    width: 110px;
     top: 55px;
    right: 7px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0,0,0,0.08);
}
.bd-cn-dropdown:before {
    position: absolute;
    content: '';
    right: 12px;
    top: -5px;
    width: 0px;
    height: 0px;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 5px solid #ffffff;
}
.bd-cn-dropdown li {
    padding: 12px 15px;
    line-height: 15px;
    border-bottom: 1px solid #f8f9f9;
}
.bd-cn-dropdown li a {
    color: #565656;
	font-size: 12px;
	cursor: pointer;
}
 .bd-cn-dropdown li:hover a {
    color: #fcc234;
}
/*shop-item End*/










@media screen and (max-width:1650px) {
	.admin-container,
	.admin-container.u-table-container {
		max-width: 1180px;
	}
	.order-good .order-add span {
		
		margin-bottom: 5px;
		
	}
	.status {
    margin: 0px 0 0 22px;
    }
	.order-add.size {
		padding:20px 15px 18px;
	}
	.main-content.report-page .u-search-box {
		min-width: 150px;
	}
	.main-content.report-page .u-filter-col .btn.btn-dark.exportid.form-control {
		padding: 8px 10px;
	}
	.main-content.report-page .top-container {
		max-width: 96%;
	}
	.main-content.report-page .u-filter-col {
		padding: 0 5px;
	}

	/** shop list **/
	.shop-create {
		max-width: 100%;
		width: 29.33%; 
	}
}

@media screen and (max-width:1400px) {
	.admin-container,
	.admin-container.u-table-container {
		max-width: 1110px;
	}
	.top-container {
		max-width: 98%;
	}
	.header_container {
		padding-left: 140px;
	}
	.admin_side_bar {
		width: 140px;
	}
	.admin_menu li {
		text-align: left;
        padding: 20px 25px 30px 35px;
        margin-bottom: 25px;
    }
    .admin_side_bar .logo{
        height:130px;
    }
	.main-content {
		padding-left: 140px;
	}
	.orders-top .u-list-top-col {
		padding: 15px 0;
		font-size: 11px;
	}
	.order-main.u-top-mid-col {
		padding: 12px 20px 18px;
	}
	.order-main.order-border.u-bottom-mid-col {
		padding: 24px 22px 12px;
	}
	
	.order-main {
		width: 50%;
	}
	.top-filter-box .u-search-box {
		min-width: 220px;
	}
	/*.rear-size.col-md-7+.col-sm-2 {
		padding: 0;
	}*/
	header.admin_header {
		height: 72px;
	}
	.u-list-wrapper {
		margin-top: 25px;
	}
	.u-inner-wrapper .table th,
	.table td {
		min-width: 75px;
	}
	.main-content.report-page .u-filter-col-btn-box {
		position: absolute;
		top: -60px;
	}
	.main-content.report-page .top-filter-box {
		margin-top: 70px;
	}
	.main-content.report-page.u-tyre-report .top-filter-box.row {
		margin-top: 25px;
	}


	/** shop list **/
	.status-right{
		margin-left: 5px;
	}
	.shop-main {
		padding: 0 25px;
	}
}

@media(max-width: 1199px) {
	.top-filter-box .btn.btn-dark {
		margin-bottom: 5px;
		margin-left: 1px;
		padding: 7px 4px;
	}
	.admin-container,
	.admin-container.u-table-container {
		max-width: 850px;
	}
	.admin_side_bar {
		width: 98px;
	}
	.main-content {
		padding-left: 98px;
	}
	.admin_menu li {
		padding: 20px 21px;
	}
	.admin_menu li span {
		font-size: 12px;
	}
	.card-order.u-inner-wrapper1 ul li .u-list-box {
		padding: 22px 15px;
	}
	.u-brand-col-left {
		width: 100%;
		float: none;
	}
	.u-brand-col-right {
		width: 100%;
		display: inline-grid;
		line-height: 14px;
	}
	.u-brand-logo.col-md-3 {
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		max-width: 20%;
	}
	.rear-size.col-md-7 {
		-ms-flex: 0 0 54.333333%;
		flex: 0 0 54.333333%;
		max-width: 54.333333%;
	}
	.order-main.u-top-mid-col {
		padding: 12px 10px 18px;
		height: 100%;
		min-height: 80px;
	}
	.order-good {
		display: block;
	}
	.order-good .order-add {
		width: 100%;
		padding-top: 10px
	}
	.order-main {
		width: calc( 100% - 40%);
	}
	.order-add.size {
		padding: 0 10px 0 0;
		width: 40%;
	}
	.rear-size.col-md-7+.col-sm-2 {
		-ms-flex: 0 0 23%;
		flex: 0 0 23%;
		max-width: 23%;
	}
	.header_container {
		padding-left: 98px;
	}
	.order-good1 .order-add {
		display: block;
	}
	.order-good1 {
		text-align: right;
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
	}
	
	/** shop list **/
	.status-right{
		padding-left:10px;
	}
}

@media (max-width:991px) {
	/*body {
		padding-top: 72px !important;
	}*/
	.top-filter-box {
		margin-top: 30px;
	}
	.navbar-tool.d-none.d-lg-flex.ml-2 {
		display: flex !important;
	}
	.admin_side_bar,
	.admin_side_bar .logo {
		height: 72px;
	}
	.admin_side_bar .logo {
		height: 72px;
		padding: 2px 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.admin_menu,
	.admin_side_bar:after,
	.admin_side_bar:before {
		display: none;
	}
	.main-content {
		padding: 0 15px;
	}
	.admin-container,
	.top-container,
	.admin-container.u-table-container {
		max-width: 100%;
	}
	header.admin_header {
		height: 72px;
		box-shadow: 0 0 15px rgba(0, 0, 0, 0.055);
	}
	.header_container {
		padding-left: 66px;
	}
	.col-md-2.col-sm-12.inventry-top {
		padding: 0;
	}
	img.web-logo,
	.main_admin_header ul li.user_icon,
	.main_admin_header ul .navbar-tool {
		display: none;
	}
	.admin_side_bar .logo,
	.admin_side_bar {
		background: transparent;
	}
	.main_admin_header {
		display: flex;
		justify-content: flex-end;
	}
	.admin_side_bar {
		position: absolute;
		left: 15px;
	}
	img.mob-logo {
		display: block;
		max-width: 55px;
		margin-left: 20px;
	}
	.mobile-nav {
		display: block;
		cursor: pointer;
	}
	.u-mobile-menu-outer {
		position: fixed; 
		top: 73px;
		width: 100%;
        height: 100%;
        left:-100%;
        opacity: 0;
        visibility: hidden;
		z-index: 99;
        background: rgba(0, 0, 0, 0.55);
        transition: all 0.5s ease-in-out;
        -webkit-transition: all 0.5s ease-in-out;
    }
    .u-mobile-menu-outer.u-open-nav{
        left:0;
        opacity: 1;
        visibility: visible;
    }
	ul.u-mobile-menu { 
		 background: #fcc42b;
		width: 80%;
		text-align: left;
		height: 100%;
	}
	ul.u-mobile-menu li a {
		padding: 22px 25px;
		display: block;
		color: #343434;
		font-size: 15px;
		border-bottom: 1px solid #ecb724;
	}
	.orders-top {
		height: auto;
	}
	.orders-top .u-list-top-col span {
		display: block;
		margin: 0 0 6px;
	}
	.orders-top .u-list-top-col:after {
		right: -28%;
	}
	.top-filter-box .u-search-box {
		min-width: 150px;
	}
	.col-md-2.col-sm-12.inventry-top,
	.col-md-10.col-sm-12.inventry-bottom {
		flex: 0 0 100%;
		max-width: 100%;
		padding: 0;
	}
	.u-filter-right {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		margin: 15px -10px 0;
	}
	.main-content.report-page .u-filter-col-btn-box {
		position: absolute;
		top: -105px;
		right: 0;
	}
	.main-content.report-page .top-container {
		max-width: 100%;
	}
	.bookservicemain .fields {
		margin-bottom: 30px;
	}
	.admin_side_bar .logo {
		height: 72px !important;
	}

	/** shop list **/
	.shop-main {
		padding: 0;
		margin: 30px 0px 0;
	}
	.shop-create{
		width:45%;
		margin: 0 0px 5% 28px
	}
}

@media (max-width: 767px) {
	.main-content {
		overflow-x: hidden;
	}
	.admin-pagination {
		position: relative;
		position: relative;
		left: 0%;
		margin: 17px auto;
	}
	.admin-pagination .page-item {
		margin: 0px;
	}
	.u-inner-wrapper {
		background: #fff;
		padding: 12px;
		margin: 14px 0 0 0px;
		border-radius: 8px;
		max-width: 100%;
	}
	.admin-pagination .pagination-sm .page-link {
		border-radius: 14px;
		width: 18px;
		font-size: 10px; 
		text-align: center;
		padding: 0px;
	}
	.top-filter-box .btn.btn-dark {
		padding: 10px 18px;
		margin-top: 9px;
	}
	.inventry-top {
		margin-bottom: 9px;
	}
	.u-inner-wrapper th,
	td {
		width: 152px;
	}
	span.search {
		right: 22px;
	}
	.top-filter-box input[type="date"] {
		width: 100%;
	}
	.inventry-bottom .form-control {
		margin-bottom: 9px;
	}
	.inventry-bottom {
		display: block;
		padding: 0;
	}
	.u-filter-right {
		width: 100%;
		display: block;
		margin: 15px 0 0;
	}
	.u-filter-col {
		width: 100%;
		padding: 0;
	}
	.u-select.u-calendar-box input[type="text"] {
		max-width: 100%;
	}
	.u-filter-col .btn.btn-dark.exportid.form-control {
		justify-content: center;
	}
	.u-list-wrapper {
		margin: 0px auto;
		margin-top: 25px;
	}
	.orders-top {
		display: inline-block;
		padding-top: 15px;
	}
	.orders-top .u-list-top-col {
		padding: 0 15px 15px;
		font-size: 11px;
		width: 48%;
		display: inline-block;
	}
	.card-order.u-inner-wrapper1 ul li .u-list-box .row {
		display: block;
	}
	.card-order.u-inner-wrapper1 ul li .u-list-box .row .u-brand-logo.col-md-3,
	.card-order.u-inner-wrapper1 ul li .u-list-box .row .rear-size.col-md-7 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
	}
	.u-brand-col-left {
		width: 130px;
		float: left;
		margin: 0;
	}
	.u-brand-col-right {
		width: calc( 100% - 135px);
		display: block;
		float: left;
	}
	span.u-list-warrenty {
		display: block;
	}
	.order-good .order-add span,
	.order-add.size .order-title b {
		font-size: 11px;
	}
	.order-title b {
		font-size: 15px;
	}
	.order-add span {
		font-size: 12px;
	}
	.order-add.size {
		padding: 10px;
    width: 50%;
    text-align: left;
    display: block;
	}
	.order-add.size .order-add {
    padding: 10px 0 0;
	}
	.u-brand-logo.col-md-3 {
		border-bottom: 1px solid #d7e1f3;
		clear: both;
		display: table;
	}
	.order-good1 {
		display: inline-block;
		width: 100%;
		margin-top: 10px;
		text-align: left;
	}
	.order-good1 .order-add {
		display: inline-block;
		margin: 0;
		min-width: 49%;
		padding-left: 15px;
	}
	.rear-size.col-md-7+.col-sm-2 {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		border-top: 1px solid #d7e1f3;
	}
	.card-order.u-inner-wrapper1 ul li {
		margin-bottom: 15px;
	}
	.orders-top .u-list-top-col select {
		margin-left: -4px;
	}
	.order-add.size span {
        display: block;
        margin-bottom: 6px;
	}
	.main-content.report-page .u-filter-col-btn-box {
		position: relative;
		top: 0;
		width: 100%;
	}
	.main-content.report-page .top-filter-box {
		margin-top: 25px;
	}
	.bookservicemain .contactdetail .selectsec .row {
		margin-top: 40px;
	}
	.bookservicemain .contactdetail {
		z-index: 1;
		margin-right: -15px;
	}
	.bookservicemain .contactdetail {
		padding: 35px 20px;
	}
	.bookservicemain h2 {
		font-size: 22px;
	}
	.bookservicemain .contactdetail .selectsec {
		margin-top: 45px;
		height: calc( 100vh - 165px);
		overflow-y: auto;
		overflow-x: hidden;
		padding-right: 15px;
	}
	.bookservicemain button.button2 {
		left: 0;
		height: 56px;
		margin-right: 15px;
        position: fixed;
		bottom: 0;
		border-radius: 0;
	}
	.book {
		width: 100%;
		right: -100%
	}
	.bookservicemain .custom-select,
	.bookservicemain .form-control {
		height: 52px;
    }
    .shop-main {
     padding-left: 0px; 
}
    .orders-top .u-list-top-col:after{
        display: none;
	}
	/** shop list **/
	 
	.shop-create{
		width:44%;
	}
}

@media(max-height:700px){
    .admin_menu li { 
        margin-bottom: 15px;
    }
    .admin_side_bar .logo{
        height:130px;
    }
}


@media (max-width: 567px) {
	.shop-main .row {
		margin: 0;
	}
	.shop-create {
		width: 100%;
		margin: 0 0 28px;
		height: 309px;
		padding: 25px;
	}
	.shop-item { 
		top: 130px;
	}
	
}


/*arabic_admin site css*/



.page-rtl{
	direction: rtl;
}

.page-rtl .header_container{
	padding-right: 192px;
	padding-left: unset;
}
.page-rtl .barline{
	right: unset;
	left: 70px;
}
.page-rtl .admin_main_header{
	padding-left: unset;
	padding-right: 32px;
}

.page-rtl .admin_side_bar {
    right: 0;
    left: unset;
}
.page-rtl .main_admin_header ul li.user_icon a {
    margin: 0 !important;
}
.page-rtl span.user_name{
	padding-right: unset;
	padding-left: 60px;
}
.page-rtl .admin_side_bar:before{
	right:unset;
	left: 0;
}
.page-rtl .admin_menu li.active:after{
	right: unset;
	left: 0;
	transform: rotate(180deg);
}
.page-rtl .main-content{
	padding-left: unset;
	padding-right: 192px;
}
.page-rtl .u-select.u-calendar-box input[type="text"]{
	padding-right: 40px;
	padding-left: unset;
}
.page-rtl span.search{
	right: unset;
	left: 14px;
}
.page-rtl .top-filter-box .u-select:after{
	right: unset;
	left: 12px
}
.page-rtl .u-inner-wrapper .table-head th:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.page-rtl .u-inner-wrapper .table-head th:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0px;
     border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.page-rtl .book.show{
	right: unset;
	left: 0
}
.page-rtl .book{
right: unset;
left: -600px;
}
.page-rtl .book .close{
	right: unset;
	left: 0
}
.page-rtl .u-select.u-calendar-box .u-calendar{
	left: unset;
	right: 15px
}
.page-rtl .top-filter-box select.form-control{
	padding: 0 15px 0px 40px
}

.page-rtl .inventry-top {
    text-align: right;
}
.page-rtl .u-brand-col-left{
	float: right;
	padding-left: 0;
	padding-right: 5px;
}
.page-rtl .u-brand-col-right{
	padding: 11px 20px 11px 6px;
	text-align: right;
}
.page-rtl .order-main{
	text-align: right;
}
.page-rt .simple-steps .tire-deco{
	left: 0;
	right: unset;
}
.page-rtl .shop-create{
    margin:0 28px 30px 0px;
}
.page-rtl .shop-main {
    padding-left: 0;
    padding-right: 55px;
}
.page-rtl .pulse-icon{
	left: unset;
	right: 32%;
}
.page-rtl .fields label{
	left: unset;
	right: 26px
}
.page-rtl .bookservicemain .custom-select {
    background: #fff url(../assets/select-arrow.png) no-repeat left 1rem center/7px 6px;
}
.page-rtl .bookservicemain:after{
	right: 0
}
/*.u-select.u-calendar-box .u-calendar{
	left: unset;
	right: 15px
}*/
@media screen and (max-width: 1400px){
.page-rtl .header_container {
    padding-right: 140px;
    padding-left: 0
}
.page-rtl .main-content{
	padding-left: unset;
	padding-right: 140px;
}
.page-rtl .rear-size.col-md-7+.col-sm-2 {
    padding: 0 15px;
}
}

@media (max-width: 991px){
.page-rtl .main-content {
    padding: 0 15px;
}
.page-rtl .header_container {
    padding-right: 66px;
}
.page-rtl img.mob-logo{
margin-left: unset;
margin-right: 20px;
}
.page-rtl .admin_side_bar {
    position: absolute;
    left: unset;
    right: 15px;
}
.page-rtl .u-mobile-menu-outer.u-open-nav{

	left: unset;
	right: 0
}
.page-rtl .u-mobile-menu-outer{
	left: unset;
	right: -100px;
}
}

.shop-info img {
	width: 100px;
	height: 100px;
	border-radius: 100%;
 }

 @media (max-height: 900px){
	.admin_menu li{
	  padding: 20px 25px 10px 35px;
	  margin-bottom: 15px
	
}
  }




.admin_header .main_admin_header .user_icon .sub_drop_menu {
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform-origin: top center;
    -webkit-transform-origin: top center;
    left: auto;
    right: 0;
    top: 100%;
    height: 44px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    background: #ffffff;
    width: 160px;
    border-radius: 5px;
    border: solid 1px #ececec;
    -webkit-box-shadow: 0 0 55px rgba(0, 0, 0, 0.11);
    box-shadow: 0 0 55px rgba(0, 0, 0, 0.11);
    position: absolute;
    z-index: 999999;
    visibility: hidden;
    margin-top: 15px;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu {
    width: 168px;
    border-color: #efefef;
}
.admin_header .main_admin_header .user_icon:hover .sub_drop_menu {
    transform: scaleY(1);
    -webkit-transform: scaleX(1);
    opacity: 1;
    visibility: visible;
}

.main_admin_header ul li ul {
    display: none;
    min-width: 60px;
    background: #fff;
    padding: 0;
    border: 1px solid #000;
}
.main_admin_header ul li:hover ul {
    display: block;
    position: absolute;
}
.main_admin_header ul li:hover ul li a:first-child {
    border-bottom: 1px solid #ededed;
}

.main_admin_header ul li:hover ul li.nav-item a {
    padding: 10px 15px!important;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu:before {
    border-color: rgba(236, 236, 236, 0);
    border-bottom-color: #ececec;
    border-width: 9px;
    margin-left: -9px;
}

.admin_header .main_admin_header .user_icon .sub_drop_menu:after, .admin_header .main_admin_header .user_icon .sub_drop_menu:before {
    bottom: 100%;
    left: 88%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 8px;
    margin-left: -8px;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu li a {
    display: flex;
    align-items: center;
    padding: 18px 25px;
    color: #7e7e7e;
    border-left: solid 2px transparent;
    transition: ease-in-out 0.3s;
    border-top: solid 1px transparent;
    border-bottom: solid 1px transparent;
}

.admin_header .main_admin_header .user_icon .sub_drop_menu li a {
    height: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    width: 100%;
    line-height: 1.6;
    text-align: left;
    padding: 10px 15px;
    font-size: 13px;
    margin: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.99);
}
.admin_header .main_admin_header .user_icon .sub_drop_menu li a:hover {
    background: #fcfcfc;
    color: #000;
    border-left-color: #fcc234;
    border-top-color: #f3f3f3;
    border-bottom-color: #f3f3f3;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu li {
    width: 100%;
    padding: 0;
    margin: 0;
}.admin_header .main_admin_header .user_icon .sub_drop_menu {
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform-origin: top center;
    -webkit-transform-origin: top center;
    left: auto;
    right: 0;
    top: 100%;
    height: 44px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    background: #ffffff;
    width: 160px;
    border-radius: 5px;
    border: solid 1px #ececec;
    -webkit-box-shadow: 0 0 55px rgba(0, 0, 0, 0.11);
    box-shadow: 0 0 55px rgba(0, 0, 0, 0.11);
    position: absolute;
    z-index: 999999;
    visibility: hidden;
    margin-top: 15px;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu {
    width: 168px;
    border-color: #efefef;
}
.admin_header .main_admin_header .user_icon:hover .sub_drop_menu {
    transform: scaleY(1);
    -webkit-transform: scaleX(1);
    opacity: 1;
    visibility: visible;
}

.main_admin_header ul li ul {
    display: none;
    min-width: 60px;
    background: #fff;
    padding: 0;
    border: 1px solid #000;
}
.main_admin_header ul li:hover ul {
    display: block;
    position: absolute;
}
.main_admin_header ul li:hover ul li a:first-child {
    border-bottom: 1px solid #ededed;
}

.main_admin_header ul li:hover ul li.nav-item a {
    padding: 10px 15px!important;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu:before {
    border-color: rgba(236, 236, 236, 0);
    border-bottom-color: #ececec;
    border-width: 9px;
    margin-left: -9px;
}

.admin_header .main_admin_header .user_icon .sub_drop_menu:after, .admin_header .main_admin_header .user_icon .sub_drop_menu:before {
    bottom: 100%;
    left: 88%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 8px;
    margin-left: -8px;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu li a {
    display: flex;
    align-items: center;
    padding: 18px 25px;
    color: #7e7e7e;
    border-left: solid 2px transparent;
    transition: ease-in-out 0.3s;
    border-top: solid 1px transparent;
    border-bottom: solid 1px transparent;
}

.admin_header .main_admin_header .user_icon .sub_drop_menu li a {
    height: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    width: 100%;
    line-height: 1.6;
    text-align: left;
    padding: 10px 15px;
    font-size: 13px;
    margin: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.99);
}
.admin_header .main_admin_header .user_icon .sub_drop_menu li a:hover {
    background: #fcfcfc;
    color: #000;
    border-left-color: #fcc234;
    border-top-color: #f3f3f3;
    border-bottom-color: #f3f3f3;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu li {
    width: 100%;
    padding: 0;
    margin: 0;
}.admin_header .main_admin_header .user_icon .sub_drop_menu {
    transform: scaleY(0);
    -webkit-transform: scaleY(1);
    transform-origin: top center;
    -webkit-transform-origin: top center;
    left: auto;
    right: 0;
    top: 100%;
    height: 44px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    background: #ffffff;
    width: 160px;
    border-radius: 5px;
    border: solid 1px #ececec;
    -webkit-box-shadow: 0 0 55px rgba(0, 0, 0, 0.11);
    box-shadow: 0 0 55px rgba(0, 0, 0, 0.11);
    position: absolute;
    z-index: 999999;
	/* visibility: visible; */
	display: block;
    margin-top: 15px;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu {
    width: 168px;
    border-color: #efefef;
}
.admin_header .main_admin_header .user_icon:hover .sub_drop_menu {
    transform: scaleY(1);
    -webkit-transform: scaleX(1);
    opacity: 1;
    visibility: visible;
}

.main_admin_header ul li ul {
    display: none;
    min-width: 60px;
    background: #fff;
    padding: 0;
    border: 1px solid #000;
}
.main_admin_header ul li:hover ul {
    display: block;
    position: absolute;
}
.main_admin_header ul li:hover ul li a:first-child {
    border-bottom: 1px solid #ededed;
}

.main_admin_header ul li:hover ul li.nav-item a {
    padding: 10px 15px!important;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu:before {
    border-color: rgba(236, 236, 236, 0);
    border-bottom-color: #ececec;
    border-width: 9px;
    margin-left: -9px;
}

.admin_header .main_admin_header .user_icon .sub_drop_menu:after, .admin_header .main_admin_header .user_icon .sub_drop_menu:before {
    bottom: 100%;
    left: 88%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 8px;
    margin-left: -8px;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu li a {
    display: flex;
    align-items: center;
    padding: 18px 25px;
    color: #7e7e7e;
    border-left: solid 2px transparent;
    transition: ease-in-out 0.3s;
    border-top: solid 1px transparent;
    border-bottom: solid 1px transparent;
}

.admin_header .main_admin_header .user_icon .sub_drop_menu li a {
    height: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    width: 100%;
    line-height: 1.6;
    text-align: left;
    padding: 10px 15px;
    font-size: 13px;
    margin: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.99);
}
.admin_header .main_admin_header .user_icon .sub_drop_menu li a:hover {
    background: #fcfcfc;
    color: #000;
    border-left-color: #fcc234;
    border-top-color: #f3f3f3;
    border-bottom-color: #f3f3f3;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu li {
    width: 100%;
    padding: 0;
    margin: 0;
}.admin_header .main_admin_header .user_icon .sub_drop_menu {
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform-origin: top center;
    -webkit-transform-origin: top center;
    left: auto;
    right: 0;
    top: 100%;
    height: 44px;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    background: #ffffff;
    width: 160px;
    border-radius: 5px;
    border: solid 1px #ececec;
    -webkit-box-shadow: 0 0 55px rgba(0, 0, 0, 0.11);
    box-shadow: 0 0 55px rgba(0, 0, 0, 0.11);
    position: absolute;
    z-index: 999999;
    visibility: hidden;
    margin-top: 15px;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu {
    width: 168px;
    border-color: #efefef;
}
.admin_header .main_admin_header .user_icon:hover .sub_drop_menu {
    transform: scaleY(1);
    -webkit-transform: scaleX(1);
    opacity: 1;
    visibility: visible;
}

.main_admin_header ul li ul {
    display: none;
    min-width: 60px;
    background: #fff;
    padding: 0;
    border: 1px solid #000;
}
.main_admin_header ul li:hover ul {
    display: block;
    position: absolute
}
.main_admin_header ul li:hover ul.lag_dorpdown{
	min-height:90px;
}
.main_admin_header ul li:hover ul li a:first-child {
    border-bottom: 1px solid #ededed;
}

.main_admin_header ul li:hover ul li.nav-item a {
    padding: 10px 15px!important;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu:before {
    border-color: rgba(236, 236, 236, 0);
    border-bottom-color: #ececec;
    border-width: 9px;
    margin-left: -9px;
}

.admin_header .main_admin_header .user_icon .sub_drop_menu:after, .admin_header .main_admin_header .user_icon .sub_drop_menu:before {
    bottom: 100%;
    left: 88%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 8px;
    margin-left: -8px;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu li a {
    display: flex;
    align-items: center;
    padding: 18px 25px;
    color: #7e7e7e;
    border-left: solid 2px transparent;
    transition: ease-in-out 0.3s;
    border-top: solid 1px transparent;
    border-bottom: solid 1px transparent;
}

.admin_header .main_admin_header .user_icon .sub_drop_menu li a {
    height: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    width: 100%;
    line-height: 1.6;
    text-align: left;
    padding: 10px 15px;
    font-size: 13px;
    margin: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.99);
}
.admin_header .main_admin_header .user_icon .sub_drop_menu li a:hover {
    background: #fcfcfc;
    color: #000;
    border-left-color: #fcc234;
    border-top-color: #f3f3f3;
    border-bottom-color: #f3f3f3;
}
.admin_header .main_admin_header .user_icon .sub_drop_menu li {
    width: 100%;
    padding: 0;
    margin: 0;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}


.admin_header_main {
    padding-top: 86px;
}
@media (max-width: 767px) {
	.admin_header_main {
    padding-top: 0px;
    padding-bottom: 72px;
}
.shop-info h3 {
    font-size: 15px;
}
.shop-info a {
    font-size: 12px;
}
.status-right p {
    font-size: 12px;
}
.shop-info img {
    width: 70px;
    height: 70px;
 }


}

label.check-label {
    display: block;
    position: relative;
    cursor: pointer;
    /* font-size: 22px; */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.bookservicemain input[type="checkbox"].form-control.mb-2.mr-sm-2 {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 20px;
    left: 5px;
    height: 40px;
    width: 40px;
    background-color: transparent;
	border: 1px solid #dae1e7;
	border-radius: 5px;
}
  
  /* On mouse-over, add a grey background color */
  label.check-label:hover input ~ .checkmark {
	background-color: transparent;
  }
  
  /* When the checkbox is checked, add a blue background */
  label.check-label input:checked ~ .checkmark {
	background-color: #ffbe2c;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  
  /* Show the checkmark when checked */
  label.check-label input:checked ~ .checkmark:after {
	display: block;
  }
  
  /* Style the checkmark/indicator */
  label.check-label .checkmark:after {
    left: 14px;
    top: 7px;
    width: 10px;
    height: 19px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}
.bookservicemain .contactdetail .selectsec .row{
	margin-top: 40px;
}
.book.show{
	overflow-y: inherit;
}
@media (max-width: 767px){
	label.check-label {
		display: block;
		position: relative;
		cursor: pointer;
		/* font-size: 22px; */
		-webkit-user-select: none;
		user-select: none;
		left: 14px;
		top: 0;
	}
	.page-rtl .shop-main {
    padding-left: 0;
    padding-right: 0;
	}
	.page-rtl .shop-create {
    margin: 0 0px 30px 0px;
}
	.checkmark {
		position: absolute;
		top: -15px;
		left: 65px;
		height: 40px;
		width: 40px;
		background-color: transparent;
		border: 1px solid #dae1e7;
		border-radius: 5px;
	}
}
