

/*header marvelapp*/

.toolbar-enabled.toolbar-marvelapp {background: #474747;}
.navbar-left {display: flex;position: absolute;align-items: center;}
.navbar-left h1 {    margin: 5px 6px;color: #fff;font-size: 22px;font-weight: 400;}
.navbar-left img{   width: 20px;display: inline;margin-right:10px;-webkit-filter:brightness(0) invert(1);filter:brightness(0) invert(1)}
.navbar-right ul li {    padding: 24px 30px;float: left;text-transform: uppercase;     position: relative;}
.navbar-right ul li a {color: #858585;font-size: 13px;text-decoration: none;position: relative;font-weight: 500;}
.navbar-right ul li a:after {background: rgb(255 190 0 / 55%);content: '';display: inline-block; height: 4px;margin: auto;position: absolute; bottom: 4px;left: -10px;transition: all 300ms; width: 0%;}
.navbar-right ul li a:hover,
.navbar-right ul li.active a { color: #ffffff;}
.navbar-right ul li a:hover:after,
.navbar-right ul li.active a:after {width: 74%;}
.left-to-right li a:after { right: inherit; }
.right-to-left li a:after { left: inherit; }
.navbar-by h1 {position: relative;font-size: 44px;font-weight: bold;color: #4d4d4d;margin-left: 9px;
    margin-top: -6px;}
.filter-header{
    padding-top: 26px;
}
.brands-marvelapp .brands img{
    margin-bottom: 10px;
    height: 100%;
}
.brands-marvelapp .brands .img-box{
height: 60px;
display: flex;
    align-items: center;
}
/*header marvelapp*/
  .brands-marvelapp {background: #ebedf4;padding: 90px 0 40px;margin-top: 20px;height: inherit;border-radius: 40px 40px 0px 0px;}
.brands-marvelapp a { border: 1px solid #e3e5e9; height: 120px;text-align: center;align-items: center;justify-content: center;border-radius: 10px;box-shadow: 0 0 6px rgb(0 0 0 / 4%);}
.brands-estb{     background: #ebedf4; padding: 90px 0 40px;margin-top: 20px;height: inherit;border-radius: 40px 40px 0px 0px;}
.brands-estb .container {max-width: 800px;}
.brands-marvelapp .col{    min-width: 140px;padding: 0 12px;}
.brands-marvelapp .container { max-width: 800px;}
.audo{ display: block;color: #7b7b7b;font-weight: 500; font-size: 13px;}

.brands-marvelapp .active a{    border: 1px solid #ffbf39;    box-shadow: 0px 0px 14px rgb(0 0 0 / 18%);}
.step{font-size: 18px; position: absolute;top: 46px; left: 26px;color: #fff;}
.brands-marvelapp .active:before {position: absolute; content: ""; top: -8px;right: 0px; background: url(../assets/ok.png); background-repeat: no-repeat; background-size: auto;height: 21px;width: 20px;}
/*estb1*/
.year-filter li a.active  {    background-color: #474747!important; color: #fff!important; border: 1px solid #ccc1!important;}
.year-filter li a{ border: 1px solid #dcdee5;   color: #474747; display: flex;justify-content: center; align-items: center;background-color: #fff;padding: 15px 10px;font-size: 18px; font-weight: 500; border-radius: 12px;box-shadow: 0 0 6px rgb(0 0 0 / 4%); width: 126px; margin-bottom: 26px;}
.year-filter li{  padding: 0 12px;  display: inline-block;}
 .navbar-right ul li span:after{
background: rgb(255 190 0 / 56%);
    content: '';
    display: inline-block;
    height: 4px;
    margin: auto;
    position: absolute;
    bottom: 5px;
    left: -1px;
    transition: all 300ms;
    width: 26%;
    text-transform: uppercase;
 }
.tire-boxes h3{
margin-top: 5px;
font-size: 18px;
font-weight: 500;
    margin-bottom: 12px;
}


 /*esbl3*/
 .card-box {background: #fff;   padding:  24px; border-radius: 12px;position: relative;    z-index: 1;}

.box-right {
    border: 1px solid #f5f6f8;
    padding: 20px 22px 8px;
    border-radius: 12px;
    text-align: center;
    position: relative;
}
.box-left span { color: #ccc; font-size: 10px; line-height: 21px;text-transform: uppercase;}
.box-right span {  font-size: 30px; color: #000;position: relative;font-weight: 500;}
 .box-right p {  
    font-size: 12px;
    color: #474747;
    line-height: 20px;
    margin-top: 8px;
    font-weight: 500;
 }
 .box-right span.right-pin{
border-right: 1px solid #f5f6f8;
padding-right: 10px;
margin-right: 10px;
display: inline-block;
line-height: 22px;
 }
 .multiple.box-right{
height: 90px;
 }
.box-right span:after {background: rgb(255 190 0 / 84%);  content: ''; display: inline-block; height: 2px;margin: auto;position: absolute;   bottom: -8px;  top: -8px;left: -3px; transition: all 300ms; width: 18px;}
.select-btn a {
    position: absolute;
    content: '';
    background: #fcc223;
    padding: 46px 28px 21px;
    right: 16px;
    bottom: -46px;
    border-radius: 10px;
    text-align: center;
    color: #fff;
    height: 76px;
    font-weight: 500;
    font-size: 14px;
}
.box-right img {     position: absolute;
    right: 22px;
    top: 11px;
    width: 22px;}

.card-top { margin-bottom: 82px;}

/*The Modal*/

#Proceed .modal-body.quantity input {background:#fdfdfe; width: 100%; padding: 14px; margin-bottom: 18px;border: 1px solid #f0f2f5; border-radius: 6px;}
#Proceed .modal-body.quantity input:focus, #Proceed .modal-body.quantity input:active{outline:0;border: 1px solid #ebeef1}
#Proceed button.close { 
    position: absolute;
    right: 22px;
    top: 12px;
    font-size: 30px;
    color: #000;
    font-weight: 300;
    opacity: 1;
    z-index: 10;
}
#Proceed button.close:after{

}
#Proceed .modal-header.title-modal{ border: none;padding-bottom: 0;}
#Proceed .modal-header.title-modal h4 {z-index: 1; position: relative; text-align: center; margin: auto;  font-size: 18px; padding: 22px 0 0; color: #474747;font-weight: 500;}
#Proceed .modal-header.title-modal h4:after{
height: 9px;
    content: "";
    width: 38px;
    background: rgb(252 194 35 / 34%);
    position: absolute;
    left: 45px;
    z-index: -1;
    bottom: 7px;
}
#Proceed .modal-header.title-modal h4.modal-title.cancelpopup::after {
    bottom: unset;
    top: 36px;
    width: 45px;
    left: 15px;
}
#Proceed .footer-btn img {width: 18px; position: relative; top: 4px; left: 6px;-webkit-filter:brightness(0) invert(1);filter:brightness(0) invert(1)}
#Proceed .modal-footer.proceed { border: none;    padding: 0px 16px 16px;}
#Proceed .modal-dialog { max-width: 370px;    top: 240px;}
#Proceed .modal-content{padding: 10px;border-radius: 20px;border: 0}
#Proceed .footer-btn { width: 100%;padding: 17px; background: #fcc223;border: none;color: #fff;border-radius: 7px;font-size: 15px;}
#Proceed .modal-body.quantity input[type="text"]::placeholder { color: #c8c8c9;font-size: 12px;}
#Proceed .modal-body{padding-bottom: 0}
@media (min-width: 1280px){
.container-fluid, .container-xl {
    padding-right: 30px;
    padding-left: 30px;
}
}
.page-rtl #Proceed button.footer-btn.searchbyvehicle img {
    right: 72px;
}
.row.pb-2.pb-sm-0.pb-md-3.brands {
    text-align: center;
  }