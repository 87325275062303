.ht:hover .tooltip-marker {
    display:block;
}

.tooltip-marker {
    display: none;
    margin-top: 0px;
    color: #000;
    position: absolute;
    z-index: 1000;
    /* height: 20px; */
    width: 200px;
    background-color: #fff;
    overflow: hidden;
    text-align: center;
    padding: 10px 0;
    border-radius: 4px;
    font-weight: 12px;
    font-weight: 500;
    margin-top: -15px;
    margin-left: 10px;
}