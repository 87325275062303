.price_summarylisting .paymentoptwrap li{padding: 6px 0;}
.paymentoptwrap li .titlename{color: #373737; font-weight: 500;margin-bottom: 10px;}
.paymentoptwrap li label{display: flex;align-items: center;font-size: 14px;font-weight: normal;font-size: 13px;min-height: 26px;}
.paymentoptwrap li label input{margin-right: 5px;}
.paymentoptwrap li label span{margin-left: 5px;}
.paymentoptwrap li label span img{max-width: 60px;}

.page-rtl .paymentoptwrap li label input{margin-right: 0;margin-left: 5px;}
.page-rtl .paymentoptwrap li label span{margin-left: 0px;margin-right: 5px;}

.confirmmodelwrap .modal-header { 
    font-size: 18px;
    font-weight: normal;color: #000000;border-bottom: none;padding-bottom: 0;align-items: flex-start;}
    .confirmmodelwrap .modal-header .close{    margin: 0;
        padding: 10px;margin-right: -5px;}
    .confirmmodelwrap .modal-header span{width: 100%;font-size: 80%; color: #ababab; margin-top: 5px;align-items: flex-start;display: block;}
    .confirmmodelwrap .modal-body{padding-bottom: 0px;}
    .confirmmodelwrap .modal-body .error-input{    margin-top: 5px;
        display: block;
        font-size: 12px;margin-bottom: 0;}
    .confirmmodelwrap .modal-footer{padding-top: 0;border: none; justify-content: center;}
    .confirmmodelwrap .modal-footer .btn-primary{color: #fff;background-color: #fcc223;
        border-color: #fcc223;}

        .page-rtl.confirmmodelwrap .modal-header{text-align: right;align-items: flex-start;}
        .page-rtl .confirmmodelwrap .modal-footer{flex-flow: row-reverse;}
        .page-rtl.confirmmodelwrap .modal-body .error-input{text-align: right;}