@charset "UTF-8";
@font-face {
    font-family: 'Segoe UI';
    src: url('../assets/Fonts/SegoeUI-Light.woff2') format('woff2'),
        url('../assets/Fonts/SegoeUI-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}


@font-face { font-family: 'Celias'; src: url("../assets/Fonts/Celias-Medium.woff2") format("woff2"), url("../assets/Fonts/Celias-Medium.woff") format("woff"); font-weight: 500; font-style: normal; }
@font-face { font-family: 'Celias'; src: url("../assets/Fonts/Celias-Regular.woff2") format("woff2"), url("../assets/Fonts/Celias-Regular.woff") format("woff"); font-weight: normal; font-style: normal; }
@font-face { font-family: 'Celias'; src: url("../assets/Fonts/Celias-Light.woff2") format("woff2"), url("../assets/Fonts/Celias-Light.woff") format("woff"); font-weight: 300; font-style: normal; }
@font-face {font-family: "Circular Std Black";
  src: url("../assets/Fonts/Circular-Std-Black.eot"), /* IE9*/
  url("../assets/Fonts/Circular-Std-Black.woff") format("woff2"), /* chromeã€firefox */
  url("../assets/Fonts/Circular-Std-Black.woff2") format("woff"), /* chromeã€firefox */
  url("../assets/Fonts/Circular-Std-Black.ttf") format("truetype"); /* chromeã€firefoxã€operaã€Safari, Android, iOS 4.2+*/
}
/*body css*/
.page-rtl h1, h2, h3, h4, h5, h6{
  font-family: 'Segoe UI' !important;

}
.page-rtl{
  font-family: 'Segoe UI' !important;
  font-size:16px;
} 
.page-rtl p{
  font-size: 16px;
  font-family: 'Segoe UI' !important
}
.page-rtl p{
  font-size: 16px
}
.page-rtl span{
  font-size: 14px;
  font-family: 'Segoe UI' !important
}

html, body, div, span, iframe, h1, h2, h3, h4, h5, h6, p, a, em, img, small, strong, sub, sup, b, i, center, ul, li, form, label, table, tbody, tfoot, thead, tr, th, td, aside, embed, footer, header, menu, nav, section, audio, video { margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit; vertical-align: baseline; }
article, aside, details, footer, header, menu, nav, section { display: block; }
html, body { height: 100%; font-family: "Celias", sans-serif; padding: 0px; margin: 0px; }
body { line-height: 1; font-family: "Celias", sans-serif; overflow-x: hidden; background: #f6f8fc;font-size: 13px; }
ol, ul { list-style: none; }
table { border-collapse: collapse; border-spacing: 0; }
*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }
a { outline: none !important; }
a:hover {color: #000000;text-decoration: none;}
h1, h2, h3, h4, h5, h6 { padding: 0px; margin: 0px; font-family: "Celias", sans-serif; font-weight: normal; font-style: normal; }
h1 { font-size: 24px; font-weight: normal; font-style: normal; }
.navbar-light .nav-item:hover .nav-link:not(.disabled), .navbar-light .nav-item:hover .nav-link:not(.disabled)>i{color:#000;}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {color: #000;}
.navbar-floating{padding: 8px 0; }
.navbar-brand{margin-right: 12px!important}
/*Custom css*/
.preloading {
    width: 100%;
    text-align: center;
    height: 100%;
    position: fixed;
    background: #ffffff;
    z-index: 999999;
    top: 0px;
    display: table;
    left: 0;
}
.preloading .wrap-preload {
    background: #fcc223;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
}
.preloading .cssload-loader {
    width: 49px;
    height: 49px;
    border-radius: 50%;
    margin: 3em;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    background: #ffffff;
}
.preloading .cssload-loader, .preloading .cssload-loader:before, .preloading .cssload-loader:after {
    animation: 1.15s infinite ease-in-out;
    -o-animation: 1.15s infinite ease-in-out;
    -ms-animation: 1.15s infinite ease-in-out;
    -webkit-animation: 1.15s infinite ease-in-out;
    -moz-animation: 1.15s infinite ease-in-out;
}
.preloading .cssload-loader {
    animation-name: cssload-loader;
    -o-animation-name: cssload-loader;
    -ms-animation-name: cssload-loader;
    -webkit-animation-name: cssload-loader;
    -moz-animation-name: cssload-loader;
}
@keyframes cssload-loader {
    from {
        transform: scale(0);
        opacity: 1;
    }
    to {
        transform: scale(1);
        opacity: 0;
    }
}

@-o-keyframes cssload-loader {
    from {
        -o-transform: scale(0);
        opacity: 1;
    }
    to {
        -o-transform: scale(1);
        opacity: 0;
    }
}

@-ms-keyframes cssload-loader {
    from {
        -ms-transform: scale(0);
        opacity: 1;
    }
    to {
        -ms-transform: scale(1);
        opacity: 0;
    }
}

@-webkit-keyframes cssload-loader {
    from {
        -webkit-transform: scale(0);
        opacity: 1;
    }
    to {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@-moz-keyframes cssload-loader {
    from {
        -moz-transform: scale(0);
        opacity: 1;
    }
    to {
        -moz-transform: scale(1);
        opacity: 0;
    }
}
@media (min-width: 1280px){
.container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1170px;
}
.container-fluid, .container-xl {
    padding-right: 40px;
    padding-left: 40px;
}

}
@media (min-width: 992px){
.navbar-sticky.navbar-stuck.navbar-floating.navbar-light {
    background-color: #fcc223;
}
}
@media (min-width: 1280px){
.banner-section .container-fluid, .banner-section .container-xl {
    padding-right: 15px;
    padding-left: 15px;
}
}
.navbar .navbar-nav .nav-link{
color: #000;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    display: flex;
    text-transform: uppercase;
}
.navbar-floating.navbar-dark .navbar-collapse{
  background-color: #fcc223;
}
.navbar .navbar-nav .nav-link img{
  margin-right: 6px;
      margin-top: -4px;
}
.navbar .navbar-nav .nav-link img.drop-arrow{
margin-left: 8px;
margin-top: 0;
}
.navbar-light .mega-nav::after{
  background-color: rgba(255,255,255,0.12);
}
.navbar-tool-icon-box{
  line-height: 60px;
}
.mega-nav::after{
  left: -12px;
  right: auto;
}
.navbar .navbar-nav .nav-link:hover, .navbar .navbar-nav .nav-link:focus{
    color: #000;
}
.navbar .nav-item:hover .nav-link:not(.disabled) {
    color: #000;
}
.navbar .navbar-tool-icon-box .notification-badge{
background: #f64234;
    color: #fff;
    height: 16px;
    display: flex;
    font-size: 8px;
    justify-content: center;
    align-items: center;
    width: 16px;
    right: 6px;
    top: 12px;
    position: absolute;
    border-radius: 100%;
}
.logo-caption{
color: #000;
font-size: 12px;
font-weight: 500;
}
.logo-caption span{
  display: block;
  text-transform: uppercase;
  font-size: 10px;
  opacity: .3;
  padding-bottom: 8px;
}
.logo-caption img{
margin-left: 2px;
}


.banner-section .banner-box {
  position: relative;
  margin: 0 auto;
}
.banner-section .banner-box:after{
content: "";
   height: 36vh;
    position: absolute;
    top: 26px;
    border: 2px solid rgb(255 255 255 / 16%);
    width: 98%;
    z-index: 0;
    border-radius: 10px;
}
.banner-section .slider-for {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
}

.banner-section .slide-container {
  height: 20vh;
  background-color: transparent;
  text-align: center;
  line-height: 20vh;
  font-size: 40px;
  font-weight: bold;
  border: 0;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.banner-section .decor {
  padding: 5px 40px;
  background-color: #fff;
  border: 2px solid #000;
}

.banner-section .slide-btn {
  box-sizing: border-box;
  height: 44vh;
  background-color: #000;
  cursor: pointer;
  display: flex !important;
  align-items: flex-end;
  justify-content: center;
  font-size: 30px;
  min-height: 120px;
  transition: 0.5s all;
}

.banner-section .slick-active-first .slide-btn {
  background-color: red;
}

.banner-section .slick-active-last .slide-btn {
  background-color: orange;
}

.banner-section .slider-dots-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: -50px;
    z-index: 1;
}

.banner-section .slider-dots {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30px;
}

.banner-section .slider-dots button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  border: 0;
  background-color: transparent;
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  transition: .3s all;
  position: relative;
  z-index: 0;
}

.banner-section .slider-dots-box .slick-active button {
    width: 40px;
    height: 40px;
}

.banner-section .slider-dots button:after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #fff;
  opacity: 0.4
}

.banner-section .slider-dots-box .slick-active button:after {
  content: none;
  display: none;
}

.banner-section .slider-dots-box .slick-active button:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}

.banner-section .circle-bg {
  fill: rgba(255, 255, 255, 0);
  stroke: rgba(0, 0, 0, 0.05);
  stroke-width: 1;
  stroke-linecap: butt;
}
.banner-section .circle-go {
  fill: rgba(255, 255, 255, 0);
  stroke: rgb(255, 255, 255);
  stroke-width: 1;
  stroke-linecap: round;
  animation: progress 16s;
  stroke-dasharray: 300px;
  stroke-dashoffset: 300;
}
.banner-section .circle-tx {
  fill: #fff;
  stroke-width: 3;
  text-anchor: middle;
font-size: 1px;
font-weight: 800;
stroke: rgb(255, 255, 255);
}
.banner-section .circle-tx:after{
  content: "";
  height: 4px;
  width: 4px;
  background: #fff;
}

@keyframes progress {
  from {
    stroke-dashoffset: 360;
  }
  
  to {
    stroke-dashoffset: 0;
  }
}
.banner-section .slider-nav .img-fill {
    width: 100%;
    display: block;
    overflow: hidden;
    position: relative;
}
.banner-section .slider-nav .slick-list{
  border-radius: 8px;
}
.banner-section .slider-nav .img-fill img {
min-height: 100%;
    min-width: 100%;
    position: relative;
    display: inline-block;
    max-width: none;
    height: 44vh;
    top: 5px;
}
.banner-section .slider-nav{
  z-index: 1;
}

.banner-section{
  background: #fcc223;
        padding: 130px 130px 200px;
        position: relative;
}
.banner-section:before{
content: "";
background: url(../assets/left-city.png) no-repeat;
position: absolute;
height: 130px;
width: 100%;
    bottom: 0;
    left: 0
}
.banner-section:after{
    content: "";
    background: url(../assets/right-city.png) no-repeat;
    position: absolute;
    height: 130px;
    width: 226px;
    bottom: 0;
    right: 0;
}
.banner-section .slider-nav .img-fill .info {
    position: absolute;
    width: 100%;
    top: 5px;
    left: 0px;
    height: 44vh;
    padding: 60px;
}
.banner-section .slider-nav .img-fill .info h3{
     color: #fcc223;
    font-weight: 600;
    margin-bottom: 16px;
    font-size: 52px; 
}
.banner-section .slider-nav .img-fill .info h5{
    font-size: 21px;
    color: #fff;
    font-weight: 400;
    margin-bottom: 44px;
    margin-top: 20px;
}
.banner-section .slider-nav .img-fill .info .coupen-box{
    border: 1px dashed rgb(252 194 35 / 50%);
    color: #fff;
    font-size: 14px;
    padding: 14px 16px;
    display: inline-block;
    border-radius: 4px;
    position: relative;
}
.banner-section .slider-nav .img-fill .info .coupen-box:after{
content: "";
    height: 13px;
    width: 15px;
    background: url(../assets/coupen-cut.png) no-repeat;
    position: absolute;
    right: -7px;
    top: -7px;
}
.banner-section .slider-nav .img-fill .info .coupen-box span{
  color: #fcc223;
  text-transform: uppercase;
}
.banner-section .slide-btn .img-box:after{
  content:"";
  background: rgb(0 0 0 / 0%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

}
.banner-section .banner-tire{
  margin-top: 44px;
  position: relative;
}
.banner-section .banner-tire img{
  position: relative;
  z-index: 1;
}
.banner-section .banner-tire:after{
     content: "";
    background: url(../assets/banner-tire-mark.png) no-repeat;
    position: absolute;
    top: -115px;
    height: 450px;
    width: 400px;
    right: -142px;
    z-index: 0;
}

/*main heading*/
.main-heading {
  text-align: center;
  display: inline-block;
  margin-bottom: 20px;
}
.main-heading h6{
color: #fcc223;
text-transform: uppercase;
font-size: 11px;
margin-bottom: 10px;
}
.main-heading h2{
  color: #fff;
  font-size: 30px;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
      font-weight: 500;
      display: inline-block;
}
.main-heading h2:after{
height: 14px;
    content: "";
    width: 72px;
    background: rgb(252 194 35 / 11%);
    position: absolute;
    left: -8px;
    z-index: -1;
    bottom: 0;
}
.main-heading p{
  color: #c4c4c4;
  line-height: 22px;
  max-width: 600px;
}
.main-heading p strong{
  color: #eee;
}


/*simple steps*/

.simple-steps{
    padding: 40px 0 100px;
    background: url(../assets/section-2bg.png) #464646 no-repeat;
    position: relative;
    background-position: 0% 80%;
}
.simple-steps:after{
  height: 90px;
    content: "";
    width: 70px;
    background: url(../assets/decoration-1.png) no-repeat;
    position: absolute;
    top: 160px;
    opacity: 0.6;
    right: 0;
}
.simple-steps:before{
  height: 90px;
    content: "";
    width: 70px;
    background: url(../assets/decoration-2.png) no-repeat;
    position: absolute;
    bottom: 0;
    opacity: 0.6;
    left: 40px;
}
.simple-steps .steps-box h3{
color: #fff;
font-size: 14px;
margin: 30px 0 16px;
opacity: 0.9;
}
.simple-steps .steps-box p{
  color: #fff;
  opacity: 0.5;
  line-height: 24px;
}
.simple-steps .steps-box .icon{
  position: relative;
  z-index: 1;
}
.simple-steps .steps-box .icon:before{
height: 28px;
    content: "";
    width: 28px;
    background: rgb(252 194 35 / 3%);
    position: absolute;
    border-radius: 100%;
    left: 2px;
    z-index: -1;
    bottom: -4px;
}
.simple-steps .steps-box .icon:after{
height: 15px;
    content: "";
    width: 50px;
    background: url(../assets/arrow-right.png) no-repeat;
    position: absolute;
    border-radius: 100%;
    left: 30%;
    z-index: -1;
    bottom: 12px;
    opacity: 0.6;
}
.simple-steps .steps-box .icon .icon-number{
 float: right;
    font-size: 40px;
    color: #fff;
    opacity: .03;
    margin-right: 10px;
}
.simple-steps .tire-deco{
position: absolute;
    right: 20px;
    bottom: 20px;
    width: 160px;
}


/*search tires*/
.search-tires{
  margin-bottom: 100px;
  padding-left: 10px;
}

.search-tires h2 {
    font-size: 94px;
    display: inline-block;
    font-weight: 900;
    font-family: "Circular Std Black";
    color: #606060;
    letter-spacing: 2px;
    background: url(../assets/text-background.png) no-repeat;
    -webkit-background-clip: text;
    /* -webkit-text-fill-color: transparent; */
    background-position: 0% 14%;
    opacity: .9;
}
.search-tires .search-arrow{
margin: 0 0 15px 15px;
}
.simple-steps .search-tabs .by-vehicle a{
    height: 124px;
    background: #474747;
    width: 140px;
    text-align: center;
    padding: 30px 35px;
    display: inline-block;
    float: right;
    position: absolute;
    color: #fff;
    top: -164px;
    right: 260px;
    border-radius: 10px 10px 0 0;
    font-weight: 500;
    box-shadow: 0px -18px 20px rgb(0 0 0 / 12%);
}
  .simple-steps .search-tabs .by-vehicle a:before{
    content: "";
    position: absolute;

    height: 10px;
    width: 20px;

    bottom: 0;
  }
  .simple-steps .search-tabs .by-size a:before{
    content: "";
    position: absolute;

    height: 10px;
    width: 20px;

    top: 0;
  }

.simple-steps .search-tabs .by-vehicle a:before{
  left: -20px;
    border-radius: 0 0 10px 0;
    -moz-border-radius: 0 0 10px 0;
    -webkit-border-radius: 0 0 10px 0;
        -webkit-box-shadow: 10px 0 0 0 #474747;
    box-shadow: 10px 0 0 0 #474747;
}

  .simple-steps .search-tabs .by-size a:before {
right: -20px;
    border-radius: 10px 0 0 0;
    -moz-border-radius: 10px 0 0 0;
    -webkit-border-radius: 10px 0 0 0;
    -webkit-box-shadow: -10px 0 0 0 #474747;
    box-shadow: -10px 0 0 0 #fcc223;
  }
.simple-steps .search-tabs .by-size a{
    height: 124px;
    background: #fcc223;
    width: 140px;
    text-align: center;
    padding: 30px 30px;
    display: inline-block;
    float: right;
    position: absolute;
    color: #fff;
    top: -40px;
    right: 120px;
    border-radius: 0 0 10px 10px;
    font-weight: 500;
    box-shadow: 0px 20px 20px rgb(0 0 0 / 16%);
}
.simple-steps .search-tabs .by-vehicle a span{
display: block;
margin-top: 20px;
color: #fcc223;
}
.simple-steps .search-tabs .by-size a span{
display: block;
margin-top: 20px;
color: #000;
}

/*brands section*/
.brands-section{padding:100px 0 80px;background:#f6f8fc}
.brands-section .main-heading h2,.brands-section .main-heading p,.brands-section .main-heading p strong{color:#000}
.brands-section .main-heading p strong{font-weight:600}
.brands-section .main-heading h2:after{background:rgb(252 194 35 / 34%)}
.brands-section .brands a{height:100px;display:flex!important;align-items:center;justify-content:center;border-radius:10px;box-shadow:0 0 6px rgb(0 0 0 / 4%);padding-left:20px;padding-right:20px}
.brands-section .brands .all-brands{color:#000;font-weight:600;display:block!important;text-align:center;padding-top:35px!important}
.brands-section .brands .all-brands span{color:#000;font-weight:400;display:block;opacity:.44;margin-top:8px;font-size:12px}

/*blogs section*/

.blogs-section{
    padding: 150px 0 150px;
position: relative;
}
.blogs-section .main-heading h2, .blogs-section .main-heading p, .blogs-section .main-heading p strong{
color: #000;
}
.blogs-section .main-heading p strong{font-weight: 600}
.blogs-section .main-heading h2:after{
    background: rgb(252 194 35 / 34%);
}
.blogs-section .card{
background: transparent;
border:0;
}
.blogs-section .card .card-body{
background: transparent;
border:0;
padding: 20px 46px 20px 10px;
}
.blogs-section .card .card-body .date{
opacity: .8;
    font-size: 11px;
    text-transform: uppercase;
}
.blogs-section .card .card-body .date{
opacity: .8;
    font-size: 11px;
    text-transform: uppercase;
    margin: 10px 0 12px;
    display: block;
    font-weight: 500;
}
.blogs-section .card .card-body .card-title{
      font-size: 18px;
    color: #000;
    font-weight: 500;
    line-height: 24px;
}
.blogs-section .card .card-body .card-text{
  line-height: 22px;
  color: #000;
  opacity: .7;
  font-weight: 500;
}
.blogs-section .card img.blog-1{
border-radius: 0 0 40px 0
}
.blogs-section .card img.blog-2{
border-radius: 40px 0 0 0
}
.blogs-section .card img.blog-3{
border-radius: 0 0 0 40px
}
.blogs-section:before{
height: 200px;
    content: "";
    width: 100%;
    background: url(../assets/tire-effect.png) no-repeat;
    position: absolute;
    left: 0;
    z-index: -1;
    top: 400px;
    opacity: 1;
}
.blogs-section:after, .listing-section:after, .whoweare-wrap:after, .contact-form:after, .privacy-policy:after{
height: 166px;
    content: "";
    width: 100%;
    background: url(../assets/bottom-city.png) no-repeat;
    position: absolute;
    left: 0;
    z-index: -1;
    bottom: -2px;
    opacity: 1;
    background-size: contain;
}

/*testimonials section*/

.testimonials-section{
background: #fcc223;
/*background: url(../assets/section-2bg.png) #fcc223 no-repeat;*/
    padding: 160px 0 0;    
    width: 93.4%;
    position: relative;
    padding-left: 100px;
    z-index: 1;
}
.testimonials-section {
    content: "";
    background: url(../assets/testimonials-city.png) no-repeat #fcc223;
    opacity: 1;
    background-position: bottom;
}
.testimonials-section:after {
    content: "";
    width: 100%;
    background: url(../assets/testimonials-bg.png) no-repeat;
    position: absolute;
    left: 0;
    z-index: -1;
    top: 0;
    opacity: 1;
    background-position: 10%;
    height: 100%;
}
.testimonials-section .left-section h2{
    font-size: 50px;
    font-weight: 600;
    color: #000;
    line-height: 56px;
    position: relative;
    z-index: 1;
    margin-bottom: 60px;
}
.testimonials-section .left-section h2:after {
    height: 24px;
    content: "";
    width: 124px;
    background: rgb(255 255 255 / 18%);
    position: absolute;
    left: -10px;
    z-index: -1;
    bottom: 0;
}
.testimonials-section .left-section .review-heading h3{
font-size: 28px;
color: #000;
font-weight: 600;
margin: 30px 0 12px;
}
.testimonials-section .left-section .review-heading p{
  color: #2f2f2f;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}
.testimonials-section .left-section .review-icon{
  position: relative;
  z-index: 1;
  display: inline-block;
}
.testimonials-section .left-section .review-icon:after{
    height: 66px;
    content: "";
    width: 66px;
    background: rgb(255 255 255 / 10%);
    position: absolute;
    border-radius: 100%;
    right: -20px;
    z-index: -1;
    top: -5px;
}


.testimonials-section .long-arrow-right {
    bottom: -20px;
    display: inline-block;
    margin: 30px auto;
    width: 20px;
    height: 10px;
    border-left: 2px solid #000;
    -webkit-transform: rotate(120deg);
    transform: rotate(120deg);
    position: absolute;
    margin-left: 70px;
}
.testimonials-section .long-arrow-right:after {
    content: "";
    display: block;
    width: 1px;
    height: 70px;
    background-color: #000;
    -webkit-transform: rotate(-30deg) translate(16px, 4px);
    transform: rotate(-30deg) translate(16px, 4px);
    left: 0px;
    top: 0px;
}
.testimonials-section .testimonails-title{
position: absolute;
    width: 100%;
    font-size: 120px;
    font-weight: 800;
    pointer-events: none;
    overflow: hidden;
    transform: rotate(-90deg) scaleX(1);
    transform-origin: top left;
    bottom: 0;
    left: 170px;
    color: #000;
    opacity: 0.02
}
.testimonials-section .floating-images{
  position: relative;
}
.testimonials-section .floating-images .left-position img, .testimonials-section .floating-images .right-position img{
border-radius: 100%;
    opacity: .5;
  animation-name:floating;
  -webkit-animation-name:floating;
  animation-iteration-count:infinite;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function:linear;
  animation-timing-function:linear;
  -webkit-transform-origin:50% 1px;
  -moz-transform-origin:50% 1px;
  -ms-transform-origin:50% 1px;
  -o-transform-origin:50% 1px;
  transform-origin:50% 1px;
  -webkit-transition:all 2s ease;
  -moz-transition:all 2s ease;
  -ms-transition:all 2s ease;
  -o-transition:all 2s ease;
  transition:all 2s ease;
  pointer-events:none
}


.testimonials-section .floating-images .left-position .position-1 img, .testimonials-section .floating-images .left-position .position-3 img{
animation-duration:10s;-webkit-animation-duration:10s;-webkit-transition-delay:.6s;transition-delay:.6s
}
.testimonials-section .floating-images .left-position .position-2 img{
animation-duration:12s;-webkit-animation-duration:12s;-webkit-transition-delay:0s;transition-delay:0s
}
.testimonials-section .floating-images .left-position .position-4 img{
animation-duration:12s;-webkit-animation-duration:12s;-webkit-transition-delay:0s;transition-delay:0s
}
.testimonials-section .floating-images .right-position .position-1 img, .testimonials-section .floating-images .right-position .position-3 img{
animation-duration:16s;-webkit-animation-duration:16s;-webkit-transition-delay:.8s;transition-delay:.8s
}
.testimonials-section .floating-images .right-position .position-2 img, .testimonials-section .floating-images .right-position .position-4 img{
animation-duration:10s;-webkit-animation-duration:10s;-webkit-transition-delay:.10s;transition-delay:.10s
}
.testimonials-section .floating-images .right-position .position-5 img{
animation-duration:10s;-webkit-animation-duration:10s;-webkit-transition-delay:.6s;transition-delay:.6s
}
.testimonials-section .floating-images .left-position .position-1{
  position: absolute;
  left: -80px;
    top: -130px;
}
.testimonials-section .floating-images .left-position .position-2{
position: absolute;
    left: 50px;
    top: -80px;
}
.testimonials-section .floating-images .left-position .position-3{
  position: absolute;
  left: -40px;
    top: -50px;
}
.testimonials-section .floating-images .left-position .position-4{
position: absolute;
    left: 38px;
    top: -10px;
}
.testimonials-section .floating-images .right-position .position-1{
position: absolute;
    right: 100px;
    top: -50px;
}
.testimonials-section .floating-images .right-position .position-2{
position: absolute;
    right: -90px;
    top: -90px;
}
.testimonials-section .floating-images .right-position .position-3{
    position: absolute;
    right: -26px;
    top: 10px;
}
.testimonials-section .floating-images .right-position .position-4{
position: absolute;
    right: 60px;
    top: 50px;
}
.testimonials-section .floating-images .right-position .position-5{
position: absolute;
    right: -30px;
    top: 80px;
}
/*.testimonials-section .floating-images .left-position .position-4:after{
  content: "";
  height: 100%; 
  width:100%;
  background: #000;
  position: absolute;
  top:0;
  right:0;
  bottom: 0;
  left:0;
}*/

@keyframes floating{ 
  0% {
      -webkit-transform: rotate(0deg) translate(-10px) rotate(0deg);
      -moz-transform: rotate(0deg) translate(-10px) rotate(0deg);
      -ms-transform: rotate(0deg) translate(-10px) rotate(0deg);
      -o-transform: rotate(0deg) translate(-10px) rotate(0deg);
      transform: rotate(0deg) translate(-10px) rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg) translate(-10px) rotate(-360deg);
      -moz-transform: rotate(360deg) translate(-10px) rotate(-360deg);
      -ms-transform: rotate(360deg) translate(-10px) rotate(-360deg);
      -o-transform: rotate(360deg) translate(-10px) rotate(-360deg);
      transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }

}
@-webkit-keyframes floating{

  0% {
      -webkit-transform: rotate(0deg) translate(-10px) rotate(0deg);
      -moz-transform: rotate(0deg) translate(-10px) rotate(0deg);
      -ms-transform: rotate(0deg) translate(-10px) rotate(0deg);
      -o-transform: rotate(0deg) translate(-10px) rotate(0deg);
      transform: rotate(0deg) translate(-10px) rotate(0deg);
  }
  100% {
      -webkit-transform: rotate(360deg) translate(-10px) rotate(-360deg);
      -moz-transform: rotate(360deg) translate(-10px) rotate(-360deg);
      -ms-transform: rotate(360deg) translate(-10px) rotate(-360deg);
      -o-transform: rotate(360deg) translate(-10px) rotate(-360deg);
      transform: rotate(360deg) translate(-10px) rotate(-360deg);
  }

}

/*testimonials slider*/
.testimonials-section .testimonails-slider{
      padding: 20px 10px 40px 50px;
}

.testimonials-section .slider-for {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
}

.testimonials-section .slide-container {
  height: 20vh;
  background-color: transparent;
  text-align: center;
  line-height: 20vh;
  font-size: 40px;
  font-weight: bold;
  border: 0;
  margin-bottom: 20px;
  box-sizing: border-box;
}

.testimonials-section .decor {
  padding: 5px 40px;
  background-color: #fff;
  border: 2px solid #000;
}

.testimonials-section .slide-btn {
  box-sizing: border-box;
  cursor: pointer;
  display: flex !important;
  align-items: flex-end;
  justify-content: center;
  font-size: 30px;
  min-height: 120px;
  transition: 0.5s all;
  outline: 0;
}

.testimonials-section .slick-active-first .slide-btn {
  background-color: red;
}

.testimonials-section .slick-active-last .slide-btn {
  background-color: orange;
}

.testimonials-section .testimonails-dots-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: -50px;
    z-index: 1;
}

.testimonials-section .slider-dots {
  justify-content: center;
  align-items: center;
  min-height: 30px;
}

.testimonials-section .slider-dots button {
  width: 40px;
  height: 30px;
  border-radius: 50%;
  display: block;
  border: 0;
  background-color: transparent;
  margin: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  transition: .3s all;
  position: relative;
  z-index: 0;
}

.testimonials-section .testimonails-dots-box .slick-active button {
    width: 40px;
    height: 40px;
}

.testimonials-section .slider-dots button:after {
  content: "";
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #fff;
  opacity: 0.4
}

.testimonials-section .slider-dots .slick-active button:after {
  content: none;
  display: none;
}

.testimonials-section .testimonails-dots-box .slick-active button:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}

.testimonials-section .circle-bg {
  fill: rgba(255, 255, 255, 0);
  stroke: rgba(0, 0, 0, 0.05);
  stroke-width: 1;
  stroke-linecap: butt;
}
.testimonials-section .circle-go {
  fill: rgba(255, 255, 255, 0);
  stroke: rgb(255, 255, 255);
  stroke-width: 1;
  stroke-linecap: round;
  animation: progress 16s;
  stroke-dasharray: 300px;
  stroke-dashoffset: 300;
}
.testimonials-section .circle-tx {
  fill: #fff;
  stroke-width: 3;
  text-anchor: middle;
font-size: 1px;
font-weight: 800;
stroke: rgb(255, 255, 255);
}
.testimonials-section .circle-tx:after{
  content: "";
  height: 4px;
  width: 4px;
  background: #fff;
}

@keyframes progress {
  from {
    stroke-dashoffset: 360;
  }
  
  to {
    stroke-dashoffset: 0;
  }
}

.testimonials-section .testimonails-slider .testimonails-slider-body .testimonails-content {
    margin: 0 0 23px;
    height: 140px;
    position: relative;
    overflow: hidden;
}
.testimonials-section .testimonails-slider .testimonails-slider-body .testimonails-foot {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    left: 30px;
    top: 25px;
        justify-content: flex-end;
        text-align: right;
}
.testimonials-section .testimonails-slider .testimonails-slider-body .testimonails-foot .testimonails-foot{
  padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    left: 30px;
    top: 25px;
}
.testimonials-section .testimonails-slider .testimonails-slider-body .testimonails-foot .foot-img{
    padding: 0;
    margin: 0;
    min-width: 40px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
}
.testimonials-section .testimonails-slider .testimonails-slider-body .testimonails-foot .foot-title{
padding-left: 15px;
    font-size: 14px;
    font-weight: 600;
    color: #000;
    line-height: 1.4;
}
.testimonials-section .testimonails-slider .testimonails-slider-body .testimonails-foot .foot-title span {
    display: block;
    font-weight: 500;
    font-size: 76%;
    color: #000;
    margin-top: 1px;
    opacity: .8
}
.testimonials-section .testimonails-slider .testimonails-slider-body .testimonails-foot .foot-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.testimonials-section .testimonails-slider .testimonails-slider-body .testimonails-content p {
    margin: 0;
    line-height: 1.6;
    color: #2f2f2f;
    font-size: 14px;
}
.testimonials-section .testimonails-slider:before{
position: absolute;
    content: '';
    background: url(../assets/testimonials-quote.png) no-repeat;
    width: 47px;
    height: 42px;
    top: 2px;
    left: 35px;
    opacity: .06;
}
.testimonials-section .testimonails-slider:after{
    bottom: -223px;
    position: absolute;
    content: '';
    height: 590px;
    width: 580px;
    background: #fff;
    background: url(../assets/testi-foot.png) no-repeat;
    background-size: contain;
    left: -57px;
    z-index: -1;
}
.testimonials-dots-box{
position: absolute;
    right: -40px;
    top: 40px;
}
.testimonials-section:before{
content: "";
    background: url(../assets/banner-tire-mark.png) no-repeat;
    position: absolute;
    top: 365px;
    height: 450px;
    width: 400px;
    left: -51px;
    z-index: 0;
    opacity: 0.6;
}
.background-effects span{
  position: absolute;opacity: 0.18;
    animation-name:floating;
  -webkit-animation-name:floating;
  animation-iteration-count:infinite;
  -webkit-animation-iteration-count:infinite;
  -webkit-animation-timing-function:linear;
  animation-timing-function:linear;
  -webkit-transform-origin:50% 1px;
  -moz-transform-origin:50% 1px;
  -ms-transform-origin:50% 1px;
  -o-transform-origin:50% 1px;
  transform-origin:50% 1px;
  -webkit-transition:all 2s ease;
  -moz-transition:all 2s ease;
  -ms-transition:all 2s ease;
  -o-transition:all 2s ease;
  transition:all 2s ease;
  pointer-events:none;

  border-radius: 100%;
    animation-duration: 10s;
    -webkit-animation-duration: 10s;
    -webkit-transition-delay: .6s;
    transition-delay: .6s;
}
.background-effects .effect-1{
height: 40px;
    width: 40px;
    background: #fff;
    right: 180px;
    top: 20px;
}
.background-effects .effect-2{
height: 30px;
    width: 30px;
    background: #fff;
    right: 600px;
    top: 50px;
}
.background-effects .effect-3{
height: 20px;
    width: 20px;
    background: transparent;
    border:4px solid #fff;
    left: 470px;
    top: 120px;
}
.background-effects .effect-4{
height: 20px;
    width: 20px;
    background: transparent;
    border:4px solid #fff;
    left: 680px;
    top: 380px;
}
.background-effects .effect-5{
height: 20px;
    width: 20px;
    background: transparent;
    border:4px solid #fff;
    right: 120px;
    top: 400px;
}
.background-effects .effect-6{
    height: 20px;
    width: 20px;
    background: transparent;
    border: 4px solid #fff;
    left: 340px;
    top: 570px;
}
.simple-steps .background-effects span{
opacity: 0.03;
}
.simple-steps .background-effects .effect-1{
    height: 40px;
    width: 40px;
    background: #fff;
    right: 450px;
    top: 40px;
}
.background-effects .effect-3{
    height: 20px;
    width: 20px;
    background: transparent;
    border: 4px solid #fff;
    left: 400px;
    top: 160px;
}
.background-effects .effect-6{
    height: 20px;
    width: 20px;
    background: transparent;
    border: 4px solid #fff;
    left: 200px;
    bottom: 60px;
}


/*mobile app css*/
.mobile-app{
  margin-bottom: -100px
}
.mobile-app h3{
      font-size: 28px;
      color: #000;
      line-height: 36px;
      margin-bottom: 18px;
}
.mobile-app h3 strong{
  font-weight: 800
}
.mobile-app h4{
      font-size: 16px;
      color: #000;
      opacity: .8
}
.mobile-app .mobile-app-content{
  padding-top: 110px;
}
.mobile-app .mobile-app-content .app-btns{
  display: inline-block;
    padding: 12px 20px 10px;
    border-radius: 4px;   
    text-decoration: none;
    margin-top: 36px;
    margin-right: 20px;
    padding-left: 50px;
    position: relative;
}
.mobile-app .mobile-app-content .app-btns.google-btn img{
  position: absolute;
    left: 15px;
    top: 16px;
}
.mobile-app .mobile-app-content .app-btns.apple-btn img{
  position: absolute;
    left: 15px;
    top: 12px;
}
.mobile-app .mobile-app-content .app-btns.google-btn{
background-color: #000;
}
.mobile-app .mobile-app-content .app-btns.apple-btn{
background-color: transparent;
border:2px solid #000;
}
.mobile-app .mobile-app-content .apple-btn .app-btn-subtitle{
display: block;
    margin-bottom: 2px;
    color: #000;
    font-size: 11px;
}
.mobile-app .mobile-app-content .google-btn .app-btn-subtitle{
    display: block;
    margin-bottom: 2px;
    color: rgb(255 255 255 / 80%);
    font-size: 11px;
}
.mobile-app .mobile-app-content .apple-btn .app-btn-title {
    display: block;
    color: #000;
        font-size: 18px;
    font-weight: 500;
}
.page-rtl .mobile-app .mobile-app-content .apple-btn .app-btn-title{
  font-family: 'Segoe UI' !important
}
.page-rtl .mobile-app .mobile-app-content .apple-btn .app-btn-subtitle{
  font-family: 'Segoe UI' !important
}
.mobile-app .mobile-app-content .google-btn .app-btn-title {
    display: block;
    color: rgb(255 255 255 / 90%);
        font-size: 18px;
}
.tire-deco-2{
position: absolute;
    right: -80px;
    bottom: 40px;
}
/*.tire-deco-2:after{
content: "";
    background: url(../assets/banner-tire-mark.png) no-repeat;
    position: absolute;
    bottom: 0;
    height: 450px;
    width: 400px;
    right: 0px;
    z-index: 0;
    opacity: 0.6;
}
*/


/*footer css*/

footer{
  background: #474747;
  padding: 100px 0 10px;
  position: relative;
}
footer .widget-light .widget-list-link{
  color: rgb(255 255 255 / 0.55);
  line-height: 24px;
}
footer .widget-list-link{
  font-size: 13px;
}
footer .widget-title{
  font-size: 14px;
}
footer .newsletter p{
color: rgb(255 255 255 / 0.55);
    line-height: 24px;
    margin-bottom: 18px;
}
footer .newsletter .input-group-append .btn{
padding: 8px 14px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    background: #fcc223;
    position: absolute;
    color: #fff;
    right: 2px;
    top: 4px;
    border-radius: 4px;
    z-index: 9;
}
footer .newsletter .input-group input{
height: 40px;
    font-size: 12px;
}
footer .input-group>.form-control:not(:last-child), footer .input-group>.custom-select:not(:last-child) {
    border-radius: 4px;
}
footer .social a{
height: 34px;
    width: 34px;
    background: #5b5b5b;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.3;
    margin-right: 15px;
}
footer .social a:hover{
   opacity: 1
}
footer:before {
    height: 90px;
    content: "";
    width: 70px;
    background: url(../assets/decoration-2.png) no-repeat;
    position: absolute;
    bottom: 0;
    opacity: 0.6;
    left: 40px;
}
footer:after {
    height: 90px;
    content: "";
    width: 70px;
    background: url(../assets/decoration-1.png) no-repeat;
    position: absolute;
    top: 30px;
    opacity: 0.6;
    right: 0;
}
/*.btn-scroll-top {
    display: flex;
    position: fixed;
    right: -4.125rem;
    bottom: 1.25rem;
    width: 2.75rem;
    align-items: center;
    justify-content: center;
    height: 2.75rem;
    transition: right 400ms cubic-bezier(0.68, -0.55, 0.265, 1.55),opacity 0.3s,background-color 0.25s ease-in-out;
    border-radius: 50%;
    background-color: rgb(252 194 35);
    color: #000;
    text-align: center;
    opacity: 0;
    font-weight: 500;
    z-index: 1025;
    font-size: 11px;
  }
  .btn-scroll-top:hover{
    background-color:#000;
    color: #fff;
  }*/


/*location modal*/

.modal.right .modal-dialog,
.modal.top .modal-dialog{
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

/* Left & Right */

.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  width: 430px;
  max-width: 100%;
  height: 100%;
}

.modal.right .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.right .modal-body {
  padding: 90px 26px 80px;
}

.modal.right.fade .modal-dialog {
  right: -500px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.show .modal-dialog {
  right: 0;
}

.modal.right .modal-content,
.modal.top .modal-content,
.modal.full .modal-content {
  border-radius: 0;
  border: none;
}

.modal.right .modal-dialog.modal-sm {
  width: 300px;
}

.modal.right .modal-dialog.modal-lg {
  width: 800px;
}

.modal.right .modal-dialog.modal-xl {
  width: 1140px;
}

/* Top and Bottom */

.modal.top .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.modal.top .modal-content {
  height: auto;
  overflow-y: auto;
}

.modal.top .modal-body {
  padding: 15px 15px;
}

/* Top */

.modal.top.fade .modal-dialog {
  top: -100%;
  -webkit-transition: opacity 0.3s linear, top 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, top 0.3s ease-out;
  -o-transition: opacity 0.3s linear, top 0.3s ease-out;
  transition: opacity 0.3s linear, top 0.3s ease-out;
}

.modal.top.fade.show .modal-dialog {
  top: 0;
}

/* Full Screen */

.modal.full .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 100%;
  height: 100%;
}

.modal.full .modal-content {
  height: 100%;
  overflow-y: auto;
}

.modal.full .close-modal {
  position: fixed;
  top: 0;
  right: 3rem;
}

/* Footer */

.modal-footer-fixed {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-radius: 0;
}

/* XS */

.modal.left.xs .modal-body{
  padding: 15px;
}



/*Listing page*/

.inner-page-banner{
padding: 100px 196px 10px;
    position: relative;
    height: 225px;
        box-shadow: 0 10px 30px rgb(0 0 0 / 20%);
        overflow: hidden;
}
.inner-page-banner:before{
    content: "";
    background: url(../assets/inner-left-city.png) no-repeat;
    position: absolute;
    height: 130px;
    width: 220px;
    bottom: 0;
    left: 0;
}
.inner-page-banner:after{
    content: "";
    background: url(../assets/inner-right-city.png) no-repeat;
    position: absolute;
    height: 130px;
    bottom: 0;
    right: 0;
    width: 386px;
}
.inner-page-banner .tire-mark{
position: absolute;
    top: 20px;
    right: -160px;
    left: 0;
    bottom: 0;
    text-align: center;
}
.inner-page-banner .list-heading ul li{
  display: inline-block;
  margin-right: 60px;
}

.inner-page-banner .list-heading ul li h1{
color: #000;
font-weight: 500;
font-size: 25px;
}
.inner-page-banner .list-heading ul li h1 span{
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 16px;
}
.inner-page-banner .list-heading{
  padding: 0 0;
  position: relative;
  z-index: 1
}

/*listing section css*/
.listing-section .listing-description{
  color: #000;
  margin-bottom: 30px;
  line-height: 20px;
}
.listing-section .listing-description span{
font-weight: 600;
}
.listing-section{
  padding: 40px 0 260px;
  position: relative;
}

.listing-block {
    position: relative;
    margin-bottom: 24px;
}
.listing-block .inner-box {
    position: relative;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    display: -webkit-box;
    display: flex;
        box-shadow: 0 0 14px rgb(0 0 0 / 4%);
    padding: 15px 15px 25px;
}
.listing-block .image-box {
    position: relative;
}
.listing-block .image {
    display: block;
    overflow: hidden;
    margin-bottom: 0;
    width: 220px;
    border-radius: 6px;
}
.listing-block .image img {
    display: block;
    width: 100%;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    border-radius: 6px;
}
.listing-block .content-box {
    position: relative;
    width: 100%;
    padding: 0 10px 0 20px;
}
.listing-block .content-box .upper-box {
    position: relative;
    padding: 20px 40px 10px;
    z-index: 2;
    width: 100%;
    margin-bottom: 10px;
}
.listing-block .user-thumb {
position: absolute;
    left: -40px;
    top: 24px;
    width: 72px;
    padding: 15px 8px;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
        min-height: 44px;
        display: flex;
    align-items: center;
    box-shadow: 0 0px 20px rgb(0 0 0 / 7%);
}
.listing-block .user-thumb img {
    display: block;
    width: 100%;
}
.listing-block h3 {
position: relative;
    font-size: 20px;
    line-height: 1.2em;
    color: #2b3041;
    font-weight: 600;
}
.listing-block h3 a span{
opacity: 0.8;
padding-left: 12px;
font-size: 22px;
}
.listing-block h3 a {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: #1b2032;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}
.listing-block .listing-info {
    position: relative;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    padding: 18px 20px;
    border-top: 1px solid #eceef2;
    border-bottom: 1px solid #eceef2;
}
.listing-block .listing-info li {
    position: relative;
    color: #000000;
    padding-right: 34px;
    font-weight: 600;
    margin-right: 34px;
    border-right: 1px solid #eceef2;
    font-size: 12px;
}
.listing-block .listing-info li:last-child{
  margin-right: 0;
  padding-right: 0;
  border-right: 0;
}

.listing-block .listing-bottom-box {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    padding: 18px 18px 18px 40px;
    background: #fcc223;
    margin-top: 20px;
    border-radius: 6px;
}
.listing-block .inner-box:hover .image img {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.listing-block .listing-info li span{
  display: block;
  color: #8e8e8e;
  font-weight: 500;
  font-size: 11px;
  margin-bottom: 9px;
}
.listing-block .listing-info li small{
font-size: 80%;
}
.listing-block .listing-heading{
padding: 6px 10px 0;
}
.listing-block .listing-heading .listing-year{
    font-weight: 600;
    margin-bottom: 8px;
    display: inline-block;
    font-size: 12px;
}
.listing-bottom-box .listing-rates{
position: relative;
    display: -webkit-box;
    display: flex;
    width: 100%
}
.listing-bottom-box .listing-rates .left-area{
color: #000;
    position: relative;
    display: -webkit-box;
    display: flex;
    align-items: center;
    width: 75%;
    white-space: nowrap;
}
.listing-bottom-box .listing-rates .left-area .total-price {
 /*text-transform: uppercase;*/
    display: inline-block;
    font-weight: 600;
    font-size: 12px;
    margin-right: 16px;
}
.listing-bottom-box .listing-rates .left-area span{
    font-size: 27px;
    font-weight: 600;
}
.listing-bottom-box .listing-rates .left-area span strong{
font-size: 70%;
}
.listing-bottom-box .listing-rates .left-area span small{
  font-size: 40%
}
.listing-bottom-box .listing-rates .right-area{
  width: 40%;
    text-align: right;
}
.listing-bottom-box .listing-rates .right-area .btn{
font-size: 12px;
width: 190px;
box-shadow: 0 0px 10px rgb(0 0 0 / 6%);
padding: 12px 20px;
}
.listing-bottom-box .listing-rates .right-area .btn-primary{
background: #322707;
border-color: #322707;
}
.listing-bottom-box .listing-rates .right-area .btn-default{
background: #fff;
border-color: #fff;
margin-left: 10px;
color: #fcc223;
}
.listing-bottom-box .listing-rates .right-area .btn-default img{
  padding-left: 4px;
}
.runflat{
  position: absolute;
  /* right: 0px; */
  text-transform: uppercase;
  color: #62b707;
  font-weight: 500;
  margin-left: 10px;
}

/*loader css*/
.loader--glisteningWindow {
  width: 0.25em;
  height: 0.25em;
  box-shadow: 0.70711em 0.70711em 0 0em #dadcdf, -0.70711em 0.70711em 0 0.17678em #e1e3e6, -0.70711em -0.70711em 0 0.25em #e9ebee, 0.70711em -0.70711em 0 0.17678em #f0f2f5;
  animation: gw 1s ease-in-out infinite, rot 2.8s linear infinite;
  border-radius: 50%;
  margin: 80px auto 0;
}
.loader--glisteningWindow + p{
text-align: center;
    margin-top: 35px;
    font-size: 11px;
    color: #000;
    opacity: .3;
}

@keyframes rot {
  to {
    transform: rotate(360deg);
  }
}
@keyframes gw {
  0% {
    box-shadow: 0.70711em 0.70711em 0 0.125em #dadcdf, -0.70711em 0.70711em 0 0.200em #e1e3e6, -0.70711em -0.70711em 0 0.5em #e9ebee, 0.70711em -0.70711em 0 0.200em #f0f2f5;
  }
  25% {
    box-shadow: 0.70711em 0.70711em 0 0.200em #dadcdf, -0.70711em 0.70711em 0 0.5em #e1e3e6, -0.70711em -0.70711em 0 0.200em #e9ebee, 0.70711em -0.70711em 0 0.125em #f0f2f5;
  }
  50% {
    box-shadow: 0.70711em 0.70711em 0 0.5em #dadcdf, -0.70711em 0.70711em 0 0.200em #e1e3e6, -0.70711em -0.70711em 0 0.125em #e9ebee, 0.70711em -0.70711em 0 0.200em #f0f2f5;
  }
  75% {
    box-shadow: 0.70711em 0.70711em 0 0.200em #dadcdf, -0.70711em 0.70711em 0 0.125em #e1e3e6, -0.70711em -0.70711em 0 0.200em #e9ebee, 0.70711em -0.70711em 0 0.5em #f0f2f5;
  }
  100% {
    box-shadow: 0.70711em 0.70711em 0 0.125em #dadcdf, -0.70711em 0.70711em 0 0.200em #e1e3e6, -0.70711em -0.70711em 0 0.5em #e9ebee, 0.70711em -0.70711em 0 0.200em #f0f2f5;
  }
}



/*about us page CSS*/
.page-rtl .brands-estb{
  direction: ltr;
}
.page-rtl .tire_decription_details ul li{
  width: 50%;
  font-size: 13px;
  padding: 10px 0;
  direction: ltr;
  text-align: right;
  padding-right: 33px;
}
.page-rtl .modal_contentwrap ul.trire_front_rear li{
  padding-right: 33px;

}
.page-rtl .fuelbox:nth-child(odd){
  padding-left: 33px;
  
}
.page-rtl .modal-header .close span {
    font-size: 25px;
    font-weight: bold;
}
.page-rtl span.tire_details.discountPrice span:after {
    content: "";
    content: "";
    /* background: red; */
    width: 1px;
    height: 48px;
    /* display: block; */
    position: absolute;
    transform: rotate(115deg);
    top: -17px;
    right: 10px;
}
.page-rtl ul.price_listing span.tire_details {
    font-size: 11px !important;
    position: relative;
    direction: rtl;
    white-space: nowrap;
}
.page-rtl  ul.price_listing span.tire_details span{font-size: 13px !important;}
.page-rtl .cart_inner .u-brand-col-left{
  float: right;
} .page-rtl .cart_inner .u-brand-col-right{
  text-align: right;
}
.inner-page-banner.center-text-banner .list-heading h3{
font-size: 40px;
font-weight: 600;
color: #000;
margin-bottom: 8px;
}
.inner-page-banner.center-text-banner .list-heading p{
  color: #000;
  font-weight: 500;
  font-size: 12px;
}
.inner-page-banner.center-text-banner{
  padding-top: 130px;
}

.about-section-1{
padding: 130px 0;
    overflow: hidden;
}
.about-section-1 .main-heading h2, .about-section-1 .main-heading p, .about-section-1 .main-heading p strong{
color: #000;
}
.about-section-1 .main-heading p strong{
  font-weight: 600;
}
.about-section-1 .main-heading h2:after {
    background: rgb(252 194 35 / 34%);
}
.border-radius-6 {
    border-radius: 6px;
}
.about-section-1 .about-inner h3{
font-size: 20px;
font-weight: 500;
line-height: 28px;
width: 90%;
}

.about-section-1 .about-inner strong {
      margin-bottom: 5px;
      line-height: 24px;
      font-weight: 500;
      font-size: 16px;
      color: #000;
      display: inline-block;
}
.about-section-1 .about-inner p{
  margin-bottom: 5px;
      line-height: 24px;
      font-size: 14px;
      color: #6f6f6f;
}


.whoweare-wrap{padding:120px 0 180px;margin:0;position: relative;}
.whowecontent{padding:0;margin:0}
.whowecontent .titletire{font-size:42px;font-weight:bold;line-height:1.3;margin-bottom:30px;width:86%;color: #000;}
.whowecontent .titletire span{font-size:12px;font-weight:300;display: block;margin-bottom: 14px;color: #fcc223;text-transform: uppercase;}
.whowecontent p{font-weight:500;color:#2f2f2f;line-height:2;margin:0 0 55px}
.wholistwrap{padding:40px 0 0;margin:0}
.wholistwrap ul{padding:0;margin:0}
.wholistwrap ul>li{padding:0;margin:0;list-style:none}
.wholistwrap ul>li+li{margin-top:58px}
.wholistwrap ul>li>.whodatalst{padding:0;margin:0;position:relative;display:flex}
.wholistwrap ul>li>.whodatalst .checkico{min-width:31px;width:31px;display:inline-flex;justify-content:center;align-items:flex-start}
.wholistwrap ul>li>.whodatalst .checkico img{max-width:100%}
.wholistwrap ul>li>.whodatalst .rightdatawho{padding:0 0 0 20px;margin:0}
.wholistwrap ul>li>.whodatalst .rightdatawho h2{font-size:16px;font-weight:bold;margin:0 0 10px;color: #000}
.wholistwrap ul>li>.whodatalst .rightdatawho p{font-size:14px;font-weight:500;line-height:1.99}

.btn-warning{
      color: #000000!important;
    background-color: #fcc223;
    border-color: #fcc223;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    padding: 14px 24px;
    border-radius: 4px;
    box-shadow: 0 0 40px rgb(0 0 0 / 6%);
}
.btn-warning:hover{
   color: #000!important;
    background-color: #fbbe18;
    border-color: #fbbe18;
}
.btn-warning:focus, .btn-warning.focus{
  background-color: #fcc223;
    border-color: #fcc223;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show>.btn-warning.dropdown-toggle {
    color: #2b3445;
    background-color: #ffbd07;
    border-color: #fcc223;
}

.about-section-1:before {
    height: 200px;
    content: "";
    width: 100%;
    background: url(../assets/tire-effect.png) no-repeat;
    position: absolute;
    left: 0;
    z-index: -1;
    top: 680px;
    opacity: 1;
}
.about-section-1 .tire-deco{
  position: absolute;
    right: -74px;
    bottom: 22px;
    width: 160px;
}
.simple-steps.about-steps{padding: 110px 0 100px;}
.simple-steps.about-steps:after{top: 12px;}



/*Contact us css*/

.contact-section-1{
padding: 130px 0 30px;
    overflow: hidden;
}
.contact-section-1 .main-heading h2, .contact-section-1 .main-heading p, .contact-section-1 .main-heading p strong{
color: #000;
}
.contact-section-1 .main-heading p strong{
  font-weight: 600;
}
.contact-section-1 .main-heading h2:after {
    background: rgb(252 194 35 / 34%);
}

.contact-section-1 .contact-details{
  padding: 0 40px;
}
.contact-section-1 .contact-details ul li span.contact-icon{
    padding: 12px 12px 12px 12px;
    background-color: #FFFFFF;
        box-shadow: 6px 5px 16px 0px rgba(0, 0, 0, 0.15);
            border-radius: 50%;
            display: flex;
                text-align: center;
                    margin: 1px 30px 0px 2px;
                    height: 54px;
    width: 54px;
    align-items: center;
    justify-content: center;
}
.contact-section-1 .contact-details ul{
  display: flex;
  margin-top: 20px;
}
.contact-section-1 .contact-details ul li{
  display: flex;
  align-items: center;
  margin-right: 80px
}
.contact-section-1 .contact-details ul li:last-child{
  margin-right: 0;
}
.contact-section-1 .contact-details ul li .details{
  text-align: left;
}
.contact-section-1 .contact-details ul li strong{
      font-weight: 800;
    color: #000;
    margin-bottom: 0px;
    font-size: 14px;
    margin-bottom: 10px;
    display: block;
}

.contact-form{
  padding: 0 0 220px;
  position: relative;
}
.contact-form .form-box{
  background-color: #1e1e1f;
      padding: 50px 60px 60px 60px;
          border-radius: 10px 10px 10px 10px;
}
.contact-form .form-box .form-title{
font-size: 36px;
    color: #fff;
    line-height: 56px;
        font-weight: 500;
        margin-bottom: 5px;
        opacity: 0.8;
}
.contact-form .form-box .form-control{
  background-color: #2f2f30;
  color: #fff;
  border-color: #1e1e1f;
  font-size: 13px;
  height: 50px;
}
.contact-form .form-box textarea.form-control{
height: auto;
padding-top: 20px;
}
.contact-form .form-box .btn{
color: #fff!important;
font-weight: 500;
font-size: 13px;
}

.contact-form .form-box p{
color: #fff;
margin-bottom: 30px;
opacity: 0.9;
line-height: 20px;
}


 .contact-form .form-control:-ms-input-placeholder { /* Edge */
  color: #999a9c;
}
.contact-form .form-control::-webkit-input-placeholder{
  color: #999a9c;
}
.contact-form .form-control::-moz-placeholder{
    color: #999a9c;
}

.envelope-deco img{
position: absolute;
}
.envelope-deco img.deco-1{
right:80px;
top:400px;
}
.envelope-deco img.deco-3{
left: 120px;
    bottom: 230px;
}
.envelope-deco img.deco-2{
    left: 50px;
    top: 230px;
}


/*privacy policy css*/

 .privacy-policy{
    padding: 60px 0 140px;
    position: relative;
  }
.privacy-policy .privacy-content h4{
font-size: 18px;
font-weight: 600;
margin-bottom: 20px;
line-height: 24px;
}
.privacy-policy .privacy-content strong{
  font-weight: 600;
}
.privacy-policy .privacy-content p{
  margin-bottom: 30px;
  line-height: 20px
}

/*Coming Soon page*/
.coming-soon{
  background: #fcc223;
      z-index: 1;
    position: relative;
}
.coming-soon:before {
    content: "";
    background: url(../assets/left-city.png) no-repeat;
    position: absolute;
    height: 130px;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: -1;
}
.coming-soon:after {
    content: "";
    background: url(../assets/inner-right-city.png) no-repeat;
    position: absolute;
    height: 130px;
    bottom: 0;
    right: 0;
    width: 100%;
    transform: rotateY(180deg);
    z-index: -1;
}
.coming-soon-content{
  display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-top: -10px;
}
.coming-soon-content .coming-soon-heading{
  text-align: left;
}
.coming-soon-content .coming-soon-heading h2{
  font-size: 74px;
  color: #000;
  font-weight: 800;
  margin-bottom: 24px;
}
.coming-soon-content .coming-soon-heading h4{
    font-size: 14px;
    line-height: 20px;
    color: #000;
    margin-bottom: 10px;
}
.coming-soon-content .coming-soon-heading h6{
font-size: 16px;
color: #000;
    margin-bottom: 12px;
}
.coming-soon-content .coming-soon-heading p{
color: #000;
font-size: 12px;line-height: 20px;
margin-bottom: 20px;
}
.coming-soon-content .mobile-app .mobile-app-content {
    padding-top: 0;
}
.coming-soon-content .mobile-app{
margin-bottom: 0;
}
.coming-soon-content .mobile-app h3{
  font-size: 24px;
      margin-bottom: 8px;
}
.coming-soon .mobile-app .mobile-app-content .app-btns{
      margin-top: 30px;
}
.coming-soon .mobile-app .mobile-app-content .app-btns{
      padding: 14px 20px 10px;
  padding-left: 50px;
}
.coming-soon .mob-app{margin-left: -55px;}
.coming-soon-heading img{
  margin-bottom: 20px;
}

.coming-soon .tire-mark {
position: absolute;
    top: 70px;
    right: -350px;
    left: 0;
    bottom: 0;
    text-align: center;
    z-index: -1;
}
.coming-soon .tire-deco {
    position: absolute;
    right: 264px;
    bottom: -22px;
    width: 110px;
}
.coming-soon-heading h6{
text-transform: uppercase;
}
.coming-soon .navbar-nav .nav-link {
    color: #000;
    align-items: center;
    font-size: 12px;
    display: flex;
    text-transform: uppercase;
    margin-top: 5px;
}
.coming-soon .middle-border{
height: 40px;
width: 1px;
background: #eee;
opacity: 0.5;
margin: 0 auto;
}
.coming-soon .navbar-nav .nav-link img {
    margin-right: 6px;
    margin-top: -4px;
}
.coming-soon .navbar-nav .nav-link img.drop-arrow {
    margin-left: 8px;
    margin-top: 0;
}

/*Tire details popup*/

.tire-details .modal-header .modal-title{
    font-weight: 600;
    text-transform: uppercase;
}
.tire-details .modal-header .close{
  font-weight: 400;
  font-size: 21px;
}
.tire-details .modal-header{
      padding: 20px 16px 17px 22px;
    box-shadow: 0 0 4px rgb(0 0 0 / 10%);
    border-radius: 0;
    position: fixed;
    width: 100%;
    z-index: 99;
    background: #fff;
}
.tire-details .upper-details .inner-box{
  position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: flex;
    padding: 0;
}
.tire-details .upper-details .inner-box .image-box {
    position: relative;
}
.tire-details .upper-details .inner-box .image-box .image {
    display: block;
    overflow: hidden;
    margin-bottom: 0;
    width: 126px;
    border-radius: 10px;
}
.tire-details .upper-details .inner-box .image-box .image img {
    display: block;
    width: 100%;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    border-radius: 6px;
}
.tire-details .upper-details .inner-box .content-box {
    position: relative;
    width: 100%;
    padding: 0 15px 0 25px;
    margin: auto;
}
.tire-details .upper-details .inner-box .content-box .upper-box {
    position: relative;
    z-index: 2;
    width: 100%;
    margin-bottom: 18px;
}
.tire-details .upper-details .inner-box .content-box .upper-box .listing-heading {
    padding: 6px 10px 0;
}
.tire-details .upper-details .inner-box .content-box .upper-box .listing-heading .listing-year {
    font-weight: normal;
    margin-bottom: 8px;
    display: inline-block;
    font-size: 13px;
}
.tire-details .upper-details .inner-box .content-box .upper-box h3 {
    position: relative;
    font-size: 18px;
    line-height: 1.2em;
    color: #2b3041;
    font-weight: 500;
}
.tire-details .upper-details .inner-box .content-box .upper-box h3 a {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: #1b2032;
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}
.tire-details .upper-details .inner-box .content-box .upper-box h3 a span {
color: #494d5b;
    padding-left: 12px;
    font-size: 14px;
    font-weight: normal;
}

.tire_description {
    border: 1px solid #f0f0f0;
    margin: 20px 0;
    padding: 0px 20px;
    border-radius: 15px;
}
.modal_contentwrap {
    padding: 30px 0;
    position: relative;
}

.modal_contentwrap ul.trire_front_rear {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: -6px;
    width: 100%;
}
.modal_contentwrap ul.trire_front_rear li {
    background: #fff;
    /* margin: 0 4px;
    padding: 0px 5px; */
    font-size: 11px;
    text-transform: uppercase;
    padding-left: 10px;
    
}
.modal_contentwrap ul.trire_front_rear li span {
    font-weight: 500;
    color: #000;
    margin-top: 9px;
    display: block;
    font-size: 18px;
}

.tire_decription_details ul li {
   display: inline-block;
    width: 50%;
    font-size: 11px;
    padding: 10px;
    color: #8e8e8e;
}
.tire_decription_details ul li span.tire_details {
    display: block;
    color: #8e8e8e;
    font-weight: 500;
    font-size: 11px;
    margin-top: 9px;
    margin-bottom: 9px;
}

.tire_decription_details ul li span.tire_details span {
    padding-right: 5px;
    font-size: 18px;
    color: #000;
}


.gauge_listdetail {
    text-align: center;
    margin: -25px;
}
.gauge_listdetail p {
    margin: 8px 0px;
    text-transform: uppercase;
}
.gauge_listdetail span.tire_details span {
    text-transform: uppercase;
    font-size: 14px !important;
    padding: 0 !important
}
ul.price_listing{
  
    margin: 20px 0;
    padding-top: 20px;
    border-top: 2px dashed#ececec;
}
ul.price_listing span.tire_details span {
    padding: 0 !important;
    color: #676767 !important;
    font-size: 16px !important;
    text-align: center;
    font-weight: 600 !important;
}
ul.price_listing span.tire_details {
    font-size: 8px !important;
}

div#right_modal_sm .modal-footer.modal-footer-fixed {
    border: none;
}
div#right_modal_sm .modal-footer.modal-footer-fixed button#choose_locationbtn {
    width: 85%;
    margin: auto;
    color: #000 !important;
    background: #f1f3fa;
    border-color: #f1f3fa;
}
div#right_modal_sm a.runflat {
    font-size: 11px;
    top: 0;
    right: 0;
}

.page-rtl div#right_modal_sm a.runflat {
  right: auto !important;
}

div#right_modal_sm .listing-heading {
    padding: 0;
}
div#right_modal_sm ul.listing-info li {
    margin-top: 9px;
    font-weight: 500;
}
 ul.listing-info li span.color_text {
    font-size: 13px ;
    color: #f4bb1b !important;
    font-weight: 800 ;
}

div#right_modal_sm ul.listing-info li span {
    /* display: block; */
    color: #8e8e8e;
    font-weight: 500;
    font-size: 11px;
    /* margin-top: 9px; */
    margin-bottom: 9px;
}
div#right_modal_sm {
    overflow: hidden !important;
}

  @media (max-width: 1500px){
.blogs-section:after, .listing-section:after, .whoweare-wrap:after, .contact-form:after, .privacy-policy:after {
    height: 151px;
  }
  .simple-steps .tire-deco {
    right: 10px;
  }
  .testimonials-section .testimonails-title{
    left: 110px;
  }
  /*section.tire_infowrap .upper-box.tireinfo_istingwrap .listing-heading.tireinfo_isting-heading p.address {
    font-size: 14px !important;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap {
    padding-right: 10px !important;
}

section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing span.full_time {
    font-size: 12px !important;
}

section.tire_infowrap .upper-box.tireinfo_istingwrap .listing-heading.tireinfo_isting-heading h3 {
    font-size: 18px !important;
}
section.tire_infowrap{
  padding-left: 60px !important
}
section.tire_infowrap .listing-block .listing-info li {
    padding-right: 10px;
    margin-right: 10px;
}
.order_summray_modalheading h2{
  font-size: 18px !important;
}
.price_summarylisting ul li {
    font-size: 15px !important;
}
.free_delivry_link {
    text-align: center;
}
.free_delivry_link a {
    font-size: 9px !important;
    text-align: center;
}*/
  
/*  .testimonials-section:after {
    width: 100%;
    left: 140px;
    bottom: 0;
}*/
}
@media (min-width: 1530px) {
  .page-rtl .fuelbox:nth-child(odd) {
    padding-left: 68px;
}
.page-rtl .tire_decription_details li:nth-child(even) {
  padding-left: 44px;
}
 .modal.right .modal-dialog{
    width:545px ;
 }
 .search-tires h2{
  font-size: 66px
}
 .tire-details .upper-details .inner-box .image-box .image{
  width: 159px;
 }
 .modal_contentwrap ul.trire_front_rear{
   width: 87%;
 }
 .modal_contentwrap ul.trire_front_rear li{
  font-size: 16px;
 }
 div#right_modal_sm ul.listing-info li span{
  font-size: 15px;
 }
 div#right_modal_sm ul.listing-info li{
  font-size: 17px;
 }
 div#right_modal_sm ul.listing-info li span.color_text{
  font-size: 17px;
 }
 .tire-details .upper-details .inner-box .content-box .upper-box h3{
  font-size: 18px;
 }
 .tire-details .upper-details .inner-box .content-box .upper-box h3 a span{
  font-size: 15px;
 }
 .tire_description{
  margin: 40px 0;
 }
 .tire_decription_details ul li{
  font-size: 15px;
  padding: 10px;
 }
.tire_decription_details ul li span.tire_details span{
  font-size: 18px !important;
}
.tire_decription_details ul li span.tire_details{
  font-size: 13px !important;
}


}



@media (max-width: 767.98px){
  .coming-soon{
    height: auto;
  }
  #Proceed .modal-body.quantity input[type="number"]::-webkit-inner-spin-button {
    opacity: 1;
    -webkit-appearance: "Always Show Up/Down Arrows";
    appearance: auto;
  }
  section.simple-steps .container.px-5 {
    padding-left: 2rem !important;
}
  .tire-details .modal-header {
    padding: 25px 15px 25px 15px;
    box-shadow: 0 0 4px rgb(0 0 0 / 10%);
    border-radius: 0;
    overflow-x: hidden;
}
  .navbar-floating{
        position: fixed;
    background: #fcc223;
  }
  .coming-soon .tire-mark{
    right: auto;
  }
  .coming-soon-content .mobile-app .mobile-app-content{
    padding-bottom: 20px;
  }
  .coming-soon-content{
    padding: 50px 0;
    display: block;
  }
  .coming-soon-heading{
    margin-top: 50px;
  }
  .coming-soon .tire-deco{
    right: 54px;
  }
  .contact-section-1 .contact-details ul li .details{
    text-align: center;
    margin-bottom: 30px;
  }
  .about-section-1{
   padding: 80px 0; 
  }
  .privacy-policy{
    padding: 80px 0;
  }
  .about-section-1 .tire-deco {
    position: absolute;
    right: -24px;
    bottom: -24px;
    width: 120px;
  }
  .contact-section-1{
    padding: 80px 0 30px;
  }
  .envelope-deco{
display: none;
  }
  .contact-form .form-box{
        padding: 40px 40px 50px 40px;
  }
  .contact-section-1 .contact-details ul li{
    display: block;
    margin-right: 0;
    width: 100%;
  }
  .contact-section-1 .contact-details ul li span.contact-icon{
    margin: 0 auto 20px;
  }
  .contact-section-1 .contact-details ul{
    display: block;
  }
  .inner-page-banner{
    padding: 140px 0px 10px;
  }
  .inner-page-banner .list-heading ul li{
        margin-right: 40px;
  }
  .inner-page-banner{
    height: unset;
  }
  .listing-block .inner-box{
    flex-direction: column;
  }
  .listing-bottom-box .listing-rates .right-area .btn-default{
    margin-left: 0;
    margin-top: 10px;
  }
  .listing-bottom-box .listing-rates, .listing-bottom-box .listing-rates .left-area{display: block;width: 100%}
  .mobile-app .mobile-app-content .google-btn .app-btn-subtitle, .mobile-app .mobile-app-content .apple-btn .app-btn-subtitle{
  font-size: 9px;
}
.listing-bottom-box .listing-rates .right-area {
    width: 100%;
    text-align: center;
}
.listing-block .image{
  width: 100%;
}
.listing-bottom-box .listing-rates .left-area{
  margin-bottom: 20px;
}
.listing-block .user-thumb{
  position: absolute;
    left:auto;
    top: -30px;
    right: 0;
    display: none;
}
.listing-block .content-box .upper-box{
     padding: 10px 0px 10px;
    margin: 0;
}
.runflat {
    position: absolute;
    left: 60px;
    top: 26px;
    right: auto;
  }
.listing-block .listing-info li{
  width: 50%;
    margin-bottom: 20px;
    border:0;
    padding-right:0 !important;
    margin-right: 0 !important;
}
.testimonials-section .long-arrow-right:after{
  transform: rotate(-30deg) translate(9px, 3px);
      height: 40px;
}
.testimonials-section .long-arrow-right{
  margin-left: 34px;
}
.banner-section {
    padding: 120px 0px 100px;
}
.testimonials-section:after{
  display: none;
}
.banner-section .slider-nav .img-fill .info h5 {
    font-size: 13px;
  }
  .banner-section .slider-nav .img-fill .info h3{
    font-size: 40px;
  }
  .simple-steps .search-tabs .by-vehicle a{
    top: -163px;
    right: 150px;
  }
   .simple-steps .search-tabs .by-size a{
        top: -41px;
    right: 10px;
  }
  .search-tires{
    padding-right: 130px;
    padding-left: 0;
  }
  .search-tires .search-arrow{
    position: absolute;
    top: -20px;
    left: 40px;
  }
  .search-tires h2{
    font-size: 28px;
    letter-spacing: 1px;
    margin-top: 40px;
    background-position: 1% 14%;
  }
  .simple-steps .steps-box p{
    margin-bottom: 40px;
  }
  .blogs-section:after, .listing-section:after, .whoweare-wrap:after, .contact-form:after, .privacy-policy:after {
    height: 240px;
    background-size: cover;
}
.blogs-section .card .card-body{
  margin-bottom: 30px;
}
.testimonials-section {
    width: 100%;
        padding-left: 10px;
    padding-right: 10px;
}
.testimonials-section .left-section h2{
  font-size: 35px;
    line-height: 48px;
}
.testimonials-section:before{display: none;}
.testimonials-section .left-section .review-heading p{
  margin-bottom: 10px;
}
.testimonials-section .testimonails-slider:after{
display: none;
}
.testimonials-section .testimonails-slider .testimonails-slider-body .testimonails-content p{
  font-size: 12px;
}
.testimonials-section .testimonails-slider {
padding: 50px 30px 40px 40px;
    background: #fff;
    border-radius: 0 0px 0px 0;
    left: -25px;
}
.testimonials-section .testimonails-slider .testimonails-slider-body .testimonails-content{
  height: auto;
  width: 100%;
}
.testimonials-section .testimonails-slider .testimonails-slider-body .testimonails-foot .foot-img{
      min-width: 34px;
    width: 34px;
    height: 34px;
}
.testimonials-section .testimonails-slider .testimonails-slider-body .testimonails-foot .foot-title{
  font-size: 12px;
}
.testimonials-section .testimonails-slider .testimonails-slider-body .testimonails-foot{
top: 0px;
    position: relative;
    left: 0px;
}
.testimonials-section .testimonails-slider:before{
left: 22px;
    top: 30px;
}
.mobile-app .img{
  margin-bottom: -100px;
}
.testimonials-section .testimonails-title{
top: 580px;
    white-space: nowrap;
    font-size: 40px;
    bottom: auto;
    left: auto;
    right: -270px;
}
.mobile-app{
  margin-bottom: 0;
}
.testimonials-section:after {
background-position: 0;
}
.testimonials-dots-box{
      right: 0px;
}
.mobile-app .mobile-app-content {
    padding-top: 30px;
    padding-bottom: 50px;
}
.brands-section{
      padding: 80px 0 60px;
}
.main-heading h2{
  font-size: 22px;
}
.mobile-app .mobile-app-content .google-btn .app-btn-title, .mobile-app .mobile-app-content .apple-btn .app-btn-title{
  font-size: 12px;
}
.mobile-app .mobile-app-content .app-btns{
  padding: 12px 12px 10px;
      padding-left: 38px;
          margin-top: 30px;
          margin-right: 10px;
}
.mobile-app .mobile-app-content .app-btns.google-btn img{
    top: 14px;
    left: 12px;
    width: 18px;
}
.mobile-app .mobile-app-content .app-btns.apple-btn img{
      left: 10px;
    width: 18px;
    top: 10px;
}
.mobile-app h4{
  font-size: 14px;
}
.mobile-app h3{
  font-size: 22px;
  margin-bottom: 14px;
    line-height: 30px;
}
.tire-deco-2{
  position: absolute;
    right: 10px;
    bottom: 10px;
    width: 140px;
}
.mobile-app .mobile-app-content{
      padding-bottom: 140px;
}
.mobile-app img.mob-app{
  margin-left: -75px;
  width: 100%;
}
.page-rtl .logo-caption {
    padding-right: 10px;
    padding-left: 0
}
.col-lg-12.mobile-app.mt-lg-5 .col-lg-4{
  text-align: center;
}
.col-lg-12.mobile-app.mt-lg-5 .row {
    flex-wrap: wrap !important;
}
.blogs-section{
  padding: 80px 0 140px;
}
.banner-section .banner-tire:after{
      right: 0px;
}
.testimonials-section .floating-images .right-position{
display: none;
}
.testimonials-section .floating-images .left-position .position-2{
      left: 130px;
}
.testimonials-section .floating-images .left-position .position-1{
      left: 10px;
}
.testimonials-section .floating-images .left-position .position-3{
  left: 50px;
}
.testimonials-section .floating-images .left-position .position-4{
      left: 108px;
}
.banner-section .banner-tire img{
  padding: 40px 40px 0
}
.navbar-nav .dropdown-menu{
  position: absolute;
  min-width: auto;
}
.navbar-dark .dropdown-menu, .navbar-dark .dropdown-menu .dropdown-menu{
  background-color: #fcc223;
}
.dropdown-item{
  color:#000!important;
}
.background-effects{
  display: none;
}
 .banner-section:after{
    width: 36px;
  }
.tire-details .upper-details .inner-box .content-box .upper-box h3{
  font-size: 13px;
}
.tire-details .upper-details .inner-box .content-box .upper-box h3 a span{
  font-size: 9px;
}
div#right_modal_sm ul.listing-info li {
    font-size: 11px;
}
div#right_modal_sm ul.listing-info li span.color_text{
  font-size: 11px !important;
  line-height: 16px;
}
/* .modal_contentwrap ul.trire_front_rear li{
  line-height: 12px;
  text-align: center;
  display: flex;
  margin: 0;
} */
.modal_contentwrap ul.trire_front_rear li span{
  display: block;
  width: 100%;
  

}

.page-rtl .modal_contentwrap ul.trire_front_rear li span{
  font-size: 12px;
}



.tire_decription_details ul li span.tire_details span{
  font-size: 11px !important;
}
.tire_decription_details ul li{
  padding: 8px;
}
.tire_decription_details li:nth-child(even) {
    padding-left: 34px;
}
.tire_decription_details ul li span.tire_details{
  font-size: 9px;
}
.listing-block h3{
  font-size: 16px;
}
.listing-block h3 a span{
  font-size: 14px;
}
.listing-block .listing-heading .listing-year{padding-left: 10px}
.listing-block .listing-info {
    padding: 15px 0;
}
.listing-block .listing-bottom-box{
  padding-left: 18px;
}
/*section.tire_infowrap {
    width: 100% !important ;
    padding: 0 !important;
}
section.order_summraypopup {
    display: block;
    width: 100% !important;
    margin: 0 !important;
}
.order_summrywrap {
    display: block !important;
}*/
.order_summrywrap {
    display: block !important;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px
}
section.tire_infowrap {
    padding: 0 !important;
    width: 100% !important;
}
section.order_summraypopup {
    width: 100% !important;
    margin: 0 !important;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap{
  display: block !important;
}

}
@media (max-width: 320.98px){
  .tire_decription_details li:nth-child(even) {
    padding-left: 43px !important;
}
.search-tires{
padding-left: 0;
padding-right: 0;
margin-top: 80px;margin-bottom: 80px;
}
.search-tires h2 {
    background-position: 70% 10%;
}
.search-tires .search-arrow {
    position: absolute;
    top: 0px;
    left: -10px;
  }
  .logo-caption{
    font-size: 10px;
    margin-right: 5px;
    padding-left: 15px;
  }
  .banner-section{
    padding-top: 90px;
  }
  .navbar-brand img{
    width: 38px;
  }
  .banner-section .slider-nav .img-fill .info{
        padding: 40px 30px;
  }
  .banner-section .slider-nav .img-fill .info h5{
    margin-bottom: 30px;
  }
  .banner-section .slider-nav .img-fill .info .coupen-box{
    font-size: 12px;
  }
  .banner-section:after{
    width: 36px;
  }
  .banner-section:before{
    display: none;
  }


}


/*location page*/

header.location_page_header .inner-box .image-box {
    padding: 0px 15px;
}
header.location_page_header .inner-box .image-box figure.image {
    width: 160px;
}
header.location_page_header .inner-box {
    align-items: center;
}
header.location_page_header .inner-box .content-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
header.location_page_header .inner-box .content-box .upper-box {
    width: unset;
    display: block;
    padding-left: 50px;
}
header.location_page_header .inner-box .upper-box ul.listing-info li span{
    display: unset;
}

header.location_page_header .inner-box ul.listing-info li {
    font-size: 18px;
    line-height: 25px;
}
header.location_page_header .inner-box ul.listing-info li span {
    font-weight: 500;
    font-size: 18px;
}
header.location_page_header .inner-box .upper-box ul.listing-info {
    border: none;
    flex: unset;
    display: block;
}
header.location_page_header .inner-box .content-box .upper-box a.runflat {
    font-size: 18px;
    right: 0;
}
header.location_page_header .inner-box .listing-bottom-box .listing-rates .left-area {
    display: unset;
    text-align: center;
    margin: auto;
    padding: 20px 0;
    width: 60%;
    line-height: 30px;
}

header.location_page_header {
    overflow: hidden;
    width: 100%;
    position: fixed;
    z-index: 111;
    border-bottom: 1px solid#ececec;
}
header.location_page_header {
    overflow: hidden;
    /* position: fixed; */
    border-bottom: 1px solid#ececec;
}
header.location_page_header .listing-block {
    margin: 0;
    height: 160px;
}


.map {
    width: 100%;
    position: relative;
    z-index: -1;
}
.dilivery_content {
    background: #fff;
    padding: 15px 0;
    color: #000;
    font-size: 18px;
    font-weight: 500;
}
section.location_leftmenu {
    overflow-x: hidden;
    background: #fff;
}
section.loction_mapwrap {
      text-align: center;
}
.location_page_wrap{
  display: flex;
  padding-top: 155px;
}
.dilivery_content span {
    color: #000;
    font-weight: 600;
}
.row.location_leftmenu {
    background: #fff;
}

.timeing_details .inner_box {
    position: relative;
    overflow: hidden;
    display: -webkit-box;
    display: flex;
      padding: 15px 25px;
}
.timeing_details .inner_box .img-box {
    position: relative;
}
.timeing_details .inner_box .img-box figure.image {
    display: block;
    overflow: hidden;
    margin-bottom: 0;
    width: 120px;
    border-radius: 10px;
}
.timeing_details .content-box {
    position: relative;
    width: 100%;
    padding: 0 15px 0 25px;
    margin: auto;
    text-align: left;
}
.timeing_details .content-box .upper-box .listing-heading p {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    line-height: 20px;
}
.timeing_details .content-box .upper-box .listing-heading p.address {
    display: block;
    font-size: 15px;
    margin-top: 15px;
    font-weight: 500;
    width: 100%;
    line-height: 20px;
    color: #a9a9aa;
    width: 90%;
}
.timeing_details .working_timeing {
    padding: 15px 25px;
    position: relative;
    overflow: hidden;
    text-align: left;
}
.working_timeing p {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 500;
    width: 100%;
    line-height: 20px;
    color: #a9a9aa;
    /*text-align: right;*/
    /* width: 90%; */
}
.working_timeing span {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    width: 100%;
    display: block;
    line-height: 20px;
    color: #000;
    /* width: 90%; */
}
.reviewselect_btn {
    padding: 15px 25px;
    position: relative;
    overflow: hidden;
    text-align: center;
}
.reviewselect_btn button#review_btn {
        background: transparent;
    color: #fcc223 !important;
    border-color: #fcc223;
    width: 100%;
    padding: 20px 0;
    font-size: 20px;
    text-transform: capitalize;
    
}
.timeing_details {
    border-bottom: 2px solid#f2f2f2;
}



/*order_summry*/

.page_title h2 {
    font-size: 23px;
    vertical-align: middle;
    margin: 0;
    padding: 2px 20px;
    color: #000;
    font-weight: 500;
}
header.order_detail_page_header .listing-block .inner-box {
    box-shadow: none;
      padding: 0;
}
header.order_detail_page_header .listing-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    background-color: #ffffff;
    border-radius: 8px;
    overflow: hidden;
    display: -webkit-box;
    display: flex;
    box-shadow: 0 0 14px rgb(0 0 0 / 4%);
    background: #fff;
    padding: 30px 25px;
}

.order_tire_listing ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.order_tire_listing ul li span {
    display: block;
    color: #8e8e8e;
    font-size: 13px;
    font-weight: 400;
    text-transform: unset !important;
    line-height: 25px;
}
.order_tire_listing ul li {
    padding-right: 20px;
    padding-left: 20px;
    font-size: 16px;
    font-weight: 500;
    border-right: 1px solid #eceef2;
    text-align: right;
    text-transform: capitalize;
}
.order_tire_listing ul li:last-child{
  border: none;
}
.tireinfo_title h2 {
    font-size: 17px;
    color: #000;
    font-weight: 500;
}
.tireinfo_title{
  padding: 15px 0;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap {
    padding-left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
section.tire_infowrap .upper-box.tireinfo_istingwrap .listing-heading.tireinfo_isting-heading h3 {
    font-size: 23px;
    color: #2d3243;
    margin-bottom: 10px;
    font-weight: bold;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .listing-heading.tireinfo_isting-heading p.address {
    font-size: 16px;
    font-weight: normal;
    color: #808080;
    width: 70%;
    line-height: 20px;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .listing-heading.tireinfo_isting-heading h3 {
    margin-bottom: 10px;
    font-size: 23px;
    font-weight: bold;
    color: #2d3243;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing {
    padding: 6px 30px 0;
    border-left: 1px solid#eceef2;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing p {
    font-size: 13px !important;
    color: #808080 !important;
    font-weight: 500;
    margin-bottom: 5px;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing span.full_time {
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    color: #000;
}

.order_summrywrap {
    display: flex;
    justify-content: space-around;
    
}
header.order_detail_page_header .listing-block {
    margin: 0;
    border-bottom: 1px solid #eceff2;
}
section.order_summraypopup {
    height: initial;
    background: #fff;
    max-height: 100%;
    padding: 20px;
    width: 30%;
    margin-left: 40px;
    z-index: 999;
}
section.tire_infowrap {
    padding-left: 150px;
    width: 70%;
    padding-top: 50px;
}
.order_summrywrap .listing-block .listing-info li{
  margin-right: 7px;
  padding-right: 7px
}
.price_mainwrap {
    padding: 20px;
}
.order_summray_modalheading h2 {
    font-size: 23px;
    font-weight: 500;
    color: #000000;
    padding-bottom: 25px;
}
.order_summray_modalheading h2 span {
    font-weight: 500;
    font-size: 16px;
    padding-left: 15px;
    color: #ffbe2c;
}
.price_summarylisting ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    font-size: 18px;
    color: #808080;
}
.price_summarylisting ul li span {
    color: #373737;
    font-weight: 500;
}
.prce_summarylisting_btn button {
    width: 100%;
    background: #fcc223;
    color: #ffff;
    border-color: #fcc223;
    border-radius: 10px;
    height: 50px;
    font-size: 20px;
    width: 100%;
    border: 1px solid;
    cursor: pointer;
    text-transform: capitalize;
    box-shadow: none;
    white-space: nowrap;
}
.prce_summarylisting_btn {
    padding: 20px 0;
}
.free_delivry_link a {
    color: #808080;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: normal;
}
.price_summarylisting ul li span.free {
    color: #fff;
    background: #8acd36;
    padding: 10px;
    font-size: 18px;
    border-radius: 8px;
}
section.tire_infowrap .image-box.img_first figure.image {
    width: 150px;
    margin: auto 0;
}
section.tire_infowrap .image-box.img_first {
    margin: auto;
}

.page-rtl .navbar ul.sub_drop_menu{
  left: 30px;
    top: 100%;
    right: auto;
}
.page-rtl .navbar .user_icon .sub_drop_menu::before{
  margin-right: -9px;
  margin-left: 0
}
.page-rtl .navbar .user_icon .sub_drop_menu::after, .navbar .user_icon .sub_drop_menu::before{
 left: unset;
  right: 80%; 
}

@media (max-width: 1500.98px){
  .location_page_wrap{
  display: flex;
  padding-top: 155px;
}
  header.location_page_header .inner-box .image-box figure.image {
    width: 130px;
  }
  header.location_page_header .inner-box ul.listing-info li{
    font-size: 11px;
  }
  header.location_page_header .inner-box ul.listing-info li span {
    font-weight: 500;
    font-size: 11px;
  }
  header.location_page_header .inner-box .content-box .upper-box a.runflat{
    font-size: unset;
    right: -30px;
  }
  header.location_page_header .inner-box .upper-box ul.listing-info li {
    font-size: 11px;
    line-height: 25px;
    border: none;
    padding-right: 10px;
  }
  header.location_page_header .inner-box ul.listing-info li{
      padding-right: 5px;


  }
  header.location_page_header .inner-box .upper-box ul.listing-info{
    padding: 10px;
  }
  .listing-bottom-box .listing-rates .left-area span{
    font-size: 15px;
  }
  .listing-block .listing-bottom-box{
    padding: 10px;
    margin:0;
  }
  .dilivery_content{
    font-size: 12px;
  }
  .timeing_details .inner_box .img-box figure.image {
    width: 100px;
  }
  .timeing_details .inner_box{
    padding: 15px 10px;
  }
  .timeing_details .content-box .upper-box .listing-heading p{
    font-size: 13px;
    
  }
  .timeing_details .content-box{
    padding: 0px 15px 0px 15px;
  }
  .timeing_details .content-box .upper-box .listing-heading p.address{
    width: 100%;
    font-size: 11px
  }
  .timeing_details .working_timeing{
    padding: 0px 15px;
  }
  .working_timeing p{
    font-size: 11px;
  }
  .working_timeing span{
    font-size: 11px;
  }
  .reviewselect_btn{
    padding: 15px 15px;
  }
  .reviewselect_btn button#review_btn{
    font-size: 12px;
    padding: 10px 0;
  }
  section.tire_infowrap{
    padding-left: 50px;
  }
  section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing span.full_time{
    font-size: 13px;
  }
  
   .price_summarylisting ul li{
    font-size: 14px;
   }
   .free_delivry_link {
    text-align: center;
  }
  .free_delivry_link a{
    font-size: 11px;
  }

}



/*location modal*/

.countrymodal.openit {
    top: 0;
}
.countrymodal {
    position: fixed;
    top: -680px;
    left: 0;
    right: 0;
    background: #fff;
    z-index: 99999999;
    padding: 64px 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}
.countrymodal .btn.close {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0;
    width: 99px;
    height: 52px;
    padding: 0px;
    background: #f3f3f3;
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.66);
    font-weight: normal;
    outline: none;
}
.countrymodal .btn.close span {
    font-size: 22px;
    vertical-align: middle;
}
.countrymodal .popup-content .content-body {
    padding: 0px;
    margin: 0px;
}
.countrymodal .popup-content .content-body .outertitle {
    padding: 0 0 0px;
    margin: 0 0 35px;
    text-align: center;
    position: relative;
}
.countrymodal .popup-content .content-body .outertitle .innertitle {
    width: auto;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    margin: auto;
    padding: 35px 0 0px;
    position: relative;
}
.countrymodal .popup-content .content-body .outertitle .innertitle h3 {
    font-size:30px;
    font-weight: 500;
    color: #050505;
    line-height: 1.5;
}

.countrymodal .popup-content .content-body .outertitle .innertitle h3:after {
    height: 14px;
    content: "";
    width: 72px;
    background: rgb(252 194 35 / 11%);
    position: absolute;
    left: 24%;
    z-index: -1;
    top: 40%;
}
.countrymodal .popup-content .content-body .outertitle .innertitle h3:after {
    background: rgb(252 194 35 / 34%);
}
.countrymodal .popup-content .content-body .outertitle .innertitle h3 span {
    font-size: 18px;
    color: #000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-weight: normal;
    display: block;
    width: 75%;
    margin: auto;
    margin-top: 5px;
}
.countrymodal .popup-content .content-body .outertitle .innertitle h3 span strong{
  font-weight: 600;
}
.countrymodal .trandinglist {
    padding: 0px;
    margin: 0px;
    text-align: center;
}
.countrymodal .trandinglist h3 {
    font-size: 20px;
    color: #050505;
    font-weight: 500;
    margin: 0 0 30px;
}
.countrymodal .trandinglist ul {
    padding: 0px;
    margin: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.countrymodal .trandinglist ul li {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding: 0 6px;
    list-style: none;
}

.countrymodal .trandinglist ul li a {
    min-height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 20px;
    outline: none;
    text-decoration: none;
    border: solid 1px #dedede;
    border-radius: 5px;
    background-clip: padding-box;
    font-size: 12px;
    color: #616161;
    letter-spacing: 0.1px;
    background: #fff;
}
.animate {
    -webkit-animation: ripple .65s linear;
    animation: ripple 0.65s linear;
}
.ink {
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 100%;
    background-clip: padding-box;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.countrymodal .trandinglist ul li.active a {
    border-color: #474747;
    background: #474747;
    color: #fff;
}
.form-group.locationgroup {
    display: flex;
    position: relative;
    justify-content: center;
}
.form-group.locationgroup a.nav-link.pl-0 {
    vertical-align: middle;
    position: absolute;
    left: 34%;
    font-size: 15px;
    font-weight: 500;
    color: #000;
    top: 8px;
}
.form-group.locationgroup a.nav-link.pl-0 img {
    padding-left: 5px;
    vertical-align: middle;
    padding-right: 10px;
}
.form-group.locationgroup input::placeholder {
    color: #aaaaaa;
    font-size: 15px;
    font-weight: 500;
    
}
.form-group.locationgroup p {
    width: 30%;
    /* margin: auto; */
    font-size: 15px;
    font-weight: 500;
    vertical-align: middle;
    color: #aaaaaa;
    padding: 25px 20px;
    text-transform: uppercase;
    border: 1px solid;
    box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.06);
    border-radius: 5px 0 0 5px;
}

.form-group.locationgroup input.form-control {
   position: relative;
    height: 70px;
    width: unset;
    border-radius: 0;
    width: 44%;
    border-color: #aaaaaa;
    padding-left: 30px;
    box-shadow: 0px 5px 11px rgba(0, 0, 0, 0.06);
    border-radius: 0px 5px 5px 0px;
}
.content-header {
    text-align: center;
}
@media (max-width: 1499px)
{
.countrymodal .trandinglist ul li a {
    min-height: 30px;
    padding: 5px 15px;
}
}





@media (min-width:768px) and (max-width:1280px){

section.tire_infowrap {
    padding: 0 15px;
    width: 100%;
}
.order_summrywrap{
  display: block;
}
section.order_summraypopup {
    width: 75%;
    margin: auto;
}
section.tire_infowrap .listing-block .listing-info li{
  font-size: 8px
}
section.tire_infowrap .listing-block .content-box{
  padding: 0 10px 0 15px;
}
section.tire_infowrap .listing-block .listing-info{
  padding: 18px 0
}
header.location_page_header .inner-box{
  align-items: unset;
}
.location_page_wrap{
  padding-top: 360px;
}
header.location_page_header .inner-box .content-box{
  display: block;
}
header.location_page_header .inner-box .listing-bottom-box .listing-rates .left-area {
    display: unset;
    text-align: center;
    margin: auto;
    padding: 0 0;
    width: 100%;
    margin-top: 0px;
    line-height: 30px;
}
header.location_page_header .inner-box .content-box .upper-box a.runflat{
  right: 0
}
section.location_leftmenu{
  width: 50%;
}
section.loction_mapwrap{
  width: 100%;
}
.form-group.locationgroup p{
  width: 42%;
}
.form-group.locationgroup a.nav-link.pl-0{
  left: 35%;
  top: 10px
}
}
@media (max-width: 767.98px){
 section.tire_infowrap .upper-box.tireinfo_istingwrap .listing-heading.tireinfo_isting-heading h3 {
    font-size: 16px;
}
section.order_summraypopup{
  padding: 0
} 
.order_summray_modalheading h2 {
    font-size: 18px;
    text-align: center;
}
.order_summray_modalheading h2 span {
    font-size: 12px;
}
.price_summarylisting ul li {
    font-size: 11px;
}
.price_summarylisting ul li span.free {
    border-radius: unset;
    font-size: 11px;
    padding: 5px;
}


.navbar-floating .navbar-collapse .navbar-nav {
    padding: 0 !important;
    vertical-align: bottom;
    position: relative;
    top: 10px;
}
.prce_summarylisting_btn button {
    font-size: 15px;
    height: 40px;
}
.free_delivry_link {
    text-align: center;
}
.free_delivry_link a {
    font-size: 8px;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .listing-heading.tireinfo_isting-heading p.address {
    font-size: 11px;
    width: 100%;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing {
    border: none;
    padding: 10px 10px;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing p {
    font-size: 11px !important;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing span.full_time {
    font-size: 12px;
}
header.order_detail_page_header {
    width: 100%;
    overflow-x: hidden;
}
p.clickhere{
  font-size: 13px !important
}
header.order_detail_page_header .listing-block {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}
header.order_detail_page_header .listing-block .inner-box {
    display: flex;
    justify-content: start;
    flex-direction: unset;
}
.order_tire_listing ul li {
    padding: 0 10px;
    font-size: 11px;
    line-height: 15px;
}
.order_tire_listing ul li span{
  font-size: 10px;
  line-height: 20px;
}
.order_tire_listing ul{
  padding: 20px 0px;
}
.location_page_wrap{
  display: block;
  padding: 0;
}
header.location_page_header .inner-box .content-box{
  display: unset;
}
header.location_page_header .inner-box .content-box .upper-box{
  padding: 0
}
header.location_page_header .inner-box ul.listing-info li{
  margin: 0
}
header.location_page_header .inner-box .upper-box ul.listing-info li{
  margin: 0;
  width: 100%;
}
header.location_page_header .inner-box .content-box .upper-box a.runflat{
  text-align: right;
  right: 0 !important
}
header.location_page_header .inner-box .image-box figure.image{
  width: 100% !important
}
header.location_page_header{
  position: unset;
}
header.location_page_header .inner-box{
  align-items: unset;
  border-radius: 0;
  padding: 10px
}
header.location_page_header .navbar-left {
    margin-bottom: 0px;
}
section.loction_mapwrap{
  display: block;
  overflow-x: hidden;
  margin-top: 25px;
}
.countrymodal.openit {
    height: 100%;
    overflow-y: auto;
}
.form-group.locationgroup{
  display: block;
}
.form-group.locationgroup p {
    width: 100%;
    margin: 10px 0;
}
.form-group.locationgroup a.nav-link.pl-0{
  left: unset;
  right: 0
}
.form-group.locationgroup input.form-control {
    width: 100%;
}
.countrymodal .trandinglist ul{
  display: block;
}
.countrymodal .trandinglist ul li{
  padding: 6px
}

.timeing_details{
  border: 2px solid transparent;
}

.timeing_details.active {
  border-color: #fcc223;

}
}


/*login page*/


.admin_banner-section{
    background: url('../assets/admin_login_background.jpg');
    background-size:cover;
    
    padding:90px 0;
    background-repeat: no-repeat;
}

header.admin_login_header .container-fluid {
    justify-content: center;
    padding: 20px;
}
.login_form_modal {
    background: url('../assets/login_formbg.jpg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 30%;
    margin: 20px auto;
    background-color: #fff;
    border-radius: 10px;
    position: relative;
     padding: 30px; 
}
.login_form_modal .main-heading {
    margin:0;
}
.login_form_modal .main-heading h2 {
    color: #000;
}
.login_pagecontent p {
    font-size: 17px;
    line-height: 25px;
    color: #616161;
    font-weight: 300;
}
.user_login {
    margin: 25px 0;
}
.user_login ul li {
    background: #fff;
    width: 150px;
    height: 150px;
    padding: 30px 0;
    margin: auto 0;
    vertical-align: middle;
    text-align: center;
    border: 1px solid#ececec;
    border-radius: 10px;
    opacity: .5;
    margin-right: 25px;
}
.user_login ul li span {
    font-weight: 500;
    display: block;
    text-align: center;
    font-size: 18px;
    padding: 10px 0 0;
    color: #010001;
}
.user_login ul {
    display: flex;
    align-items: center;
}
.user_login ul li.active {
    opacity: 1;
    border-color: #ffbe00;
    position: relative;
}
.select_option {
    position: absolute;
    right: -8px;
    top: -8px;
    background: #ffbe00;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 2px 28px 0 rgb(0 0 0 / 24%);
}
.login_form .form-control label {
    position: absolute;
    top: -12px;
    background: #fff;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 16px;
}
.login_btnwrp button.btn.btn-default.login_btn {
    width: 100%;
    text-transform: uppercase;
    background: #ffbe2c;
    /* padding: 15px 0; */
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.login_btnwrp {
    text-align: center;
}
.login_form_modal .banner-tire {
    width: 100%;
    position: absolute;
    right: -15px;
    padding: 15px 0;
    text-align: end;
}
.login_form_modal .banner-tire img {
    width: 20%;
}
.login_form .form-control {
    position: relative;
    height: 60px;
    margin: 15px 0;
}
.login_form .form-control input::placeholder {
    font-size: 16px;
    font-weight: 200;
}
.login_form .form-control input {
    width: 100%;
    border: none;
    height: 45px;
}
.login_form .form-control input:focus {
    outline: none;
}
a.forgot_passwod {
    position: absolute;
    right: 20px;
    top: 16px;
    font-size: 14px;
    color: #ffbe2c;
    font-weight: 500;
}
header.admin_login_header ul.navbar-nav {
    border-left: 1px solid #facd53;
    padding: 0px 25px;
}
.user_login ul li span.login_type {
    font-weight: 500;
    opacity: .5;
}
.user_login ul li span.user_type {
    font-size: 20px;
}
.main_loginpage {
    display: flex;
}
.main_loginpage .testimonials-section {
    width: 70% !important;
    padding: 220px 0 0;
    padding-left: 100px;
}
.main_loginsec .login_form_modal {
    margin: 0;
    width: auto;
    border-radius: unset;
    padding: 110px 50px;
}
.main_loginsec {
    width: 30%;
}
header.login_page_header .container-fluid {
    justify-content: start;
}

.main_loginpage .testimonials-section:before
{
    display: none;
}
.main_loginpage .testimonials-section .tire-deco-2 {
    right: -190px;
}
.main_loginpage .testimonials-section .tire-deco-2 img{
    width: 40%;
}



@media (max-width: 767.98px){
    .partitioned{
      font-size: 25px !important;
      width: 16% !important;
    }
  .singup-modal .modal-header{
    padding: 20px 20px !important;
  }
    .main_loginpage{
        display: unset;
    }
    .main_loginpage .testimonials-section{
        width: 100% !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .main_loginpage .testimonials-section .tire-deco-2 {
    right: 10px;
    }
    .main_loginpage .testimonials-section .tire-deco-2 img {
     width: unset; 
        }
        .main_loginsec .login_form_modal {
    padding: 30px;
    }
    .main_loginsec{
        width:100%
    }
    header.login_page_header {
    padding: 0 15px 0;
    }
    header.login_page_header .d-none{
        display: block !important;
    }
    .login_form_modal{
        width: 100%;
    }
    header.admin_login_header .container-fluid{
        padding: unset;
    }
    header.admin_login_header .d-none{
        display: block !important;
    }
}

@media (max-width: 1366px) and (min-width: 768px){
         .login_form_modal{
        width: 60%;
    }
    .main_loginpage .testimonials-section .testimonails-slider:after{
        width: 500px;
    }   
    .main_loginpage .testimonials-section{
        padding-left: 0;
    }
    .main_loginpage .testimonials-section .container.px-lg-5 .col-lg-6.mb-5.pr-lg-5.pl-lg-5 {
    padding-left: 0 !important;
    }
    .main_loginpage .testimonials-section .left-section h2{
        font-size: 32px;
    }
    .partitioned{
      font-size: 25px !important;
      width: 18% !important
    }

}

@media (max-width: 1180px) and (min-width: 768px){
   .main_loginpage .testimonials-section .testimonails-slider {
    padding: 20px 10px 40px 50px;
    left: -50px;
    padding: 50px 30px 40px 40px;
    background: #fff;
    border-radius: 0 0px 0px 0;
    left: -25px;
}
.main_loginpage .testimonials-section .testimonails-slider:after{
    width: 0
}
.main_loginpage .testimonials-section{
    width: 50% !important;
    padding-top: 110px;
}
.main_loginsec {
    width: 50%;
}
.review-heading span.review-icon img {
    width: 35%;
}
.main_loginsec .login_form_modal{
    padding: 200px 50px;
}
}




/*singup modal*/


.singup-modal .modal-header {
    position: unset;
    background: none;
    box-shadow: none;
    border: none;
    padding: 10px;
}
.singup-modal .modal-header button.close.btn.ripplelink {
    background: #f3f3f3;
    font-size: 15px;
    padding: 15px 20px;
    color: #888888;
    font-weight: 300;
}
.singup-modal .modal-content  {
    background: url(../assets/login_formbg.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    height: 100%;
    content: "";
    background-color: #ffff;
}
.singup-modal .modal-header button.close.btn.ripplelink span {
    vertical-align: middle;
}
.singup-modal .main-heading h2 {
    color: #000;
    letter-spacing: 2px;
}
.singup-modal .main-heading h2:after{
  background: #ffebbf;;
}
.singup-modal .modal-body {
    padding: 90px 40px 80px;
}

.singup_pagecontent p {
    font-size: 17px;
    line-height: 25px;
    font-weight: 500;
    color: #686868;
}
.singup_content {
    margin: 20px 0;
}
.singup_content .select_numberwrap {
    display: flex;
    align-items: center;
}
.page-rtl .singup_content .select_numberwrap{
  direction: ltr;
}
.singup_content .form-control {
    height: 75px;
}
.singup_content .form-control select {
    height: 55px;
    border: none;
    font-size: 18px;
    color: #3c3c3c;
    font-weight: 400;
    /* border-right: 1px solid #edeef2; */
    margin-right: 20px;
    padding-right: 5px;
}
.singup_content .form-control input {
    height: 55px;
    width: 100%;
    font-size: 18px;
    padding-left: 10px;
    border: none;
    border-left: 1px solid #edeef2;
}
.singup_btn {
    text-align: center;
    margin-top: 40px;
}
.singup_btn button.btn.btn-default.login_btn {
    width: 100%;
    text-transform: uppercase;
    background: #ffbe2c;
    padding: 15px 0;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    border-radius: 5px;
}
p.clickhere {
    color: #9e9e9e;
    font-size: 17px;
    margin-top: 15px;
    text-align: center;
    font-weight: 500;
}
p.clickhere a {
    color: #000;
}
.signlogofoot {
    position: static;
    text-align: center;
    margin: 25px 0;
    height: auto;
}
p.clickhere.termcondition {
    width: 75%;
    margin: auto;
    line-height: 20px;
    margin-top: 15px;
}
.singup-modal .modal-content {
    overflow-x: hidden;
}
/*.singup-modal .modal-dialog:before{
        content: "";
    width: 100%;
    background: url(../assets/modal_background.png) no-repeat;
    position: absolute;
     right: 0; 
    z-index: 1;
    bottom: 0;
    opacity: 1;
    background-position: 10%;
    height: 160px;
}*/

a.resend_code {
    display: block;
    margin-top: 5px;
}


.listing_wrap {
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.notify_listing ul li {
    padding: 25px 0 25px;
    border-bottom: 1px solid #e9ebee;
    position: relative;
    /* opacity: .66; */
    background: rgb(248 250 253);
    opacity: .5;
}
.notify_listing ul li.active {
    
    background: #fff;
    opacity: 1;
}
.notification-modal .modal-body {
    padding: 90px 0 !important;
}
.listing_wrap p.notify_content span {
    display: block;
    line-height: 25px;
    padding-top: 10px;
    font-weight: ;
    color: #8d8d8d;
    font-weight: 400;
}
.listing_wrap p.notify_content {
    color: #000;
    font-size: 18px;
    padding-left: 15px;
    font-weight: 600;
}
.listing_wrap img{
  width: 30px ;
  height: 30px;
}
p.notify_time {
    position: absolute;
    right: 10px;
    top: 25px;
}
.partitioned {
    padding-left: 15px;
    letter-spacing: 0;
    border: 0;
    background-image: linear-gradient(to left, #a2a0a0 100%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 50px 1px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 18%;
    margin-left: 20px;
    text-align: center;
    font-size: 40px;
    padding: 10px 15px;
    display: inline-flex;
    background-color: transparent;
    /* min-width: 220px; */
}
.otp_wrap {
    padding: 60px 0;
    text-align: center;
}







@media (max-width: 767.98px){

  .navbar ul.sub_drop_menu{
    width: 75px;
  }
  .navbar ul.sub_drop_menu{
    right: 48%;
  }

.lising_cartmain {
    display: block;
    width: 100%;
    margin: 10px 0;
}
.cart_top p{
  font-size: 11px;
}
.cart_top a {
    font-size: 10px;
}
.car_product_heading h3{
  font-size: 16px;
}
ul.listing-info li:after{
  left: -8px;
  bottom: 4px;
}
ul.listing-info li{
  padding: 0px 10px 0 0;
  font-size: 11px;
}
.cart_mid .img_cart p.production{
  font-size: 13px;
}
.cart_price_sec span{
  font-size: 20px
}
.cart_price_sec .right-area a.install_btn{
  font-size: 10px;
}

}

@media (max-width: 1366px) and (min-width: 768px){

.lising_cartmain {
    width: 48%;
    margin-right: 13px;
    margin-bottom: 10px;
}

}



/*notification nodata*/


.notificationnodata-modal .modal-body {
    height: calc(100vh - 56px);
    background: #f8fafd;
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.notify_nodatacontent {
    text-align: center;
    width: 75;
    width: 65%;
    margin: auto;
}
.notify_nodatacontent h3 {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
}
.notify_nodatacontent p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #9a9b9d;
}
.notify_imgnodata{
  text-align: center;
}



/*order confirmed*/


.orderconfirmed-modal .modal-dialog{


  max-width: 450px !important;
    width: 100%;
    /* background-color: #fff; */
    padding: 0px;
    margin: 0px auto !important;
    position: relative !important;
    display: flex;
    /* top: 0; */
    /* bottom: 50%; */
    right: unset !important;
    padding: 50px 0;
}

.order_modalbottom {
    padding: 20px;
    background: #fff;
    z-index: 999;
    text-align: center;
    display: flex;
    height: 250px;
    line-height: 20px;
    justify-content: center;
    align-items: center;
}
.order_modalbottom p {
    font-size: 14px;
    color: #000000;
    font-weight: normal;
    margin-bottom: 20px;
}
.order_modalbottom a {
    font-size: 20px;
    margin-top: 20px;
    color: #000;
    background: #fcc223;
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 300;
}

.orderconfirmed-modal .modal-content{
  background: #fcc223;
  border-radius: 10px !important;
  border: unset !important; 
  height: unset !important;
  overflow: hidden !important;
  transition: transform 0.5s;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    padding-top: 0;
    background-color: #fff;
    max-width: 830px; 
}

.order_modaltop {
    height: 250px;
    position: relative;
    background: #fcc223;
    margin: auto 0;
        display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-bottom: 2px solid #3a30309e;
}
.content_ordermodal {
    position: relative;
}
.order_modaltop:before {
    content: "";
    background: url(../assets/inner-left-city.png) no-repeat;
    position: absolute;
    height: 130px;
    width: 220px;
    bottom: 0;
    left: 0;
    
}
.order_modaltop .tire-mark{
    position: absolute;
    top: 20px;
    right: -160px;
    left: 0;
    bottom: 0;
    text-align: center;

 }   
 .content_ordermodal p.order_number span {
    display: block;
    margin-top: 10px;
    font-size: 27px;
    color: #000;
    font-weight: 500;
}
.content_ordermodal p.order_number {
    font-size: 18px;
    color: #000;
    text-align: center;
}
.content_ordermodal h3{
      font-size: 30px;
    font-weight: 500;
    margin-bottom: 10px;
    color: #000;
}

.car_ordermodal {
    position: absolute;
    bottom: -12px;
    z-index: 11111;
    right: 15px;
}



/*listing nodata pge*/


.main_emptylisting {
    text-align: center;
}

.listing_nodatacontent {
    text-align: center;
}
.listing_nodatacontent .main-heading h2 {
    color: #000;
}
.listing_nodatacontent .main-heading p {
    color: #000;
}
.listing_nodatacontent .main-heading p strong {
    font-weight: bold;
    color: #000;
}
.listing-section .listing-description a.search_again {
    background: #ffbe2c;
    color: #fff;
    text-transform: uppercase;
    padding: 15px;
    border-radius: 10px;
    font-size: 12px;
    margin-left: 10px;
}













@media (max-width: 767.98px){

.listing-section .listing-description a.search_again {
    font-size: 10px;
    padding: 10px;
    line-height: 42px;
    /* margin-top: 71px; */
}

}




/*new css*/


listing-bottom-box .listing-rates .left-area span {
    display: flex;
    padding: 0;
    align-items: center;
}

.listing-bottom-box .listing-rates .left-area span span.discount_price:after {
    content: "";
    background: red;
    width: 1px;
    height: 85px;
    /* display: block; */
    position: absolute;
    transform: rotate(105deg);
    top: -33px;
    left: 40px;
}
.listing-bottom-box .listing-rates .left-area span span.discount_price {
    padding-right:25px;
    position: relative;
    color: #767171;
    font-size: 14px;
}
.listing-block h3 a span.pull-right {
    position: absolute;
    right: 0;
    top: -54px;
    font-size: 16px;
    color: #fcc223;
    margin-right: -30px;
}
section.listing-section ul.listing-info li {
    margin-right: 10px;
}

.navbar-expand-lg .navbar-nav .nav-item ul {
    display: none;
    min-width: 60px;
    background: #fff;
    padding: 0px ;
    border: 1px solid rgba(0,0,0);

}
.navbar-expand-lg .navbar-nav .nav-item:hover ul {
    display: block;
    position: absolute;
}
.navbar-expand-lg .navbar-nav .nav-item:hover ul li.nav-item a {
    padding: 10px 15px !important;
}
.navbar-expand-lg .navbar-nav .nav-item:hover ul li.nav-item a:hover {
    color: #262626;
    text-decoration: none;
    background-color: #f5f5f5;
}
.navbar-expand-lg .navbar-nav .nav-item:hover ul li.nav-item a:first-child {
    border-bottom: solid 1px #ededed;
}
section.location_leftmenu {
    overflow-x: hidden;
    background: #fff;
    width: 25%;
    position: absolute;
    right: 0;
}
section.loction_mapwrap {
    text-align: center;
    width: 75%;
    position: fixed;
}

.installcenterinfo span {
    padding-right: 20px;
    font-size: 18px;
    color: #808080;
}
.installcenterinfo {
    font-weight: 500;
    font-size: 18px;
    color: #808080;
    padding: 10px 0;
}
.installcenterinfo span.bold {
    padding: 0;
    font-weight: bold;
    font-size: 18px;
    color: #ffbe2c
}
header.order_detail_page_header .listing-block .inner-box{
  align-items: center;
}
header.order_detail_page_header .listing-block .inner-box span {
    padding-right: 20px;
    color: #c1c1c1;
}
header.order_detail_page_header .listing-block .inner-box span.bold {
    font-weight: bold;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing {
    padding: 10px 0;
    
    border: none;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing p {
    font-size: 13px !important;
    color: #808080 !important;
    font-weight: 500;
    margin-bottom: 5px;
    display: inline-block;
    width: 30%;
    vertical-align: top;
}
section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing span.full_time {
    font-size: 12px;
    display: inline-block;
    width: 70%;
}


.installcenterinfo.b_bottom {
    border-bottom: 1px solid #e3e9ef !important;
}
.row.order_deliver {
    padding: 0px 0px 0 15px;
}






/*new css*/

.listing-heading span.discount_section {
    position: absolute;
    right: -30px;
    top: -45px;
    font-size: 18px;
    color: #ffc000;
}
.listing-bottom-box .listing-rates span.discount_price {
    font-size: 16px;
    padding: 0 20px 0 0;
}

.listing-bottom-box .listing-rates span.discount_price {
    font-size: 16px;
    padding: 0 20px 0 0;
    color: #767171;
    position: relative;
}
.listing-bottom-box .listing-rates span.discount_price:after {
    content: "";
    width: 2px;
    height: 105px;
    background: red;
    position: absolute;
    top: -47px;
    left: 47px;
    transform: rotate(102deg);
}
section.listing-section ul.listing-info li:after{
  display: none;
}
.lising_cartmain{
  display: unset;
  width: unset;

}
.cart_top a {
    font-weight: 500;
    position: unset;
    color: #92d050;
    font-size: 24px;
}
.cart_top .cencel_btn a.cancle_btn {
    background: #fed24c;
    color: #fff;
    padding: 20px 40px;
    font-size: 18px;
    border-radius: 10px;
}
.cart_listingmainwrap {
    
    background: #fff;
}

.cart_listingmainwrap ul {
    display: flex;
    justify-content: space-between;
        flex-wrap: wrap;
}
.cart_listingmainwrap ul li:last-child {
    border: none;
}
.cart_listingmainwrap ul li {
    width: 33.33%;
    padding: 20px;
    border-right: 1px solid#ffc000;
}
.cart_top{
  align-items: center;
    display: flex;
    justify-content: space-between;
}
.product_cartdetail img {
    width: 200px;
}
.product_cartdetail {
    display: flex;
    align-items: center;
}

.product_cartdetail h3.product_name {
    font-size: 25px;
    color: #000;
    font-weight: 500;
    padding-left: 10px;
}
p.prouction_time {
    font-size: 16px;
    padding: 10px 0;
}
.tire_size p {
    font-size: 16px;
}
p.prouction_time span {
    color: #000;
    font-weight: 500;
}
p.prouction_time span {
    color: #000;
    font-weight: 500;
}
.tire_size {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
}
.tire_size p span {
    font-weight: 500;
    color: #000;
}
.cart_listingmainwrap ul li.cart_mid {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cart_listingmainwrap ul .shop_name ul li {
    width: unset;
    padding: 0;
    border: none;
}
/*.cart_listingmainwrap ul .shop_name ul{
  align-items: center;
}*/
.product_pricedetail p {
    font-size: 16px;
    /* padding-right: 10px; */
    font-weight: 500;
    padding: 5px;
}
.product_pricedetail p span {
    color: #000;
    font-weight: 500;
}
.grand_total p {
    font-size: 12px;
    color: #000;
    font-weight: 500;
}

.grand_total p.total_price {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
    margin-top: 20px;
    text-align: center;
    line-height: 24px;
}
.grand_total p.total_price span {
    display: block;
    font-size: 22px;
}
.tire_shpaddress p {
    font-size: 14px;
    padding: 5px 0;
    color: red;
    font-weight: 500;
}
.cart_listingmainwrap ul li .shop_name {
    padding-top: 20px;
}
.cart_listingmainwrap ul .shop_name ul li p.shop_name {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    padding-bottom: 20px;
}
.cart_listingmainwrap ul .shop_name ul li p {
    font-size: 14px;
    padding: 10px 0;
    font-weight: 500;
    color: #000;
}
.cart_btn a {
    display: block;
    font-size: 18px;
    padding: 20px;
    border: 1px solid #fed24c;
    color: #fed24c;
    font-weight: 500;
    border-radius: 10px;
    margin-bottom: 20px;
    text-align: center;
}
.cart_listingmainwrap ul .shop_name ul li img {
    width: 160px;
}

.cart_top a.order_complete {
    color: #4472c4;
}
.order_copletewrap .cart_listingmainwrap ul .shop_name ul li img {
    width: unset;
}
.order_copletewrap .cart_listingmainwrap ul{
  justify-content: unset;
}
.order_copletewrap .cart_listingmainwrap ul .shop_name ul li{
  padding:0 20px;
}
.cart_listingmainwrap ul .shop_name ul li p.center_name {
    color: #000;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    padding-bottom: 20px;
}
.md-stepper-horizontal {
    display: table;
    width: 40%;
    position: relative;
}
.md-stepper-horizontal .md-step {
    display: table-cell;
    font-size: 13px;
    color: #000;
    padding-top: 15px;
    font-weight: 600;
    text-align: center;
}

.md-stepper-horizontal .md-step .md-step-circle {
        width: 30px;
    height: 30px;
    margin: 0 auto;
    background-color: #d9d9d9;
    border: 1px solid #828282;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    padding-top: 7px;
    margin-bottom: 10px;
}
.md-stepper-horizontal .md-step.active .md-step-title {
    color: rgba(0,0,0,.87);
}
.md-stepper-horizontal .md-step .md-step-title {
    margin-top: 3px;
    font-size: 11px;
    margin-bottom: 3px;
}
.md-stepper-horizontal .md-step .md-step-title {
    color: #888;
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left, .md-stepper-horizontal .md-step:last-child .md-step-bar-right {
    display: none;
}
.md-stepper-horizontal .md-step .md-step-bar-left, .md-stepper-horizontal .md-step .md-step-bar-right {
    position: absolute;
    top: 30px;
    height: 1px;
    border-top: 2px solid #DDDDDD;
}
.md-stepper-horizontal .md-step .md-step-bar-left, .md-stepper-horizontal .md-step .md-step-bar-right {
    position: absolute;
    top: 30px;
    height: 1px;
    border-top: 2px dotted #DDDDDD;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
    left: 0;
    right: 15%;
    margin-right: 20px;
    margin-left: 65px;
}
.md-stepper-horizontal .md-step .md-step-bar-left, .md-stepper-horizontal .md-step .md-step-bar-right {
    position: absolute;
    top: 30px;
    height: 1px;
    border-top: 2px dotted #DDDDDD;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
    right: 0;
    left: 50%;
    margin-left: 20px;
    margin-right: 94px;
}
.md-stepper-horizontal .md-step.active .md-step-circle {
    background-color: #ffc000;
    border-color: #ffc000;
    position: relative;
    z-index: 11; 
}
header.order_detail_page_header .page_title {
    display: flex;
    display: flex;
    align-items: center;
}
header.order_detail_page_header .page_title p {
    font-size: 18px;
    font-weight: 500;
}
header.order_detail_page_header .page_title p span {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    padding-left: 15px;
}

.listing-block.order_detaillistingwrap .inner-box {
    display: block;
}
.listing-block.order_detaillistingwrap .bottom_wrap {
    display: flex;
}
.order_detaillistingwrap .inner-box .dilever_order {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
}
.order_detaillistingwrap .inner-box .dilever_order p {
    font-size: 18px;
    font-weight: 500;
    
}
.order_detaillistingwrap .inner-box .dilever_order p span.deliver_timeing {
    color: #7f8690;
    padding-left: 30px;
    font-weight: 500;
}

.testimonials-section:before{
  top: 0;
  height: 325px;
}
.testimonials-section{
  width: 100%;
  padding: 10px 0 0
}
.col-lg-12.mobile-app.mt-lg-5 {
    margin-top: 0 !important;
}
@media (max-width: 767.98px)
{
  .order_detaillistingwrap .inner-box .dilever_order{
    display: block;
    margin:0 0 35px 0;
  }
  .listing-heading span.discount_section{
    right: 0;
    top: 0
  }
  .order_detaillistingwrap .inner-box .dilever_order p {
    font-size: 11px;
    padding: 10px 0;
    font-weight: 500;
}
.order_detaillistingwrap .inner-box .dilever_order p span.deliver_timeing {
    color: #7f8690;
     padding-left: 0px;
    font-weight: 500;
}
.cart_listingmainwrap ul li {
    width: 100%;
    border: none;
}
.cart_top{
  display: block;
}
.md-stepper-horizontal{
  width: 100%;
}
.md-stepper-horizontal .md-step.active .md-step-circle{
  width: 15px;
  height: 15px;
}
.md-stepper-horizontal .md-step .md-step-circle{
  width: 15px;
  height: 15px;
}
.md-stepper-horizontal .md-step .md-step-bar-right{
  margin-right: 32px;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
    left: 0;
    right: 0;
    margin-right: 20px;
    margin-left: 25px;
}
.md-stepper-horizontal .md-step .md-step-bar-left, .md-stepper-horizontal .md-step .md-step-bar-right{
  top: 22px
}
.cart_top .cencel_btn a.cancle_btn {
    background: #fed24c;
    color: #fff;
    padding: 10px 25px;
    font-size: 12px;
    border-radius: 5px;
}
.md-stepper-horizontal .md-step .md-step-title{
  font-size: 9px;
}
.cencel_btn {
    text-align: center;
    text-align: center;
    margin-top: 20px;
}

.product_cartdetail h3.product_name{
  padding-left: 0;
  font-size:18px;
}
.tire_size{
  margin-top: 10px;
}
.product_cartdetail img{
  width: 130px;
}
.cart_listingmainwrap ul li.cart_mid{
  display: block;
}
.grand_total {
    text-align: center;
    text-align: center;
    width: 100%;
}
.tire_shpaddress p{
  font-size: 12px;
}
.cart_listingmainwrap ul .shop_name ul li img{
  width: unset;
}
}
@media (min-width: 1500.98px){
.cart_listingmainwrap ul li.cart_mid{
  align-items: unset;

}
.cart_btn {
    text-align: center;
    width: 100%;
}
.cart_btn a {
    display: inline-block;
    margin-bottom: 0;
    margin-top: 20px;
    margin-right: 20px;
}
}
.navbar-dark .nav-item:hover .nav-link:not(.disabled) {
    color: #000 !important;
}
.navbar-dark .navbar-nav .nav-link {
    color: #000 !important;
}

/*arabic_site css*/
.page-rtl .listing-block h3 a span{
  font-size: 22px;

}
.page-rtl section.location_leftmenu{
  right: unset;
  left: 0
}
.page-rtl .simple-steps .search-tabs .by-size a span{
  margin-top: 8px
}
.page-rtl{
  direction: rtl;
}
.page-rtl .banner-section {
    direction: ltr;
}
.page-rtl .navbar-tool:last-child{
  margin-left: unset !important;
  margin-right: .5rem;
}
.page-rtl .navbar-brand{
  margin-right: 0 !important;
  margin-left: 12px
}

.page-rtl .navbar .navbar-nav .nav-link img.drop-arrow{
  margin-left: 0;
  margin-right: 8px
}
.page-rtl .navbar .navbar-nav .nav-link img{
  margin-left: 6px;
  margin-right: 0

}
.page-rtl footer .social a{
  margin-right: 0;
  margin-left: 15px
}
.page-rtl footer{
  text-align: right;
}
.page-rtl footer .newsletter .input-group-append .btn{
  right: unset;
  left: 2px;
}
.page-rtl footer:after{
  right: unset;
  left: 0
}

.page-rtl footer:before{
  left: unset;
  right: 40px
}
.page-rtl .main-heading h2:after{
  left: unset;
  right: -8px
}
.page-rtl .blogs-section:before{
  left: unset;
  right: 0
}
.page-rtl .blogs-section .card{
  text-align: right;
}
.page-rt .blogs-section:after, .page-rt .listing-section:after, .page-rt .whoweare-wrap:after, .page-rt .contact-form:after, .page-rt .privacy-policy:after{
  left: unset;
  right: 0
}
.page-rtl .banner-section .col-xl-9.col-lg-9.col-md-9.col-sm-12.pr-lg-5 {
    padding-right: unset !important;
    padding-left: 3rem;
    order: 2;
}
.page-rtl .banner-section .banner-box{
  padding-right: 0 !important;
  padding-left: 3rem;
}
.page-rtl .banner-section .banner-tire:after{
  left: -142px;
  right: unset;
}
.page-rtl .banner-section .slider-nav .img-fill .info{
  left: unset;
  right: 0;
  text-align: right;
}
.page-rtl .banner-section .banner-box:after{
  left: 0
}
.page-rtl .search-tires{
  padding-left: 0;
  padding-right: 10px;
  text-align: right;
}
.page-rtl .simple-steps .search-tabs .by-vehicle a{
  right: unset;
  left: 260px;
  float:left;
}
.page-rtl .simple-steps .search-tabs .by-size a{
  float: left;
  right: unset;
  left: 120px
}
.page-rtl .simple-steps .steps-box h3{
  text-align: right;
}
.page-rtl .simple-steps .tire-deco{
  left: 0;
  right: unset;
}
.page-rtl .simple-steps .steps-box p{
  text-align: right;
}
.page-rtl .simple-steps:before{
  left: unset;
  right: 40px;
}
.page-rtl .simple-steps:after{
  left: 0;
  right: unset;
}
.page-rtl .simple-steps .steps-box .icon{
  text-align: right;
}
.page-rtl .simple-steps .steps-box .icon .icon-number{
  float: left;
  margin-left: 10px;
  margin-right: 0
}
.page-rtl .simple-steps .steps-box .icon:before{
  left: unset;
  right: 2px;
}
.page-rtl .simple-steps .steps-box .icon:after{
  left: unset;
  right: 30%;
  transform: rotate(180deg);
}
.page-rtl .testimonials-section{
  padding-left: 0;
  padding-right: 100px;
}
.page-rtl .testimonails-slider {
    direction: ltr;
}
.page-rtl .testimonails-slider {
    direction: ltr;
    padding: 20px 50px 40px 10px;
}
.page-rtl .testimonials-section:before{
  left: unset;
  right: 0px
}
.page-rtl .testimonials-section .testimonails-slider:after{
  left: unset;
  right: -57px;
}
.page-rtl .tire-deco-2{
  right: unset;
  left: -80px
}
.page-rtl .mobile-app .mobile-app-content{
  text-align: right;
}
.page-rtl .testimonials-section .left-section{
  text-align: right;
}
.page-rtl .testimonials-dots-box{
  right: unset;
  left: -40px
}
.page-rtl .testimonials-section .left-section h2:after{
  right: 10px;
  left: unset;
}

/*about us*/

.page-rtl .inner-page-banner:after{
  right: unset;
  left: 0
}
.page-rtl .inner-page-banner:before{
  right: 0;
  left: unset;
}
.page-rtl .about-section-1 .tire-deco{
  right: unset;
  left: -74px
}
.page-rtl .about-inner{
  text-align: right;
}
.page-rtl .wholistwrap{
  text-align: right;
}
.page-rtl .wholistwrap ul>li>.whodatalst .rightdatawho{
  padding:0 20px 0 0px;
}
.page-rtl .whowecontent{
  text-align: right;
}

.page-rtl .logo-caption{
  text-align: right;
}
.page-rtl .logo-caption img{
  margin-right: 2px;
  margin-left: 0
}


/*contact us*/

.page-rtl .contact-section-1 .contact-details ul li span.contact-icon {
    margin: 1px 2px 0px 30px;
}

.page-rtl .contact-section-1 .contact-details ul li{
  margin-right: 0;
  margin-left: 80px
}
.page-rtl .contact-section-1 .contact-details ul li .details {
    text-align: right;
}
.page-rtl .contact-section-1 .contact-details ul li:last-child {
    margin-left: 0;
}
.page-rtl  .contact-form .form-box{
  text-align: right;
}
.page-rtl .envelope-deco img.deco-2{
  left: unset;
  right: 50px;
}

.page-rtl .envelope-deco img.deco-3{
  left: unset;
  right: 120px;
}
.page-rtl .envelope-deco img.deco-1{
  right: unset;
  left: 80px
}

/*comming soon*/

.page-rtl .coming-soon-content .col-5.col-lg-5.text-right.pr-lg-1 {
        text-align: left !important;
    padding-left: .25rem;
    padding-right: unset !important;
}
.page-rtl section.coming-soon-content .col-4.col-lg-4.pl-0 {
    padding-left: unset !important;
    padding-right: 0;
}
.page-rtl.coming-soon .navbar-nav .nav-link img.drop-arrow {
    margin-left: unset;
    margin-right: 8px;
}
.page-rtl.coming-soon .navbar-nav .nav-link img {
    margin-right: unset;
    margin-left: 6px
}

.page-rtl.coming-soon .tire-deco{
  left: 264px;
  right: unset;
}
.page-rtl .coming-soon-content .coming-soon-heading {
    text-align: right;
}
.page-rtl .listing-section .listing-description{
  text-align: right;
}
.page-rtl .inner-page-banner .list-heading ul li h1{
  text-align: right;
}
.page-rtl .inner-page-banner .tire-mark{
  left: -160px;
  right: 0;
}
.page-rtl .listing-heading span.discount_section {
    right: unset;
    left: -45px;
}
.page-rtl .runflat {
    right: unset;
    left: 20px;
}
.page-rtl .runflatlisting{
  right:85px;
}

.page-rtl .listing-block .listing-heading{
  text-align: right;
}
.page-rtl .listing-block .user-thumb {
    left: unset;
    right: -40px;
}
.page-rtl .listing-block .listing-info li {
    border-left: 1px solid #eceef2;
    margin-left: 34px;
    margin-right: 0;
    padding-left: 15px;
    text-align: right;
    padding-right: 0;
    text-align: right;
    border-right: unset;
    font-weight: bold;
}
.page-rtl .listing-block .listing-info li:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
    margin-left: 0;
    border-left: 0;
    padding-left: 0;
}
.page-rtl .listing-bottom-box .listing-rates .right-area{
  text-align: left;
}
.page-rtl .listing-bottom-box .listing-rates span.discount_price {
    padding: 0;
    padding-left: 20px;
}
.page-rtl .listing-bottom-box .listing-rates .left-area .total-price {
    margin-right: 0;
    margin-left: 16px;
    font-weight: bold;
    font-size: 18px
}
.page-rtl .listing-bottom-box .listing-rates span.discount_price:after{
  left: unset;
  right: 47px
}



/*location modal*/

.page-rtl .form-group.locationgroup a.nav-link.pl-0{
  left: unset;
  right: 13%;
}
.page-rtl .form-group.locationgroup a.nav-link.pl-0 img {
    padding-right: 5px;
    vertical-align: middle;
    padding-left: 10px;
}
.page-rtl .countrymodal .popup-content .content-body .outertitle .innertitle h3:after{
  left: unset;
  right: 24%
}
.page-rtl .countrymodal .btn.close{
  right: unset;
  left: 0
}


.page-rtl .modal.right.fade.show .modal-dialog{
  right: unset;
  left: 0
}
.page-rtl .modal.right .modal-body{
  text-align: right;
}



/*order summray*/

.page-rtl header.order_detail_page_header .listing-block{
  text-align: right;
}
.page-rtl .order_tire_listing ul li{
  border-left: 1px solid #eceef2;
  border-right: 0px;
}
.page-rtl section.order_summraypopup{
  text-align: right;
  margin-right: 40px;
  margin-left: 0px;
  width: 30%;
}
.page-rtl section.tire_infowrap .upper-box.tireinfo_istingwrap .working_timeing{
  border-left: 0;
  border-right: 1px solid #eceef2;
}
.page-rtl .listing-block .inner-box{
  text-align: right;
}
.page-rtl section.tire_infowrap{
  padding-left: 0px;
  text-align: right;
  padding-right: 150px;
}
.page-rtl .MI:after{
  left: 8px;
}
.page-rtl .wrnty:after{
  left: 0px;
}
.page-rtl .qty:after{
  left: 30px;
}
.page-rtl section.tire_infowrap{
  width: 70%
}
.page-rtl .order_tire_listing ul li:last-child{
  border: 0
}
.page-rtl .tire_infowrap ul.listing-info li:after{
    display: none;
}

/*privacy*/

.page-rtl .inner-page-banner.center-text-banner #list-heading h3{
  text-align: right;
  padding: 0 327px;
}
.page-rtl .inner-page-banner.center-text-banner #list-heading p{
  text-align: right;
  padding: 0 367px;
}
.page-rtl .privacy-policy{
  text-align: right;
}

.page-rtl .inner-page-banner .list-heading{
  text-align: right;
}
.page-rtl .navbar-by h1 {
    margin-left: 0;
    margin-right: 9px;
}
.page-rtl .navbar-right ul li a:after{
  left: 0;
  right: 25px;
}
.page-rtl .brands-marvelapp .active:before{
  right: unset;
  left: 0;
  transform: rotate(360deg);
}
.page-rtl .navbar-right ul li span:after{
  left: unset;
  right: -1px
}
.page-rtl .box-right span:after{
  left: unset;
  right: -3px;
}
.page-rtl .brands-estb .card-top .box-left.mr-auto {
    margin-right: 0 !important;
    margin-left: auto;
    text-align: right;
}
.page-rtl  .box-right{
  direction: ltr;
}
/*.page-rtl .singup_content .form-control select{
  margin-left: 20px;
    padding-left: 5px;
   
    margin-right: 0;
}*/
/*.page-rtl .singup_content .form-control input{
  border-left: 0;
  border-right:1px solid #edeef2;
  padding-left: 0;
  padding-right: 10px;
      text-align: right;
    direction: ltr;
}*/

.page-rtl .otp_wrap{
  direction: ltr;
}
.page-rtl .navbar-by {
    text-align: right;
}
.page-rtl .modal-header .close {
    margin-left: 0;
    margin-right: auto;
    margin: -1rem auto -1rem -1.25rem;
}
.listing_wrap{
  align-items: flex-start;
}
.page-rtl .listing_wrap p.notify_content{
  padding-right: 15px;
  padding-left: 0
}
.page-rtl p.notify_time{
  right: unset;
  left: 10px;
}
.page-rtl .simple-steps .search-tabs .by-vehicle a:before{
  display: none;
}
.page-rtl .simple-steps .search-tabs .by-size a:before{
  display: none;
}
.page-rtl .testimonials-section .long-arrow-right{
  margin-right: 70px;
  margin-left: 0;
  transform:rotate(300deg);
}

.page-rtl .mobile-app .mobile-app-content .app-btns{
  padding-left: 0;
  padding: 12px 20px 10px;
   padding-right: 50px;
}
.page-rtl .mobile-app .mobile-app-content .app-btns.apple-btn img{
  left: unset;
  right: 15px;
}

.page-rtl .mobile-app .mobile-app-content .app-btns.google-btn img{
  left: unset;
  right: 15px;
}
.page-rtl .mobile-app .mobile-app-content .app-btns{
  margin-right: 0;
  margin-left: 20px;
  font-family: 'Segoe UI' 
}
/*.page-rtl .testimonials-section .testimonails-title{
      position: absolute;
    width: 100%;
    font-size: 120px;
    font-weight: 800;
    pointer-events: none;
    overflow: hidden;
    transform: rotate(-90deg) scaleX(1);
    transform-origin: bottom right;
    top: -400px;
    right: 170px;
    left: unset;
    bottom: unset;
    color: #000;
    opacity: 0.02;
}*/
.page-rtl .listing-block .content-box{
  padding: 0 20px 0 10px
}
.page-rtl .cart_heading h2{
  text-align: right;
}
.page-rtl .cart_listingmainwrap ul .shop_name ul li{
  text-align: right;
}
.page-rtl .tire_shpaddress {
    text-align: right;
}
.page-rtl .product_cartdetail h3.product_name{
  padding-left: 0;
  padding-right: 10px
}
.page-rtl .booking_date_cart {
    text-align: right;
}
.page-rtl .form-group.locationgroup p{
  border-radius: 0px 5px 5px 0px;
}
.page-rtl .form-group.locationgroup input.form-control{
  border-radius: 5px 0px 0px 5px
}
.page-rtl .cart_listingmainwrap ul li{
  border-left: 1px solid#ffc000;
  border-right: 0
}
.page-rtl .listing-block h3 a span{
  padding-right: 12px;
  right: unset;
  left: 0;
  top: -60px;
  padding-left: 0;
  /* margin-right: -30px; */

}
.page-rtl .navbar-left img{
  transform: rotate(180deg);
  margin-right: 0;
    margin-left: 10px;
}
.page-rtl .md-stepper-horizontal .md-step .md-step-bar-left {
    right: 0;
    left: 15%;
    margin-left: 20px;
    margin-right: 65px;
}
.page-rtl .md-stepper-horizontal .md-step .md-step-bar-right {
    left: 0;
    right: 50%;
    margin-right: 20px;
    margin-left: 94px;
}
@media (max-width: 767.98px){
.page-rtl .contact-section-1 .contact-details ul li span.contact-icon {
    margin: 0 auto 20px;
}
.page-rtl .contact-section-1 .contact-details ul li .details {
    text-align: center;
}
.page-rtl.coming-soon .tire-deco {
    right: unset;
    left: 54px;
}
.page-rtl .listing-block .listing-info li{
  padding-left: 0;
  margin-left: 0;
  border:0;
}
.page-rtl .listing-block .user-thumb{
  left: 0;
  right: auto;
}
.page-rtl .runflat{
  right: 60px;
  left: auto;
}
.page-rtl .listing-bottom-box .listing-rates .right-area{
  text-align: center;
}
.page-rtl .listing-heading span.discount_section{
  left: 0;
    font-size: 10px;
    top: -25px;
}
.page-rtl .form-group.locationgroup a.nav-link.pl-0{
  left: unset;
  right: 0
}

.page-rtl .testimonials-section{
  padding-right: 10px;
  padding-left: 10px
}
.page-rtl .tire-deco-2 {
    position: absolute;
    left: 10px;
    bottom: 10px;
    width: 140px;
}
.page-rtl .testimonials-section .testimonails-title {
    top: 580px;
    white-space: nowrap;
    font-size: 40px;
    bottom: auto;
    right: auto;
    left: -270px;
}
.page-rtl .banner-section .banner-tire img {
    padding: 40px 40px 0;
}
.page-rtl .banner-section .banner-tire:after {
    left: 0px;
    width: 350px
}

.page-rtl .banner-section .banner-box {
    padding-right: 0 !important;
    padding-left: 0;
}
.page-rtl .banner-section .col-xl-9.col-lg-9.col-md-9.col-sm-12.pr-lg-5{
      padding-right: 15px !important;
    padding-left: 15px;
    order: unset;

}
.page-rtl  .simple-steps .search-tabs .by-vehicle a {
    top: -163px;
    left: 150px;
}
.page-rtl .simple-steps .search-tabs .by-size a {
    top: -41px;
    left: 10px;
}
.page-rtl .search-tires .search-arrow {
    position: absolute;
    top: -20px;
    right: 40px;
}
.page-rtl .search-tires {
    padding-left: 130px;
    padding-right: 0;
}
.page-rtl .testimonials-section .long-arrow-right {
    margin-right: 34px;
}
.page-rtl .testimonials-section .testimonails-slider {
    padding: 50px 40px 40px 30px;
    background: #fff;
    border-radius: 0 0px 0px 0;
    right: -25px;
}
.page-rtl .testimonials-dots-box {
    left: 0px;
}
.page-rtl .mobile-app img.mob-app {
    margin-right: 0;
    margin-left: 0;
}
}
@media (max-width: 1500px){
.page-rtl .simple-steps .tire-deco {
    right: unset;
    left: 10px;
    bottom: 0
}
.page-rtl header.location_page_header .inner-box ul.listing-info li{
  margin-left: 15px
}
.page-rtl .testimonials-section .testimonails-title {
    right: 60px;
    left: unset;
}
.page-rtl .order_summrywrap .listing-block .listing-info li{
  padding-right:0;
  padding-left: 7px;
  margin-right: 0;
  margin-left: 7px;
}
}
  @media (max-width: 767.98px){
.page-rtl .about-section-1 .tire-deco {
    
    left: -24px;
    right: unset;
}
}
@media (min-width: 992px){
.page-rtl .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 1.125rem !important;
}
.page-rtl .whoweare-wrap .col-lg-5.offset-lg-1 {
    margin-right: 8.3333333333%;
    margin-left: unset;
}

}



@media (max-width: 1280px) and (min-width: 768px){
.page-rtl section.tire_infowrap {
    padding: 0 15px;
    width: 100%;
}
.page-rtl section.order_summraypopup {
    width: 75%;
    margin: auto;
}
}

@media (max-width: 1500.98px){
.page-rtl section.tire_infowrap {
    padding-right: 50px;
}
/*section.tire_infowrap .listing-block .listing-info li {
    padding-left: 0px;
    margin-left: 0px;
    margin-right: 0;
    padding-right: 0
}*/
.installcenterinfo{
  font-size: 14px
}
.installcenterinfo span{
  font-size: 14px
}
.installcenterinfo span.bold{
  font-size: 14px
}
}

#Proceed .modal-content{
  padding: 0 0 30px !important
}

.selectSizeLink{
  font-size: 12px !important;
}
#Proceed .modal-body.quantity input{
  
  border: 1px solid #c0c0c1!important;;

}
header.location_page_header .inner-box .listing-bottom-box .listing-rates .left-area{
  width: 100%;
  padding:0;
}
.listing-bottom-box .listing-rates .left-area .total-price{
  font-size: 12px;
  margin-right: 10px;
}
header.location_page_header .inner-box .content-box .upper-box{
  margin: 0
}
.listing-block .inner-box{
  padding: 15px 15px 15px
}
.listing-block .listing-info{
  padding: 10px;
}
header.location_page_header .inner-box .content-box .upper-box{
  padding-left: 30px
}
#Proceed .modal-body.quantity input[type="number"]::-webkit-inner-spin-button {
    opacity: 1;
    -webkit-appearance: "Always Show Up/Down Arrows";
    appearance: auto;
}

.error-input {
  color: red;
  margin-top: 15px;
  margin-bottom: 15px;

}
span.front-ttl {
    display: block;
    margin: -20px 0 5px;
    font-size: 18px;
    color: #fff;
}
.navbar-right ul li span.front-ttl:after{
    display: none;
    
}
section.admin_banner-section.bg-size-cover.bg-position-center .select_numberwrap select {
    height: 60px;
    padding: 20px 10px;
    border: 1px solid #dae1e7;
    background: transparent;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
section.admin_banner-section.bg-size-cover.bg-position-center .select_numberwrap input.inputMobile {
    height: 60px;
    width: 100%;
    padding: 0 20px;
    border: 1px solid #dae1e7;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    border-left: 0;
}
.admin_banner-section{
  height: 100vh
}

.navbar ul.sub_drop_menu{
    transform: scaleY(0);
    -webkit-transform: scaleY(0);
    transform-origin: top center;
    -webkit-transform-origin: top center;
    left: auto;
    right: 15px;
    top: 85%;
    height: 44px;
    transition: .3s ease-in-out;
    background: #ffffff;
    width: 160px;
    border-radius: 5px;
    border: solid 1px #ececec;
    border-top-color: rgb(236, 236, 236);
    border-right-color: rgb(236, 236, 236);
    border-bottom-color: rgb(236, 236, 236);
    border-left-color: rgb(236, 236, 236);
    box-shadow: 0 0 55px rgba(0, 0, 0, 0.11);
    position: absolute;
    z-index: 999999;
    visibility: hidden;
    
}
.navbar ul.sub_drop_menu {
    width: 168px;
    border-color: #efefef;
}

.navbar .user_icon:hover .sub_drop_menu {
    transform: scaleY(1);
    -webkit-transform: scaleX(1);
    opacity: 1;
    visibility: visible;
}
.navbar .user_icon .sub_drop_menu::after, .navbar .user_icon .sub_drop_menu::before {
    bottom: 100%;
    left: 80%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
.navbar .user_icon .sub_drop_menu::before {
    border-color: rgba(236, 236, 236, 0);
    border-bottom-color: #ececec;
    border-width: 9px;
    margin-left: -9px;
}
.navbar .user_icon .sub_drop_menu li {
    width: 100%;
    padding: 0;
    margin: 0;
}
.navbar .user_icon .sub_drop_menu li {
    width: 100%;
    padding: 0;
    margin: 0;
}
.navbar .user_icon .sub_drop_menu li {
    width: 100%;
    padding: 0;
    margin: 0;
}
.navbar .user_icon .sub_drop_menu li a {
    height: auto;
    box-shadow: none;
    background: transparent;
    border-radius: 0;
    width: 100%;
    line-height: 1.6;
    text-align: left;
    padding: 10px 15px;
    font-size: 13px;
    margin: 0px;
    display: flex;
    justify-content: start;
    align-items: center;
    color: rgba(0, 0, 0, 0.99);
}
.navbar .user_icon .sub_drop_menu li a {
    display: flex;
    align-items: center;
    color: #7e7e7e;
    border-left: solid 2px transparent;
    transition: ease-in-out 0.3s;
    border-top: solid 1px transparent;
    border-bottom: solid 1px transparent;
}
.navbar .user_icon .sub_drop_menu li a:hover {
    background: #fcfcfc;
    color: #000;
    border-left-color: #fcc234;
    border-top-color: #f3f3f3;
    border-bottom-color: #f3f3f3;
}

div#\#navbarCollapse .navbar-right {
  display: flex;
}
.step{
  left: 32px !important
}
header.order_detail_page_header .listing-block .inner-box span span.warehorseValue {
    color: #ffbe2c;
    font-weight: bold;
    padding: 0;
    letter-spacing: 1px;
}
header.order_detail_page_header .listing-block .inner-box span {
    padding-right: 20px;
    color: #808080;
    font-size: 18px;
}

.u-brand-col-left{
  display: none;
}
.u-brand-col-right{
  width: unset !important;
}
/*.rear-size.col-md-7 {
    padding: 15px !important;
}*/
/* .timeing_details.active {
  border-left-color: #fcc223;
} */
.timeing_details:hover {
  border-left-color: #fcc223;
}
.timeing_details {
  border-bottom: 2px solid#f2f2f2;
  border-left: 2px solid transparent;
  cursor: pointer;
}



@media (max-width: 767.98px){

  .navbar ul.sub_drop_menu{
    width: 75px;
  }
  .navbar ul.sub_drop_menu{
    right: 42%;
    top: 100%;
  }
  .container-fluid{
    padding-left: 15px;
  }
}


/*car page*/
.page-rtl .col-sm-3.offset-1.offset_unset{
  margin-left: 0;
  margin-right: 8.3333333333%
}
.page-rtl .md-stepper-horizontal .md-step .md-step-bar-left {
    right: 0;
    left: 15%;
    margin-left: 0;
    margin-right: 15px;
}
.page-rtl .text-left {
  text-align: right !important;
}
.page-rtl .ml-2{
  margin-left: 0 !important;
  margin-right: .5rem !important;
}
.page-rtl .cart_inner .bootom_cart .right_wrap .order-good1 .order-add{
  text-align: right;
}
.page-rtl .cart_inner .order-good1{
  text-align: right;
}
.page-rtl .cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap .working_timeing {
    padding-right: 30px;
    border-right: 1px solid#eceef2;
    border-left: none;
    padding-left: 0;
}
.page-rtl .cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap .listing-heading.tireinfo_isting-heading {
    padding-right: 10px;
    padding-left: 20px;
    text-align: right}
.page-rtl .cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap .listing-heading.tireinfo_isting-heading h3 a {
    display: block;
}
.page-rtl ul.contact_shop li {
    padding: 0 0 0 40px;
}
.page-rtl p.deliver_time{
  text-align: right;
}
.page-rtl .confirmed_check{
  left: unset;
  right: -1px
}
section.cart_contenwrp.whoweare-wrap {
    padding-top: 50px;
    padding-bottom: 230px;
}
.cart_heading h2 {
    font-size: 17px;
    /* font-weight: 500; */
    font-weight: 500;
    color: #000;
}
.cart_listing ul.cart_mainlisting li {
    display: inline-block;
    align-items: center;
    border-radius: 15px;
    margin: 0px 10px 0 0;
    padding: 10px;
    width: 33%;
}

.cart_top {
    background: #fbfbff;
    border-bottom: 1px solid #f3f3f3;
    padding: 15px;
    position: relative;
    border-radius: 10px 10px 0 0;
}
.cart_top p {
    font-size: 14px;
    color: #353949;
    font-weight: 500;
    margin: 10px 0;
}
/*.cart_top a {
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    right: 15px;
    top: 35px;
    color: #518c2a;
}*/
.lising_cartmain:last-child {
    margin-right: 0;
}
/* .lising_cartmain {
    display: inline-block;
    justify-content: space-between;
    width: 32%;
    align-items: center;
    margin-right: 19px;
} */
.cart_listingwrap{
  margin: 30px 0px;
}
.cart_inner .card-order.u-inner-wrapper1 {
    margin: 0;
    border-radius: 0 0 8px 8px;
}
.cart_inner {
    background: #fff;
    padding: 15px 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.cart_inner .card-order.u-inner-wrapper1 ul li .u-list-box{
  border-radius: 0 0 8px 8px;
  padding: 15px 10px 0;
}
.cart_inner .order-main{
    width: 100%;
}
.cart_inner .order-main.u-top-mid-col{
  padding: 12px 20px 24px;
}
.cart_inner .order-title span{
  display: block;
  margin-bottom: 5px;
}
ul.tire_qty {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0 0;
}
.cart_inner .card-order.u-inner-wrapper1 ul li{
  margin: 0
}
ul.tire_qty li {
    color: #8e8e8e;
    font-size: 13px;
    margin: 10px 0;
}
ul.tire_qty li span {
    display: block;
    color: #000;
    margin-top: 5px;
    opacity: 1;
    font-weight: 500;
}  
.cart_inner span.free {
    background: #8acd36;
    padding: 2px 8px;
    border-radius: 5px;
    color: #ffff;
}
.cart_inner .bootom_cart {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    flex-wrap: wrap;
}
.cart_inner .bootom_cart .left_wrap .listing-block {
   position: relative;
    margin-bottom: 0;
    background: #fcfcfc;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #f0f0f0;
    border-radius: 10px;
}
.cart_inner .bootom_cart .left_wrap .inner-box {
    box-shadow: none;
    background: #fcfcfc;
    /* margin-bottom: 20px; */
    padding: 0;
    
}
.cart_inner .bootom_cart .left_wrap .inner-box .image-box.img_first {
    width: 25%;
}
.cart_inner .bootom_cart .left_wrap {
    width: 65%;
}
.cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap h3 {
    position: relative;
    font-size: 18px;
    line-height: 1.2em;
    color: #2b3041;
    font-weight: 600;
    margin-bottom: 5px;
}
.cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap p {
    font-size: 14px;
    margin-top: 0;
    line-height: 1.6;
}
.cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap .listing-heading.tireinfo_isting-heading {
    width: 45%;
    padding-right: 20px;
    align-items: center;
    display: grid;
}
.cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap .working_timeing {
    width: 55%;
    padding-left: 30px;
    border-left: 1px solid#eceef2;
}
ul.contact_shop {
    display: flex;
    margin-top: 20px;
}
ul.contact_shop li{
    padding: 0 40px 0 0;
}
ul.contact_shop li:last-child{
  padding: 0;

}
ul.contact_shop li a{
   color: #ffbe2c;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}
p.deliver_time {
    font-size: 13px;
    font-weight: 500;
    margin-top: 20px;
    color: #ff0000;
    line-height: 1.6;
}
p.deliver_time b{
    
    font-weight: bold;
   
}
.cart_inner .bootom_cart .right_wrap {
    width: 35%;
    text-align: center;
}
.cart_inner .bootom_cart .right_wrap .pay_btn a.confrm_book {
    border-radius: 10px;
    font-size: 18px;
    background: #f1f3fa;
    color: #1f1f1f;
    text-transform: capitalize;
    padding: 20px 110px;
    font-weight: 500;
    width: 100%;
}
.cart_inner .bootom_cart .right_wrap .pay_btn a.alert-primary_link{
    display: block;
    margin: 40px 0;
    text-transform: uppercase;
    color: #808080;
    font-weight: normal;
    opacity: .8;
}
.rear-size.col-md-5.order_complete {
    display: flex;
    /* align-items: center; */
    flex-wrap: wrap;
}
.rear-size.col-md-5.order_complete .clearfix {
    width: 50%;
}

.md-stepper-horizontal {
    display: table;
    width: 50%;
    position: relative;
}
.md-stepper-horizontal .md-step {
    display: table-cell;
    font-size: 13px;
    color: #000;
    padding-top: 15px;
    font-weight: 600;
    text-align: center;
}

.md-stepper-horizontal .md-step .md-step-circle {
    width: 25px;
    height: 25px;
    margin: 0 auto;
    background-color: #e8e9f1;
    border: 8px solid #e8e9f1;
    border-radius: 50%;
    text-align: center;
    line-height: 18px;
    font-size: 16px;
    font-weight: 600;
    color: #FFFFFF;
    padding-top: 7px;
    margin-bottom: 10px;
    position: relative;
    z-index: 99;
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left, .md-stepper-horizontal .md-step:last-child .md-step-bar-right {
    display: none;
}
.md-stepper-horizontal .md-step.active .md-step-title {
    color: #000;
    text-transform: uppercase;
    opacity: 1;
}
.md-stepper-horizontal .md-step .md-step-title {
    margin-top: 3px;
    font-size: 12px;
    margin-bottom: 3px;
    letter-spacing: 1px;
}
.md-stepper-horizontal .md-step .md-step-title {
    color: #000;
    opacity: .5
}
.md-stepper-horizontal .md-step:first-child .md-step-bar-left, .md-stepper-horizontal .md-step:last-child .md-step-bar-right {
    display: none;
}
.md-stepper-horizontal .md-step .md-step-bar-left, .md-stepper-horizontal .md-step .md-step-bar-right {
    position: absolute;
    top: 30px;
    height: 1px;
    border-top: 2px solid #DDDDDD;
}
.md-stepper-horizontal .md-step .md-step-bar-left, .md-stepper-horizontal .md-step .md-step-bar-right {
    position: absolute;
    top: 30px;
    height: 1px;
    border-top: 2px solid #DDDDDD;
}
.md-stepper-horizontal .md-step .md-step-bar-left {
    left: 0;
    right: 15%;
    margin-right: 20px;
    margin-left: 67px;
}
.md-stepper-horizontal .md-step .md-step-bar-left, .md-stepper-horizontal .md-step .md-step-bar-right {
    position: absolute;
    top: 30px;
    height: 1px;
    border-top: 2px solid #DDDDDD;
}
.md-stepper-horizontal .md-step.confiremd .md-step-circle {
    background-color: #58aa66;
    border-color: #58aa66;
    width: 30px;
    height: 30px;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
    right: 0;
    left: 50%;
    margin-left: 20px;
    margin-right: 94px;
}
.md-stepper-horizontal .md-step.active .md-step-circle {
    background-color: #fff;
    border-color: #ffc000;
    position: relative;
    border-width: 10px;
    z-index: 11;
    width: 30px;
    height: 30px
}
.confirmed_check{
      position: absolute;
    top: 2px;
    left: 2px;
    z-index: 999;
}
.cart_inner .u-brand-col-left {
  display: flex;
}
.cart_inner .u-brand-col-left {
  width: 40%;
  margin-top: 22px;
  float: left;
  padding-left: 5px;
}
.cart_inner .u-brand-col-right {
  width: 60% !important;
  display: inline-grid;
  padding: 11px 6px 11px 15px;
  line-height: 14px;
}
.cart_inner .u-brand-col-right span.u-list-year {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}
.cart_inner s.u-brand-col-right span.u-list-brand {
  font-size: 20px;
  margin-bottom: 10px;
}
.cart_inner .u-brand-col-right b {
  font-weight: bold;
  color: #000;
}
.cart_inner .u-brand-col-right .bin {
  color: #fa7102;
  margin-top: 3px;
}
.cart_inner .u-brand-col-right span.u-list-made>span {
  display: block;
  margin-bottom: 3px;
  font-size: 12px;
  font-weight: 400;
  color: #8e8e8e;
}
.cart_inner .u-brand-col-right span.u-list-made {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin-bottom: 5px;
}
.cart_inner .order-title {
    padding: 0 0 10px;
}
.cart_inner .order-title b {
  color: #000;
  font-weight: 500;
  font-size: 18px;
}
.cart_inner ul.tire_qty {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0 0;
}
.cart_inner ul.tire_qty li span {
  display: block;
  color: #000;
  margin-top: 5px;
  opacity: 1;
  font-weight: 500;
  margin-left: 10px;
}
.cart_inner .order-main {
 
  border-left: 1px solid #d7e1f3;
  border-right: 1px solid #d7e1f3;
}
.cart_inner  .order-good1 {
  text-align: right;
}

.cart_inner .order-good1 .order-add {
  padding: 0;
  line-height: 29px;
  margin-top: 0px;
}

.cart_inner .order-good1 .anorder {
  padding: 0;
  line-height: 29px;
  margin-top: 20px;
}
.cart_inner .order-add {
  display: inline-block;
  padding-right: 7px;
}
.cart_inner .order-add span {
  color: #8e8e8e;
  font-size: 13px;
  margin-top: 7px;
}
.cart_inner .order-add b {
  color: #000;
  font-weight: 500;
}
.cart_inner .order-add.u-dis {
  margin-bottom: 10px;
  display: block;
}
.cart_inner .order-good1 .order-add.u-dis b {
  color: #8acd36;
}
.cart_inner .order-good1 .order-add .u-list-tot {
  font-weight: 600;
  color: #000;
  font-size: 14px;
  margin-top: 5px;
  display: block;
}
.cart_inner span.free {
  background: #8acd36;
  padding: 2px 8px;
  border-radius: 5px;
  color: #ffff;
}
.cart_inner .u-brand-col-right span {
  color: #8e8e8e;
  font-size: 11px;
  margin-top: 7px;
}
.cart_inner .u-brand-col-left img {
  width: 122px;
  height: 86px;
  display: block;
  box-shadow: 0 0 29px rgba(0, 0, 0, 0.06);
}
.md-stepper-horizontal .md-step .md-step-bar-left {
    left: 0;
    right: 50%;
    margin-right: 20px;
}
.md-stepper-horizontal .md-step .md-step-bar-right {
    right: 0;
    left: 40%;
    margin-left: 20px;
    margin-right: 70px;
    z-index: 9;
}
.page-rtl .listing-block .inner-box .navbar-left-arrow img {
    transform: rotate(180deg);
}
.page-rtl .row.order_deliver .col-md-6.border-left.text-right {
    text-align: left !important;
    border-left: none !important;
    border-right: 1px solid #e3e9ef !important;
}
.modal.right.fade.tire-details.show .listing_wrap {
    align-items: center;
}
ul.menu.left-to-right.rearMenu {
    padding-left: 85px;
}
.page-rtl ul.menu.left-to-right.rearMenu {
    padding-left: 0px;
    padding-right: 85px;
}
.page-rtl .row.order_deliver {
    padding-left: 0;
    padding-right: 15px;
}
.price_summarylisting ul ul.front-Rear-D:last-child {
    margin-bottom: 0;
}

.price_summarylisting ul ul.front-Rear-D li {
    padding: 5px 0;
}
.price_summarylisting ul ul.front-Rear-D {
    margin-bottom: 20px;
}

/*pagecloase*/


/*faq*/

.accordionData.accordion .card {
    margin: 20px 0;
}
.accordionData.accordion .card .title.card-header {
    font-size: 18px;
    border-radius: 10px;
    font-weight: 500;
}
.accordionData.accordion .card .card-body {
    line-height: 1.6;
    font-size: 15px;
    border: 1px solid #efefef;    border-radius: 10px
}
.page-rtl .accordionData.accordion .card .title.card-header{
  text-align: right;
}
.page-rtl .accordionData.accordion .card .card-body{
  text-align: right;
}
@media (max-width: 1199px){
  .offset_unset{
    margin-left: 0
  }
  .col_3responsive{
    flex: 0 0 25%;
      max-width: 25%;
  }
  .cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap h3{
    font-size: 13px;
  }
  .cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap p{
    font-size: 12px;
  }
  .cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap .working_timeing{
    padding-left: 10px;
  }
  ul.contact_shop li a{
    font-size: 12px;
  }
  p.deliver_time{
    font-size: 11px;
  }
  .cart_inner .bootom_cart .left_wrap {
      width: 70%;
  }
  .cart_inner .bootom_cart .right_wrap {
      width: 30%;
      text-align: center;
  }
  .cart_inner .bootom_cart .right_wrap .pay_btn a.confrm_book{
    padding: 20px 40px;
    font-size: 12px;
  }
  .cart_inner .bootom_cart .right_wrap .pay_btn a.alert-primary_link{
    font-size: 10px; 
    margin: 30px auto;
  }
  .page-rtl .col-sm-3.offset-1.offset_unset{
    margin-right: 0
  }
  div#\#navbarCollapse {
    display: block !important;
  }
  ul.menu.left-to-right.rearMenu{padding-left: 30px}
  .navbar-right ul li{
    padding: 15px !important
  }
  ul.menu.left-to-right {
    margin-top: 60px;
  }
  
  }
  @media (max-width: 991.98px){
.navbar-expand-lg>.container, .navbar-expand-lg>.container-fluid, .navbar-expand-lg>.container-xl {
    padding-right: 15px;
    padding-left: 15px;
}
ul.menu.left-to-right.rearMenu{padding-left: 50px}
    
.col-lg-12.mobile-app.mt-lg-5 .row {
    flex-wrap: nowrap;
}

}


@media (min-width: 992px){
    ul.menu.left-to-right.rearMenu{padding-left: 50px}
    .page-rtl ul.menu.left-to-right.rearMenu{padding-left: 0px; padding-right: 40px}
}
    
.col-lg-12.mobile-app.mt-lg-5 .row {
    flex-wrap: nowrap;
}

  
  
  @media (max-width: 767px){
    .tire_decription_details {
      margin-top: 17px;
    }
    li.fuelbox .gauge-container{
      width: 95px !important;
    }
    li.fuelbox .gauge_listdetail {
         width: 98px !important;
  }
  .gauge_listdetail p{
    font-size: 10px;
  }
  .page-rtl .cart_top a {
      display: flex;
      align-items: center;
  }
  .page-rtl section.location_leftmenu .col-lg-12{
    margin-right:0;
    margin-left:30px;
  }
  .md-stepper-horizontal .md-step .md-step-bar-left, .md-stepper-horizontal .md-step .md-step-bar-right{
    top: 30px
  }
  .col_3responsive{
    flex: 0 0 100%;
      max-width: 100%;
  }
  .cart_inner .bootom_cart .left_wrap {
      width: 100%;
  }
  .cart_inner .bootom_cart .left_wrap .inner-box .image-box.img_first{
    width: 100%;
    margin: 0
  }
  .cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap .listing-heading.tireinfo_isting-heading{
    width: 100%;
    display: block;
    padding: 0 0 0 15px;
  }
  .cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap .working_timeing{
    width: 100%;
      border: none;
      padding: 0;
      margin-top: 20px;
  }
  .cart_in0ner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap{
    display: block;
    margin-bottom: 10px;
  }
  .cart_inner .bootom_cart .left_wrap .listing-block{
    border-radius: 0;
    padding: 15px;
  }
  .cart_inner .bootom_cart{
    padding:0;
  }
  .cart_inner .bootom_cart .content-box{
        position: relative;
      width: 100%;
      padding: 0;
      margin-top: 10px;
  }
  
  .cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap{
    display: block;
  }
  .cart_inner .bootom_cart .right_wrap .pay_btn a.alert-primary_link{
    margin-bottom: 0
  }

  .cart_inner .bootom_cart .right_wrap {
      width: 100%;
      text-align: center;
      margin: 20px 0 0 0;
  }
  .cart_inner .order-good1 .order-add {
    width: 100%;
    padding-left: 0
  }
  .cart_inner .card-order.u-inner-wrapper1 ul li{
    font-size: 14px;
    padding-right: 15px

  }
  .cart_inner ul.tire_qty li span{display: unset;}
   .cart_inner .card-order.u-inner-wrapper1 ul li:last-child{padding: 0}
  
  .page-rtl .cart_inner .card-order.u-inner-wrapper1 ul li{
    font-size: 10px;
    text-align: right;
  }
  .cart_inner .u-brand-logo.col-md-3 {
    margin-bottom: 20px;
  }
  .cart_inner .card-order.u-inner-wrapper1 ul li .u-list-box .col-sm-2 {
    display: inline-block;
    width: 50%;
    margin-top: 20px;
  }
  .page-rtl .cart_inner .u-brand-col-right{
    text-align: right;
  }
  .cart_inner .u-brand-col-right{
    width: 55% !important;
    padding-left:10px;
    padding-right:0;
  }
  .page-rtl .cart_inner .order-title{
    text-align: right;
  }
  .cart_inner .order-main.u-top-mid-col{
    padding: 12px 10px 24px
  }
  .md-stepper-horizontal .md-step.active .md-step-circle {
      width: 30px;
      height: 30px;
  }
  .md-stepper-horizontal .md-step .md-step-bar-left {
      left: 14%;
      right: 12%;
      margin-right: 0;
      margin-left: 0;
  }
  .md-stepper-horizontal .md-step .md-step-bar-right{
    right: 15%
  }
  .md-stepper-horizontal .md-step .md-step-bar-right {
      margin-right: 0;
  }
  .page-rtl .cart_top a{
    display: table-cell;
  }
  .md-stepper-horizontal{
    width: 100%;
  }
  .cart_inner .order-good1{
    text-align: left;
  }
  section.loction_mapwrap {
    width: 100%;
    position: unset;
    margin: 0;
  }
  section.location_leftmenu {
    width: 100%;
        margin: 20px 0;
    background: transparent;
  }
  .timeing_details .inner_box {
    padding: 15px 10px;
    width: 300px;
    /* max-width: 200px; */
  }
  section.location_leftmenu .col-lg-12 .timeing_details {
    /* width: 100%; */
    /* max-width: 548px; */
    background: #fff;
    border-radius: 10px;
    margin: 0 10px;
    min-height: 275px;
    height: 100%;
  }
  section.location_leftmenu .col-lg-12 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
    margin: 0 30px 0 0;
    width: 100%;
    flex-flow: row;
  }
  ul.menu.left-to-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .filter-header button.navbar-toggler {
    display: none;
  }
  .navbar-right ul li {
    padding: 15px 15px !important;
    float: unset !important;
    text-transform: uppercase;
    position: relative;
  }
  div#\#navbarCollapse {
    display: block !important;
}
  .step {
    left: 15px !important;
  }
  header.location_page_header .inner-box .content-box .upper-box {
    padding-left: 0;
  }
  .navbar-left{
    position: unset !important;
  }
  /*.brands-estb ul.year-filter{
    text-align: center;
  }*/
  ul.menu.left-to-right{
    margin: 20px 0;
  }
  ul.menu.left-to-right.rearMenu{
    padding: 0
  }
  .page-rtl ul.menu.left-to-right.rearMenu{
    padding: 0
  }


  .rearMenu {
    margin: 40px 0 0 !important;
  }
  div#\#navbarCollapse .navbar-right {
    display: block;
}
.logo-caption{
  padding-left: 10px;
}
.navbar-floating.navbar-dark .navbar-collapse {
  background-color: #fff;
}
section.loction_mapwrap{
  margin-top: 0 !important;
}
}
@media  (min-device-width: 1400px) and (max-device-width: 1920px) {
/* .modal_contentwrap ul.trire_front_rear{
  width: 86%;
} */
}

@media  (min-device-width: 768px) and (max-device-width: 1200px) {
   .search-tires h2{font-size: 66px}
  }

.cart_inner .bootom_cart .right_wrap .order-good1 {
  width: 75%;
  /* margin-bottom: 21px; */
  margin: 25px auto 0;
  text-align: left;
  /* margin-bottom: 20px; */
}
.cart_inner .bootom_cart .right_wrap .order-good1 .order-add {
  display: block;
}
.cart_inner .bootom_cart .right_wrap .pay_btn {
  margin: 50px 0 0;
}
.cart_inner .order-good1 {
  text-align: left;
}

.order-good1-mobile {
  display: none;
}

.page-rtl .col-sm-2.col_3responsive {
  margin-left: 12%;
}
@media screen and (max-width: 425px){
  .modal_contentwrap ul.trire_front_rear{
    width: 94%;
  
  }
}

@media screen and (max-width: 393px){
  .modal_contentwrap ul.trire_front_rear{
    width: 100%;
  
  }
}

@media screen and (max-width: 767px){
  .page-rtl .fuelbox:nth-child(odd) {
    padding-left: 54px;
}
.page-rtl .tire_decription_details li:nth-child(even){
  padding-right: 59px !important;
}
.page-rtl .modal_contentwrap ul.trire_front_rear li{
  padding-left: 21px;
}
.page-rtl .tire_decription_details ul li{
  padding-left: 20px;
}
  .order-good1-mobile {
    display: block
  }
  .cart_inner .bootom_cart .right_wrap .order-good1 {
    display: none;
  }
  .page-rtl .col-sm-2.col_3responsive {
    margin-right: 0px;
  }
}

/*page ratl*/

.page-rtl .logo-caption span{
  font-size: 14px
}
.page-rtl .logo-caption {
  font-size: 16px;
  font-weight:bold;
}
.page-rtl .simple-steps .search-tabs .by-vehicle a span{
  font-size: 20px
}
.page-rtl .search-tires h2{
  font-size: 76px
}
.page-rtl .search-tires .search-arrow {
    margin: 0 15px 15px 0;
    transform: rotate(268deg);
}
.page-rtl .simple-steps .search-tabs .by-size a span{
  font-size: 20px
}
.page-rtl .navbar-right ul li a{
  font-size: 16px
}
.page-rtl #Proceed .footer-btn{
  font-size: 18px;
}
.page-rtl #Proceed .footer-btn img{
  left: unset;
  right: 6px;
  transform: rotate(180deg);
}

.page-rtl .box-left span{
  font-size: 14px
}
/*.page-rtl .listing-block .listing-info li span{
  font-size: 14px
}

*/.page-rtl .listing-bottom-box .listing-rates .left-area span strong{
  font-size: 90%
}
.page-rtl .listing-bottom-box .listing-rates .left-area span small{
  font-size: 60%
}
.page-rtl .listing-bottom-box .listing-rates .right-area .btn-default{
  font-weight: bold;
}

@media (max-width: 1500.98px){
.page-rtl .listing-bottom-box .listing-rates .left-area span {
    font-size: 18px;
    line-height: 1.6;
}
}
.page-rtl p.clickhere a{
  font-weight: bold;
}
.page-rtl p.clickhere a{
  padding-left: 0;
  padding-right: 15px
}
.page-rtl div#right_modal_sm ul.listing-info li span{
  font-weight: bold;
}
.page-rtl .modal_contentwrap ul.trire_front_rear li span{
  font-weight:bold;
}
.page-rtl .timeing_details .content-box .upper-box .listing-heading p.address{
  font-size: 14px;
  font-weight: bold;
}
.page-rtl .timeing_details .content-box .upper-box .listing-heading p{
  text-align: right;
}
.page-rtl .working_timeing span{
  text-align: right;
  font-size: 14px
}
.page-rtl .reviewselect_btn button#review_btn{
  font-weight: bold;
}
.page-rtl header.location_page_header .inner-box .upper-box ul.listing-info li span{
  font-weight: bold;
}
.page-rtl header.location_page_header .inner-box ul.listing-info li span{
  font-weight: bold;
}
.page-rtl .dilivery_content{
  font-size: 18px
}
.page-rtl .btn{
  font-weight: bold;
}
.page-rtl .price_summarylisting ul li span{
  font-weight: bold;
}
.page-rtl p.deliver_time{
  font-size: 16px
}
.page-rtl .cart_inner .order-add b{
  font-weight: bold;
}
.page-rtl ul.contact_shop li a{
  font-weight: bold;
}
.page-rtl .cart_inner .bootom_cart .left_wrap .inner-box .content-box .upper-box.tireinfo_istingwrap p{
  font-weight: bold;
}
.page-rtl .cart_inner .u-brand-col-right .bin{
  font-weight: bold;
}

.listing_mobile{ display: none; }
.cart_btmmobile{ display: none !important; }
.cartb_right{display: none;}
ul.madein_list {
    display: none;
}

.mobile_listinginfo{display: none !important;}
ul.warranty_ul{display: none;}
.listing-block.tirepage_listing ul.listing-info.tire_pagelisting_mob {
    display: none !important;
}
.listing-bottom-box.tirepage_bottombox{display: none;}
ul.warranty_tirepag {
    display: none;
}
ul.madein-text {
    display: none;
}
span.desktopnow{display:block;}

ul.mappag_warrenty {display: none;}

@media (max-width: 767.98px){
.page-rtl .search-tires h2 {
    font-size: 22px;
    line-height: 1.4;
    background-position: 12% 1%;
   
}
.page-rtl .navbar ul.sub_drop_menu{
  left: 44%
}
.page-rtl .search-tires .search-arrow{
  top: -8px
}
.page-rtl .installcenterinfo span{
  text-align: right;
  padding-right: 0
}
.page-rtl .row.order_deliver .col-md-6.border-left.text-right .installcenterinfo.b_bottom{
  text-align: right;
  border: none;
}
.listing-bottom-box .listing-rates .left-area span.nowprice {
    display: block;
    margin: 10px 0;
    font-size: 15px !important;
}
.page-rtl .installcenterinfo span.bold{
  padding-left: 20px
}
.orderconfirmed-modal {
    padding-right: 0 !important;
}
.modal.right.orderconfirmed-modal .modal-dialog {
    width: 340px;
}
.page-rtl .cart_inner .bootom_cart .right_wrap .pay_btn a.confrm_book {
    font-weight: bold;
    padding: 20px 40px;
    font-size: 18px;
}
.booking_date_cart{
  display: flex; align-items: center;justify-content: space-between;
}
.booking_date_cart p{font-size: 14px}
.md-stepper-horizontal .md-step .md-step-title{text-align: center;line-height: 1.4; font-size:10px; letter-spacing:0px;}
.cart_inner .u-brand-col-left{ width: 40% }
.cart_inner .u-brand-col-right span.u-list-made>span{display: unset;}
.cart_inner .u-brand-logo.col-md-3 a.selectSizeLink {
    position: absolute;
    right: 5%;
    left: auto;
    top: 27%;
}
.cart_inner .u-brand-col-right span.u-list-made{ margin-bottom: 0 }
.cart_inner .u-brand-col-right span{margin-top: 5px}
.rear-size.col-md-5.order_complete{ display: unset; }
.rear-size.col-md-5.order_complete .clearfix{ width: 100% }
.cart_inner .order-main{border: none;}
.cart_inner .order-title span{font-size: 15px}
.cart_inner ul.tire_qty{ margin: 0; justify-content:center; }

.listing_mobile{ display: block; }

.bootom_cart {display: none !important;}
.cart_btmmobile{ display: block !important; }
.cart_inner .card-order.u-inner-wrapper1 ul li .u-list-box .col-sm-2 {
    display: none;
}
.cart_btmmobile .listing-block .inner-box {
    flex-direction: unset;
}
.cartb_right{display: block;}
.cartb_right .order-good1 {
    display: none;
}
.cart_btmmobile p.deliver_time {
    margin: 10px 0;
}
.cartb_right .pay_btn {
    margin: 30px 0;
    text-align: center;
}
.cartb_right .pay_btn a{
  border-radius: 10px;
    font-size: 18px;
    background: #f1f3fa;
    color: #1f1f1f;
    text-transform: capitalize;
    padding: 20px 110px;
    font-weight: 500;
    width: 100%;
  }
 .cartb_right .pay_btn a.alert-primary_link {
    display: block;
    margin: 35px 0;
    text-transform: uppercase;
    color: #808080;
    font-weight: normal;
    opacity: .8;
    font-size: 13px;
    background: transparent;
    padding: 0;
}
.working_timeing {
    margin-top: 10px;
}
.tire_infowrap .listing-block .inner-box {
    flex-direction: unset;
}
.tire_infowrap .listing-block .content-box{padding: 0}
.tire_infowrap .listing-block h3 a span{ padding: 0 0px 0 10px }
ul.madein_list {
    display: block !important;
    padding: 0px 10px 0;
}
ul.madein_list li {color: #000}
ul.madein_list li span{

    color: #8e8e8e;
    font-weight: 500;
    font-size: 12px;
    margin-bottom: 9px;
  }
/* ul.listing-info{display: none !important; } */
ul.listingInDetailsPage{display: none !important; }

ul.warranty_ul{display: block;
padding: 0px 15px 0;
background: #fff;
}
.mobile_listinginfo{
  display: block !important;
    background: #fff;
    padding: 15px 15px 20px;
     border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.mobile_listinginfo li {
  display: inline-block;
    width: 50%;
    text-align: center;
    margin: 0;
    font-weight: bold;
    padding-bottom: 8px
}
ul.warranty_ul li {
    padding: 10px 0 0;
    font-size: 15px;
  color: #000;
  font-weight: bold;
}
ul.mobile_listinginfo li {
    font-size: 15px;
    color: #000;
    margin-right: 0px;
    text-align: left;
}
ul.mobile_listinginfo li span{
   color: #8e8e8e;
    font-weight: 500;
    font-size: 12px;
   
}
ul.mobile_listinginfo li:last-child{margin: 0}

.inner-box.mobile_listingtire {
    flex-direction: row;
    padding: 15px 15px 0;
    border-radius: unset;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: none;
}
.order_detailmob .working_timeing{margin: 0}
.page-rtl .order_mobile .content-box .upper-box.tireinfo_istingwrap .listing-heading {
    padding: 6px 10px 0 0px;
}
.listing-block.tirepage_listing ul.listing-info.tire_pagelisting_mob {
    display: block !important;
    background: #fff
}
.listing-block.tirepage_listing .listing-bottom-box {
    display: none;
}
.listing-bottom-box.tirepage_bottombox{display: block !important; background: #fff; padding-top: 0;}
.listing-block.tirepage_listing .inner-box {
    flex-direction: row;
}
.listing-block.tirepage_listing .inner-box {
    box-shadow: none;
    border-radius: unset;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.listing-block.tirepage_listing ul.listing-info.tire_pagelisting_mob li{
  width: 50%;
    margin: auto;
    line-height: 1.6;
    font-size: 10px;
    margin-bottom: 10px;
    display:inline-block;
}
.listing-bottom-box.tirepage_bottombox .listing-rates{
  position: relative;
    display: block;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    background: #fcc223;
    margin-top: 20px;
    text-align: center;
    border-radius: 6px;
}
.listing-bottom-box.tirepage_bottombox .listing-rates .left-area{margin-bottom: 10px}
.listing-block.tirepage_listing ul.listing-info.tire_pagelisting_mob li span {
    display: unset;
}
ul.warranty_tirepag {
    display: block !important;
    background: #fff;
    padding: 0 15px 15px;
}
ul.warranty_tirepag li span{
      color: #8e8e8e;
    font-weight: 500;
    font-size: 11px;
    margin-bottom: 9px;
}
.page-rtl ul.warranty_tirepag li {text-align: right;}
.page-rtl ul.mappag_warrenty li{text-align: right;}
.page-rtl .working_timeing p{text-align: right;}
ul.warranty_tirepag li {
  color: #000;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 12px;
}
ul.mappag_warrenty {
    padding: 10px;
    background: #fff;
    display: block;
}
ul.mappag_warrenty li {
    /*color: #000;*/
    font-weight: 500;
    font-size: 12px;
}
ul.mappag_warrenty li span.color_text{
  color: #8e8e8e
}
.installMap{height: calc(60vh - 200px) !important}
.inner-box.booking_detailmob {
    box-shadow: none;
    border-radius: unset;
}
.inner-box.booking_detailmob .working_timeing {
    display: none;
}
.timing_mob {
    display: block;
    background: #ffff;
    padding: 0 15px 15px;
}
 .working_time.timing_mob p {
    font-size: 13px !important;
    color: #808080 !important;
    font-weight: 500;
    margin-bottom: 5px;
    vertical-align: top;
}
 .working_time.timing_mob span.full_time{
font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    color: #000;
  }
  ul.warranty_ul li span.text-color {
    color: #8e8e8e;
}
.listing-block .listing-heading{ padding: 0 }
ul.madein-text {
    margin: 10px 0;
    display: block;
}
  .listing-block .image img{width: 200px}
  ul.madein-text li{
    color: #000;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 12px; 
  }
  ul.madein-text li span{
        color: #8e8e8e;
  }
  .listing-bottom-box .listing-rates .left-area span span.discount_price{
    padding-right: 15px
  }
  .listing-bottom-box .listing-rates .left-area span small{
    font-size: 50%
  }
  .listing-bottom-box .listing-rates .left-area span{font-size: 11px !important}
  .listing-block .content-box{padding: 0px 10px 0 10px}
  span.desktopnow{display: none;}
}


.brandImage{ text-align: center; }
.locationAtThis{color: red;}
.mapLocation{margin-bottom: 20px;}
.mapLocationSubmit{padding-bottom: 10px; color: #000}
.selectHead{font-size: 18px; margin-bottom: 15px}
.install_at_home{font-size: 18px; margin-bottom: 8px; color: #000}
.have_you_tires_del{margin-bottom: 8px}
.popupBox{border-radius: 10px;}
.searchPopup {
    width: 100%;
}
.page-rtl div#Proceed button.close {
    right: unset;
    left: 22px;
}
.serchpopup-midd {
    font-size: 12px;
}
.page-rtl span.locationAtThis {
    display: block;
}
.limit_popup {
    direction: rtl;
}
.limit_popup button.close { 
    margin-left: unset;
}
.limit_popup .modal-body {
    text-align: right;
}


/* search tab 10-11-2021 */

.banner-section .main-heading {
  text-align: left;
}

.banner-section .main-heading h2 {
  font-size: 43px;
  font-weight: bold;
  color: #000;
  line-height: 1.4;
  font-family: "Celias", sans-serif !important;
}

.banner-section .main-heading span {
  display: block;
  font-size: 28px;
  margin-bottom: 15px;
  font-weight: normal;
}

.search-tab ul {
  display: flex;
  align-items: center;
}

.search-tab ul li {
  background: #474747;
  border-radius: 10px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-tab ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fcc223;
  font-size: 16px;
  min-width: 215px;
  height: 110px;
  font-weight: 500;
}

.searchby {
  display: block;
  text-transform: uppercase;
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: bold;
}

.search-tab ul li a img {
  margin-right: 20px;
}

.search-tab ul li:last-child {
  margin: 0;
}

.imgActive {
  display: none;
}

.search-tab ul li:hover, .search-tab ul li.active {
  background: #fff;
}

.search-tab ul li:hover .imgActive, .search-tab ul li.active .imgActive {
  display: block;
}

.search-tab ul li:hover a, .search-tab ul li.active a  {
  color: #474747;
}

.search-tab ul li:hover .imgNormal, .search-tab ul li.active .imgNormal  {
  display: none;
}

.seachTbwrp {
  position: relative;
  z-index: 9;
}
.banner-section .main-heading h2.mobViewtxt{
  display: none;
}
.seachTbwrp:before {
  background: url(../assets/searchtabBg.png) no-repeat;
  content: "";
  height: 490px;
  width: 490px;
  position: absolute;
  background-size: cover;
  top: -85px;
  z-index: -1;
}
.page-rtl .banner-section .main-heading{
text-align: right;
width: 100%;
}
.page-rtl .search-tab ul li a{
  flex-flow: row-reverse;
  text-align: right;
  font-size: 25px;
}
.page-rtl .search-tab ul li a img {
margin-right: 0;
margin-left: 20px;
}
.page-rtl .search-tab ul li a span {
  font-size: 25px;
}
.stok-qty {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 20px;
}
.stok-qty img {
  width: 60px;
}
.stok-qty span{
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
}
.In-stock {
  color: #00b050;  
}
.sold-out {
  color: #ff4d4d;
}
.low-stock {
  color: #ffc000;
}
.page-rtl .stok-qty {
  right: unset;
  left: 0;
}
.page-rtl .stok-qty span{
  padding-left: 0;
  padding-right: 5px;
  font-size: 14px;
}
.text-line-lbl {
  margin: 20px 0;
  background: #fff;
  padding: 0 10px;
}
.text-line-lbl p span {
  display: block;
  margin-bottom: 10px;
}
.text-line-lbl p span:last-child{
  margin: 0;
}
.text-line-lbl.mobileView{
  display: none;
}
@media (max-width:1536px) {
  .banner-section .main-heading h2 {
      font-size: 32px;
  }
  .banner-section .main-heading span {
      font-size: 22px;
      margin-bottom: 10px;
  }
  .search-tab ul li {
      min-width: auto;
  }
  .search-tab ul li a img {
      width: 50px;
      margin-right: 10px;
  }
  .page-rtl .search-tab ul li a img{
    margin-right: 0px;
    margin-left: 10px;
  }
  .search-tab ul li a {
      min-width: 160px;
      font-size: 14px;
      height: 70px;
  }
  .searchby {
      font-size: 16px;
  }
  .seachTbwrp:before {
      height: 400px;
      width: 400px;
  }
  .page-rtl .search-tab ul li a{
    text-align: center;
  }
  .page-rtl .search-tab ul li a span {
    font-size: 22px;
}
}

@media (max-width:1199px) {
  .seachTbwrp:before {
      height: 350px;
      width: 350px;
  }
}

@media (max-width:991px) {
  .seachTbwrp{
    margin-top:100px;
  }


.search-tab ul li a {
  min-width: 160px;
    flex-flow: column;
    height: 140px;
    font-size: 16px;
    text-align: center;
}
.search-tab ul li a img {
  margin: 0;
  margin-bottom: 10px;
}
.search-tab ul{
  justify-content: space-around;
}
.page-rtl .search-tab ul li a{
  flex-flow: column;
}

}
@media (max-width:767px) {
  

.modal_contentwrap ul.trire_front_rear li span{
  font-size: 16px;
}
div#right_modal_sm a.runflat {
  font-size: 9px;
  top: 23px;
  right: -10px;
}

  .banner-section .main-heading h2.mobViewtxt{
    display: block;
    
    width: 100%;
  }
  .main-heading h2.webtxt{
    display: none;
  }
  .stok-qty{
    top: 10px;
    width: 60px;
  }
  .stok-qty span{
    font-size: 12px;
    padding-left: 5px;
    line-height: 1.4;
  }
  .stok-qty img {
    width: 30px;
  }
  .runflat {
    position: absolute;
    right: -5px;
    bottom: 19px;
    left: auto;
    font-size: 12px;
    top: unset;
  }
  .page-rtl .runflat {
    left: 10px;
    right: auto;
  }
  .text-line-lbl{
    display: none;
  }
  .text-line-lbl.mobileView{
    display: block;
    /* margin: 10px 0; */
    margin: 0;
    border-bottom: 1px solid #eceef2;
  }
  .page-rtl .text-line-lbl.mobileView{
  text-align: right;
  }
  .mobilecard_view{
    padding: 14px 12px 20px;
    font-family:"Rubik",sans-serif;
  }
  .totalbtn_box{
    padding-top: 10px !important;
  }
  .totalbtn_box .listing-rates{
    margin-top: 0 !important;
  }
  .mobilecard_view .listing-year{
    color: #8e8e8e;
  }
  .runflat{
    bottom: 0;
  }
}
.stok-qty span{
  width: 60px;
}

@media (max-width:374px) {
  .tire_description{
    padding: 0px 10px;
  }
}

@media (max-width:991px) {
  .modal_contentwrap ul.trire_front_rear{
    margin: 0 -5px;
    /* width: 96%; */
  }
  .modal.right .modal-body {
    padding-left:15px;
    padding-right:15px;
}
.modal_contentwrap ul.trire_front_rear li{flex-wrap: wrap;}
}
.cutpricelabel{
  text-decoration-color: red !important;
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}
.tire_decription_details {
  margin-top: 19px;
}
.tire_decription_details li:nth-child(even) {
 padding-left: 64px;

}
/* ul.trire_front_rear li:nth-child(even) {
  min-width: 136px;
} */
.tirbox ul {
  display: flex;
  flex-wrap: wrap;
}
.tire_decription_details li.fuelbox {
  /* width: 160px !important; */
  margin-bottom: 10px;
  padding-left: 0;
}
 li.fuelbox:nth-child(even){
  padding-left: 42px;
}
.price_listing li:nth-child(even){
  padding-left: 35px;
}

li.fuelbox .gauge-container {
  width: 120px;
}
.page-rtl .tire_decription_details li:nth-child(even) {
  /* padding-left: 19px;
  text-align: left;
  width: 47%; */
  padding-right: 71px;
}

li.fuelbox .gauge_listdetail {
  margin: -25px 0;
  width: 120px;
}

.promotion_cls{display: flex; align-items: center; color: green; white-space: nowrap; margin-top: 10px;}
.promotion_cls img{margin-right: 5px;}
.promotion_cls a img{margin: 0px; margin-left: 5px;}
.modelbodytext{line-height: 1.6;}
.page-rtl .promotion_cls img{margin-right: 0; margin-left: 5px;}
.page-rtl .promotion_cls a img{margin-left: 0; margin-right: 5px;}
.include-gift {margin-top: 10px;}
.included_gift_cls {font-size: 14px; font-weight: bold;}
.page-rtl .text-line-lbl p span.low-stock {font-weight: bold;}

.blogs-section .card img.blog-2{height: 264px; 
  object-fit: cover;}
.blogdetailspage .blogfullimg{width: 100%;}
.blogdetailspage hr{border-color: #cbcbcb;margin: 0 0 15px;}
.blogdetailspage .privacy-content h2,
.blogdetailspage .privacy-content h3{margin-bottom: 15px;}
.blogdetailspage .privacy-content ul{margin-bottom: 15px;list-style: outside;padding-left: 25px;}
.blogdetailspage .privacy-content ol{margin-bottom: 15px;list-style: num; padding-left: 25px;}
.blogdetailspage .privacy-content ol ul{padding-left: 20px;}


.blogdetailspage .privacy-content ul li p,
.blogdetailspage .privacy-content ol li p{margin: 0 0 10px;}
.blog-heading{
  font-weight: bold;        /* Make the text bold */
  line-height: 1.6;       /* Extra space between words for readability */
  font-size: 20px;          /* Adjust font size */
}

.blogdetailspage .privacy-content p{font-size: 13px; line-height: 1.6;margin-bottom: 15px;}
.blogdetailspage .privacy-content p span{display: flex;}
.blogdetailspage .privacy-content p span img{ width: 250px;
  height: auto;
  object-fit: cover;
  margin-right: 20px;
  flex-shrink: 0;}


.page-rtl .blogdetailspage .privacy-content ul,
.page-rtl .blogdetailspage .privacy-content ol{padding-left: 0;padding-right: 25px;}
.page-rtl .blogdetailspage .privacy-content ol ul{padding-left: 0;padding-right: 20px;}
.page-rtl .blogdetailspage .privacy-content p span img{
  margin-right: 0;margin-left: 20px;}
  
@media (max-width:767px) {
  .weblistingdata{display:none !important;}
}
