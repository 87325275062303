.btn .btn-secondary {
    color: #ffffff !important;
}

.footer-btn-map {
	width: 100%;
	padding: 17px;
	background: #fcc223;
	border: none;
	color: #fff;
	border-radius: 7px;
	font-size: 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-width: 150px;
    max-width: 150px;
}
.map-model .modal-header {
    border: none;
}
.map-model .modal-footer {
    display: none;
}
.map-model .modal-header .modal-title {
    font-size: 20px;
    color: #000;
    font-weight: 500;
}
.map-model .close {
    padding: 11px;
    margin-top: -55px;
    font-size: 35px;
}

.map-model.rtl-ar .modal-header {
    text-align: right;
}
.map-model.rtl-ar .modal-header .close {
    margin-left: -15px;
}
.map-model.fade.modal.show {
    margin-top: 150px !important;
}